<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading sites...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <nav class="navigation">
    <ul class="o-breadcrumb">
      <li class="o-breadcrumb__item">
        <a routerLink="/infrastructure" class="is-link"> <mat-icon class="is-icon">dashboard</mat-icon></a>
      </li>
      <li class="o-breadcrumb__item">Sites</li>
    </ul>
  </nav>
  <div class="c-sites__container">
    <div class="c-sites__title">
      <div class="o-heading--pica">
        <ng-container *ngIf="organisation; else noOrg">
          <h2>{{ organisation.name }}</h2>
        </ng-container>
        <ng-template #noOrg>
          <h2>Sites</h2>
        </ng-template>
        <h3>Showing {{ filteredSites.length }} of {{ sites.length }} sites</h3>
      </div>
    </div>
    <ng-container *ngIf="isSupport">
      <div class="c-sites__filter">
        <app-filter placeholder="Filter sites" (search)="runSearch($event)" class="o-search__input"></app-filter>
        <div class="is-action o-button has-outline" (click)="openAddEditSiteDialog()" *ngIf="isSupport || isAdmin">
          <mat-icon class="is-icon-left">domain_add</mat-icon>
          ADD SITE
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="noSites; else hasSites">
      <div class="c-no-sites" *ngIf="isSupport || isAdmin; else noAccess">
        <img class="is-image" src="/assets/images/site-museum.png" alt="Building" />
        <div class="is-text-container">
          <h2 class="pica">You don't have any sites yet.</h2>
          <button class="o-button is-primary u-margin--bottom" mat-raised-button (click)="openAddEditSiteDialog()">
            Add your first site
          </button>
          <p>You can create as many sites as you need for your organisation.</p>
        </div>
      </div>
      <ng-template #noAccess>
        <div class="c-sites__request-access">
          <app-no-sites-card></app-no-sites-card>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #hasSites>
      <div class="c-sites__list">
        <ng-container *ngFor="let site of filteredSites">
          <!-- add the monitors details -->
          <app-site-card
            [site]="site"
            [cameras]="siteCameras[site.id]"
            [occupancyMonitors]="occupancyMonitorsMapBySiteId[site.id]"
            [peopleCountMonitors]="peopleCountMonitorsMapBySiteId[site.id]"
            [peelOffMonitors]="peelOffMonitorsMapBySiteId[site.id]"
            (editSite)="openAddEditSiteDialog(site)"
            (deleteSite)="deleteSite(site)"
          ></app-site-card>
        </ng-container>
        <div class="c-add-site" (click)="openAddEditSiteDialog()" *ngIf="isSupport || isAdmin">
          <img class="is-image" src="/assets/images/site-museum.png" alt="" />
          <span class="is-action o-button">
            <mat-icon class="is-icon-left">apartment</mat-icon>
            Add site
          </span>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
