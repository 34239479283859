import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';

import {
  NotificationGroup,
  NotificationGroupService,
  UserService,
  NotificationGroupNotificationGroup,
  NotificationGroupMappingService,
  AlertService,
  AlertNotificationGroupMappingService,
  AlertNotificationGroupMapping,
} from 'src/app/api';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotifyService } from 'src/app/services/notify.service';
import { EditNotificationGroupDialogComponent } from 'src/app/views/notifications/components/edit-notification-group-dialog/edit-notification-group-dialog.component';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { AddEditNotificationGroupComponent } from 'src/app/components/notifications/add-edit-notification-group/add-edit-notification-group.component';
import { NotificationGroupEnum } from 'src/app/views/notifications/routes/notification-group/notification-group.models';

@Component({
  selector: 'app-notification-group',
  templateUrl: './notification-group.component.html',
  styleUrls: ['./notification-group.component.scss'],
})
export class NotificationGroupComponent implements OnInit, OnDestroy {
  notificationGroupId: string;
  showAddToNotificationGroupFullScreen = false;
  emailsSelection = new SelectionModel<string>(true, []);
  notificationGroupsSelection = new SelectionModel<string>(true, []);
  deleting = false;
  userEmailToUserMap: { [userEmail: string]: User } = {};
  alertNotificationGroupMappings: AlertNotificationGroupMapping[] = [];
  alertNotifiactions = [];

  isLoading$ = new BehaviorSubject<boolean>(false);

  notificationGroups: { [key: string]: NotificationGroupEnum } = {
    camera_health_summary_alert: 'Camera health summary',
    occupancy_alert: 'Occupancy alert',
    offline_alert: 'Offline alert',
  };

  private ngUnsubscribe = new Subject();
  notificationGroupsMap: { [_: string]: NotificationGroup };
  notificationGroupNotificationGroupMap: { [_: string]: NotificationGroupNotificationGroup[] };
  nestedNotificationGroups: any; // details
  notificationGroup: NotificationGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private matDialog: MatDialog,
    private notificationGroupService: NotificationGroupService,
    private notificationGroupMappingService: NotificationGroupMappingService,
    private notifyService: NotifyService,
    private ref: ChangeDetectorRef,
    private userService: UserService,
    private alertService: AlertService,
    private alertNotificationGroupMappingService: AlertNotificationGroupMappingService,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.notificationGroupsMap = this.notificationsService.notificationGroupsMap;

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        this.notificationGroupId = params?.id;
        this.getData();
      },
    });
    this.breakpointObserver
      .observe(['(min-width: 768px)'])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (state: BreakpointState) => {
          this.showAddToNotificationGroupFullScreen = !state.matches;
        },
      });
  }

  getData(): void {
    this.isLoading$.next(true);

    forkJoin([
      this.notificationGroupService.listNotificationGroups(this.notificationGroupId),
      this.userService.listUsers(),
      this.alertService.listAlerts(undefined, undefined, undefined, undefined, undefined, undefined, 'active'),
      this.alertNotificationGroupMappingService.listAlertNotificationGroupMappings(
        undefined,
        this.notificationGroupId,
        undefined,
        undefined,
        'active',
      ),
    ])
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([[notificationGroup], users, alerts, alertNotificationGroupMappings]) => {
          if (notificationGroup === undefined) {
            this.router.navigate(['/not-found']);
          }

          this.notificationGroup = notificationGroup;

          this.notificationGroupNotificationGroupMap = this.notificationsService.notificationGroupNotificationGroupMap;
          const childNotificationGroupsLinks = this.notificationGroupNotificationGroupMap[notificationGroup.id];
          const childNotificationGroups = childNotificationGroupsLinks.map((childNotificationGroupsLink) => {
            return this.notificationsService.notificationGroupsMap[childNotificationGroupsLink.childId];
          });

          this.nestedNotificationGroups = childNotificationGroups.map((group) => ({
            name: group.name,
            id: group.id,
            members: this.notificationsService.notificationGroupMembers[group.id],
          }));

          users.forEach((user) => (this.userEmailToUserMap[user.email] = user));

          const alertsMap = {};
          alerts.forEach((alert) => {
            alertsMap[alert.id] = alert;
          });
          this.alertNotificationGroupMappings = alertNotificationGroupMappings;
          this.alertNotificationGroupMappings.forEach((alertNotificationGroupMapping) => {
            this.alertNotifiactions.push({
              title: alertsMap[alertNotificationGroupMapping.alertId].name,
              type: alertsMap[alertNotificationGroupMapping.alertId].alertType,
            });
          });
        },
        error: (error) => {
          this.notifyService.error(error);
          this.router.navigate(['internal-error']);
        },
      });
  }

  addEmailToNotificationGroup(): void {
    const dialogRef = this.matDialog.open(AddEditNotificationGroupComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: { notificationGroup: this.notificationGroup, editOnlyUsers: true },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  updateNotificationGroup(emails: string[]): void {
    this.notificationGroupService
      .putNotificationGroup({ ...this.notificationGroup, emails })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (_) => {
          this.notificationGroup.emails = emails;
          this.emailsSelection.clear();
          this.ref.detectChanges();
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  editList(): void {
    const dialogRef = this.matDialog.open(AddEditNotificationGroupComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: { notificationGroup: this.notificationGroup },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.navigateToNotificationGroups(true);
        }
      },
    });
  }

  deleteList(): void {
    const message = `Are you sure you want to delete list "${this.notificationGroup.name}"?
    
    The notification group will be unlinked from all its alerts!`;
    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'delete', isDelete: true },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.deleting = true;

          const requests: Observable<any>[] = [];
          this.notificationsService.notificationGroupNotificationGroups.forEach(
            (notificationGroupNotificationGroup) => {
              if (
                notificationGroupNotificationGroup.childId === this.notificationGroup.id ||
                notificationGroupNotificationGroup.parentId === this.notificationGroup.id
              ) {
                requests.push(
                  this.notificationGroupMappingService.deleteNotificationGroupNotificationGroup(
                    notificationGroupNotificationGroup.id,
                  ),
                );
              }
            },
          );

          this.alertNotificationGroupMappings.forEach((alertNotificationGroupMapping) => {
            requests.push(
              this.alertNotificationGroupMappingService.deleteAlertNotificationGroupMapping(
                alertNotificationGroupMapping.id,
              ),
            );
          });

          if (requests.length) {
            forkJoin(requests)
              .pipe(
                switchMap((_) => this.notificationGroupService.deleteNotificationGroup(this.notificationGroup.id)),
                finalize(() => {
                  this.deleting = false;
                }),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe({
                next: (_) => {
                  this.navigateToNotificationGroups(true);
                },
                error: (error) => {
                  this.notifyService.error(error);
                },
              });
          } else {
            this.notificationGroupService
              .deleteNotificationGroup(this.notificationGroup.id)
              .pipe(
                finalize(() => {
                  this.deleting = false;
                }),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe({
                next: (_) => {
                  this.navigateToNotificationGroups(true);
                },
                error: (error) => {
                  this.notifyService.error(error);
                },
              });
          }
        }
      },
    });
  }

  deleteSelected(): void {
    if (this.emailsSelection.selected.length !== 0) {
      this.updateNotificationGroup(
        this.notificationGroup.emails.filter((email) => !this.emailsSelection.isSelected(email)),
      );
    }
    if (this.notificationGroupsSelection.selected.length !== 0) {
      const requests: Observable<any>[] = [];
      this.notificationsService.notificationGroupNotificationGroupMap[this.notificationGroup.id].forEach(
        (notificationGroupNotificationGroup) => {
          if (this.notificationGroupsSelection.selected.includes(notificationGroupNotificationGroup.childId)) {
            requests.push(
              this.notificationGroupMappingService.deleteNotificationGroupNotificationGroup(
                notificationGroupNotificationGroup.id,
              ),
            );
          }
        },
      );
      forkJoin(requests)
        .pipe(
          finalize(() => {
            this.deleting = false;
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: (_) => {
            window.location.reload();
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
    }
  }

  navigateToNotificationGroups(reload?: boolean): void {
    this.router.navigate(['../'], { relativeTo: this.route }).then(() => {
      if (reload) {
        window.location.reload();
      }
    });
  }

  openEditNotificationGroupDialog(notificationGroup): void {
    this.matDialog.open(EditNotificationGroupDialogComponent, {
      width: '380px',
      data: {
        notificationGroupDetails: notificationGroup,
        userEmailToUserMap: this.userEmailToUserMap,
      },
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
