import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, catchError, of, throwError, forkJoin, takeUntil, finalize, Subject, BehaviorSubject } from 'rxjs';
import {
  CameraService,
  OccupancyMonitor,
  OccupancyMonitorCameraPosition,
  OccupancyMonitorCameraPositionService,
  OccupancyMonitorService,
  Site,
  SiteService,
} from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { AccountService } from 'src/app/services/account.service';
import { CamerasService } from 'src/app/services/cameras.service';
import { MonitorsService } from 'src/app/services/monitors.service';
import { NotifyService } from 'src/app/services/notify.service';
import { ReportsService } from 'src/app/services/reports.service';

type TSortOptions =
  | 'Monitor name'
  | 'Site'
  | 'Safe occupancy (High - low)'
  | 'Safe occupancy (Low - high)'
  | 'Occupancy % (High - low)'
  | 'Occupancy % (Low - high)';

const loadNextMonitorsQuantity = 10;
@Component({
  selector: 'app-real-time-data',
  templateUrl: './real-time-data.component.html',
  styleUrls: ['./real-time-data.component.scss'],
})
export class RealTimeDataComponent implements OnInit, OnDestroy {
  isLoading$ = new BehaviorSubject<boolean>(true);
  occupancyMonitors: OccupancyMonitor[] = [];
  filteredOccupancyMonitors: OccupancyMonitor[] = [];
  updating = false;

  sitesMap: { [_: string]: Site } = {};
  availableSites: Set<Site>;
  selectedSite = '00000000-0000-0000-0000-000000000000';
  sites: Site[] = [];
  allSiteOption: Site = {
    name: 'ALL SITES',
    id: '00000000-0000-0000-0000-000000000000',
  };
  searchString = '';

  selectedSortOption: TSortOptions = 'Site';
  sortOptions: { [key: string]: TSortOptions } = {
    monitorName: 'Monitor name',
    site: 'Site',
    capacityLowHigh: 'Safe occupancy (Low - high)',
    capacityHighLow: 'Safe occupancy (High - low)',
    occupancyLowHigh: 'Occupancy % (Low - high)',
    occupancyHighLow: 'Occupancy % (High - low)',
  };

  activeRealTimeDataMonitorsLimit = loadNextMonitorsQuantity;
  @ViewChild('realTimeDataList') realTimeDataList: ElementRef;
  private ngUnsubscribe = new Subject();

  constructor(
    public accountService: AccountService,
    public reportsService: ReportsService,
    private occupancyMonitorService: OccupancyMonitorService,
    private monitorsService: MonitorsService,
    private occupancyMonitorCameraPositionService: OccupancyMonitorCameraPositionService,
    private siteService: SiteService,
    private cameraService: CameraService,
    private camerasService: CamerasService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private notifyService: NotifyService,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.isLoading$.next(true);

    const requests: Observable<any>[] = [
      this.siteService.listSites(undefined, undefined, 'active'),
      this.cameraService.listCameras(false, undefined, undefined, ['running', 'paused']),
    ];
    if (this.accountService.occupancyLicence) {
      requests.push(
        this.occupancyMonitorService.listOccupancyMonitors(undefined, undefined, undefined, 'active'),
        this.occupancyMonitorCameraPositionService.listOccupancyMonitorCameraPositions(
          undefined,
          undefined,
          undefined,
          undefined,
          'active',
        ),
      );
    }

    forkJoin(requests)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.sitesMap = this.reportsService.sitesMap;
          this.occupancyMonitors = this.reportsService.occupancyMonitors;
          this.availableSites = new Set([this.allSiteOption, ...this.sites]);

          this.filterMonitors();
          this.isLoading$.next(false);
        }),
      )
      .subscribe({
        // next: ([sites, cameras, occupancyMonitors?, occupancyMonitorCameraPositions?]) => {
        next: (respone: any[]) => {
          const sitesMap = {};
          this.sites = respone[0];
          this.sites.forEach((site) => {
            sitesMap[site.id] = site;
          });

          let cameras = respone[1];
          cameras = cameras
            .map((c) => this.camerasService.getCameraStatus(c))
            .sort((c1, c2) => {
              if (!c1.name) {
                c1.name = '-';
              }
              if (!c2.name) {
                c2.name = '-';
              }
              if (c1.siteId === c2.siteId) {
                return c1.name.toLowerCase().localeCompare(c2.name.toLowerCase());
              } else {
                return sitesMap[c1.siteId].name.toLowerCase().localeCompare(sitesMap[c2.siteId].name.toLowerCase());
              }
            });

          const camerasMapByCameraPositionId: { [_: string]: CameraStatus } = {};
          cameras.forEach((camera) => {
            camerasMapByCameraPositionId[camera.cameraPositionId] = camera;
          });

          let occupancyMonitors;
          let occupancyMonitorCameraPositions;
          let occupancyMonitorsMap = {};
          const occupancyMonitorCameraPositionsMap: { [_: string]: OccupancyMonitorCameraPosition[] } = {};

          if (this.accountService.occupancyLicence) {
            occupancyMonitors = respone[2];
            occupancyMonitorCameraPositions = respone[3];

            occupancyMonitors.forEach((occupancyMonitor) => {
              occupancyMonitorsMap[occupancyMonitor.id] = occupancyMonitor;
              occupancyMonitorCameraPositionsMap[occupancyMonitor.id] = [];
            });

            occupancyMonitorCameraPositions.forEach((occupancyMonitorCameraPosition) => {
              if (occupancyMonitorCameraPositionsMap[occupancyMonitorCameraPosition.occupancyMonitorId] !== undefined) {
                occupancyMonitorCameraPositionsMap[occupancyMonitorCameraPosition.occupancyMonitorId].push(
                  occupancyMonitorCameraPosition,
                );
              }
            });
            occupancyMonitors = occupancyMonitors.map((om) =>
              this.monitorsService.getOccupancyMonitorStatus(
                om,
                occupancyMonitorCameraPositionsMap[om.id]
                  .map((omcp) => camerasMapByCameraPositionId[omcp.cameraPositionId])
                  .filter(Boolean),
                sitesMap[om.siteId],
              ),
            );
          }

          this.reportsService.cameras = cameras;
          this.reportsService.peopleCountMonitors = cameras;
          this.reportsService.sitesMap = sitesMap;
          this.reportsService.camerasMapByCameraPositionId = camerasMapByCameraPositionId;

          if (this.accountService.occupancyLicence) {
            this.reportsService.occupancyMonitors = occupancyMonitors.sort((objA, objB) => {
              if (sitesMap[objA.siteId].name < sitesMap[objB.siteId].name) return -1;
              if (sitesMap[objA.siteId].name > sitesMap[objB.siteId].name) return 1;
              return 0;
            });

            this.reportsService.occupancyMonitorCameraPositionsMap = occupancyMonitorCameraPositionsMap;
            this.reportsService.occupancyMonitorsMap = occupancyMonitorsMap;
          }
        },
        error: (error) => {
          this.notifyService.error(error);
          this.router.navigate(['internal-error']);
        },
      });
  }

  onRealTimeDataScroll(): void {
    const element = this.realTimeDataList.nativeElement;
    const isScrolledToBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= 1;
    const isAllCamerasLoaded = this.activeRealTimeDataMonitorsLimit >= this.occupancyMonitors.length;
    if (isScrolledToBottom && !isAllCamerasLoaded) {
      this.activeRealTimeDataMonitorsLimit += loadNextMonitorsQuantity;
      this.filterMonitors();
    }
  }

  filterMonitors(searchString?: string): void {
    if (searchString !== undefined) {
      this.searchString = searchString.toLowerCase();
    }

    this.filteredOccupancyMonitors =
      this.selectedSite === this.allSiteOption.id
        ? this.occupancyMonitors
        : this.occupancyMonitors.filter((m) => m.siteId === this.selectedSite);

    this.filteredOccupancyMonitors = this.filteredOccupancyMonitors
      .filter(
        (m) =>
          m.id.includes(this.searchString) ||
          m.name?.toLowerCase().includes(this.searchString) ||
          this.sitesMap[m.siteId]?.name.toLowerCase().includes(this.searchString),
      )
      .slice(0, this.activeRealTimeDataMonitorsLimit);
    this.sortFilteredMonitors();
  }

  sortFilteredMonitors(): void {
    if (this.selectedSortOption === this.sortOptions.monitorName) {
      this.filteredOccupancyMonitors = this.filteredOccupancyMonitors.sort((objA, objB) => {
        if (objA.name < objB.name) return -1;
        if (objA.name > objB.name) return 1;
        return 0;
      });
    }
    if (this.selectedSortOption === this.sortOptions.site) {
      this.filteredOccupancyMonitors = this.filteredOccupancyMonitors.sort((objA, objB) => {
        if (this.sitesMap[objA.siteId].name < this.sitesMap[objB.siteId].name) return -1;
        if (this.sitesMap[objA.siteId].name > this.sitesMap[objB.siteId].name) return 1;
        return 0;
      });
    }
    if (this.selectedSortOption === this.sortOptions.capacityLowHigh) {
      this.filteredOccupancyMonitors = this.filteredOccupancyMonitors.sort((objA, objB) => {
        if (objA.capacity === undefined && objB.capacity === undefined) {
          return 0;
        }
        if (objA.capacity === undefined) {
          return -1;
        }
        if (objB.capacity === undefined) {
          return 1;
        }
        if (objA.capacity < objB.capacity) {
          return -1;
        }
        if (objA.capacity > objB.capacity) {
          return 1;
        }
        return 0;
      });
    }
    if (this.selectedSortOption === this.sortOptions.capacityHighLow) {
      this.filteredOccupancyMonitors = this.filteredOccupancyMonitors.sort((objA, objB) => {
        if (objA.capacity === undefined && objB.capacity === undefined) {
          return 0;
        }
        if (objA.capacity === undefined) {
          return 1;
        }
        if (objB.capacity === undefined) {
          return -1;
        }
        if (objA.capacity > objB.capacity) {
          return -1;
        }
        if (objA.capacity < objB.capacity) {
          return 1;
        }
        return 0;
      });
    }

    if (this.selectedSortOption === this.sortOptions.occupancyLowHigh) {
      this.filteredOccupancyMonitors = this.filteredOccupancyMonitors.sort((objA, objB) => {
        const currentOccupancyA = this.reportsService.occupancyMonitorRealTimeData[objA.id].currentOccupancy;
        const currentOccupancyB = this.reportsService.occupancyMonitorRealTimeData[objB.id].currentOccupancy;
        if (
          (currentOccupancyA === undefined || objA.capacity === undefined) &&
          (currentOccupancyB === undefined || objB.capacity === undefined)
        ) {
          return 0;
        }
        if (currentOccupancyA === undefined || objA.capacity === undefined) {
          return -1;
        }
        if (currentOccupancyB === undefined || objB.capacity === undefined) {
          return 1;
        }
        const occupancyA = currentOccupancyA ? Number((currentOccupancyA / objA.capacity) * 100) : 0;
        const occupancyB = currentOccupancyB ? Number((currentOccupancyB / objB.capacity) * 100) : 0;
        if (occupancyA < occupancyB) return -1;
        if (occupancyA > occupancyB) return 1;
        return 0;
      });
    }
    if (this.selectedSortOption === this.sortOptions.occupancyHighLow) {
      this.filteredOccupancyMonitors = this.filteredOccupancyMonitors.sort((objA, objB) => {
        const currentOccupancyA = this.reportsService.occupancyMonitorRealTimeData[objA.id].currentOccupancy;
        const currentOccupancyB = this.reportsService.occupancyMonitorRealTimeData[objB.id].currentOccupancy;
        if (
          (currentOccupancyA === undefined || objA.capacity === undefined) &&
          (currentOccupancyB === undefined || objB.capacity === undefined)
        ) {
          return 0;
        }
        if (currentOccupancyA === undefined || objA.capacity === undefined) {
          return 1;
        }
        if (currentOccupancyB === undefined || objB.capacity === undefined) {
          return -1;
        }
        const occupancyA = currentOccupancyA ? Number((currentOccupancyA / objA.capacity) * 100) : 0;
        const occupancyB = currentOccupancyB ? Number((currentOccupancyB / objB.capacity) * 100) : 0;
        if (occupancyA > occupancyB) return -1;
        if (occupancyA < occupancyB) return 1;
        return 0;
      });
    }
  }

  updateRealTimeData(): void {
    this.updating = true;
    this.ref.detectChanges();
    this.reportsService.updateRealTimeDataWithLatest().then((_) => {
      this.updating = false;
      this.ref.detectChanges();
    });
  }

  toggleAutoRefresh(): void {
    this.reportsService.autoUpdateRealTimeData = !this.reportsService.autoUpdateRealTimeData;
    this.ref.detectChanges();
    this.cookieService.set('_auto_update_real_time_data', this.reportsService.autoUpdateRealTimeData.toString());
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
