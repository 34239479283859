<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>

<ng-template #notLoading>
  <div>
    <nav class="navigation">
      <ul class="o-breadcrumb">
        <li class="o-breadcrumb__item">
          <a routerLink="/infrastructure" class="is-link"> <mat-icon class="is-icon">dashboard</mat-icon></a>
        </li>
        <li class="o-breadcrumb__item">
          <a routerLink="/sites" class="is-link">Sites</a>
        </li>
        <li class="o-breadcrumb__item">{{ siteDetailsService.site.name }}</li>
      </ul>
    </nav>
    <div class="o-view is-full-height">
      <main class="o-view__main">
        <div class="c-section-title">
          <h2 class="is-title">{{ siteDetailsService.site.name }}</h2>
          <a class="is-icon" *ngIf="isSiteDetailsRoute()">
            <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editSite()">
                <mat-icon>edit</mat-icon>
                Edit site details
              </button>
              <button mat-menu-item (click)="deleteSite()" *ngIf="accountService.isSupport">
                <mat-icon>delete</mat-icon>
                Delete site
              </button>
            </mat-menu>
          </a>
        </div>

        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</ng-template>
