import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  Alert,
  AlertCameraPositionMapping,
  AlertCameraPositionMappingService,
  AlertOccupancyMonitorMappingService,
  AlertService,
  CameraService,
  NotificationGroup,
  NotificationGroupService,
  OccupancyMonitor,
  OccupancyMonitorCameraPositionService,
  OccupancyMonitorService,
  Organisation,
  PeelOffMonitor,
  PeelOffMonitorCameraPositionService,
  PeelOffMonitorService,
  PeopleCountMonitor,
  PeopleCountMonitorCameraPositionService,
  PeopleCountMonitorService,
  ReportService,
  Site,
  SiteService,
  UserService,
} from 'src/app/api';
import { CameraSetupDialogComponent } from 'src/app/components/cameras/camera-setup-dialog/camera-setup-dialog.component';
import { AddEditPeelOffMonitorComponent } from 'src/app/components/monitors/add-edit-peel-off-monitor/add-edit-peel-off-monitor.component';
import { AddOccupancyMonitorComponent } from 'src/app/components/monitors/add-occupancy-monitor/add-occupancy-monitor.component';
import { AddPeopleCountMonitorComponent } from 'src/app/components/monitors/add-people-count-monitor/add-people-count-monitor.component';
import { AddEditSiteComponent } from 'src/app/components/sites/add-edit-site/add-edit-site.component';
import { compare } from 'src/app/functions/sort';
import { GlobalMethods } from 'src/app/global-methods';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { SiteStatus } from 'src/app/model/siteStatus';
import { AccountService } from 'src/app/services/account.service';
import { CamerasService } from 'src/app/services/cameras.service';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  showQuickstarWizard = true;
  gotAllData = false;
  allCameras: CameraStatus[] = []; // including decommissioned cameras
  cameras: CameraStatus[] = [];
  cameraVideoLoss: { [_: string]: number } = {};
  cameraPeopleCount: { [_: string]: number } = {};
  issueCameras: CameraStatus[] = [];
  issueCamerasLastWeek: CameraStatus[] = [];
  cameraSevenDaysSummary: { [_: string]: { lateCount: number; missingCount: number; onTimeCount: number } } = {};
  incompletCameras: CameraStatus[] = [];
  sites: SiteStatus[] = [];
  sitesMap: { [_: string]: SiteStatus } = {};
  sitesMapCameraIssues: { [_: string]: CameraStatus[] } = {};
  sitesMapCameraIssuesLastWeek: { [_: string]: CameraStatus[] } = {};
  organisations: Organisation[] = [];
  offlineAlerts: Alert[] = [];
  offlineAlertsMap: { [_: string]: Alert } = {};
  offlineAlertsMapByCameraPositionId: { [_: string]: Alert[] } = {};
  camerasWithNoOfflineAlerts: CameraStatus[] = [];
  summaryAlerts: Alert[] = [];
  summaryAlertsMap: { [_: string]: Alert } = {};
  summaryAlertsMapByCameraPositionId: {
    [_: string]: Alert[];
  } = {};
  camerasWithNoSummaryAlert: CameraStatus[] = [];
  occupancyAlerts: Alert[] = [];
  noOccupancyAlertMonitors: OccupancyMonitor[] = [];
  occupancyMonitors: OccupancyMonitor[] = [];
  peopleCountMonitors: PeopleCountMonitor[] = [];
  peelOffMonitors: PeelOffMonitor[] = [];
  occupancyMonitorsMap: { [_: string]: OccupancyMonitor } = {};
  occupancyMonitorsMapByCameraPositionId: { [_: string]: OccupancyMonitor[] } = {};
  peopleCountMonitorsMap: { [_: string]: PeopleCountMonitor } = {};
  peopleCountMonitorsMapByCameraPositionId: { [_: string]: PeopleCountMonitor[] } = {};
  peelOffMonitorsMap: { [_: string]: PeelOffMonitor } = {};
  peelOffMonitorsMapByCameraPositionId: { [_: string]: PeelOffMonitor[] } = {};
  occupancyMonitorCameraPositionsIssues: number = 0;
  occupancyMonitorCameraPositionsIssuesLastWeek: number = 0;
  peopleCountMonitorCameraPositionsIssues: number = 0;
  peopleCountMonitorCameraPositionsIssuesLastWeek: number = 0;
  peelOffMonitorCameraPositionsIssues: number = 0;
  peelOffMonitorCameraPositionsIssuesLastWeek: number = 0;
  occupancyMonitorsMapBySiteId = {};
  peopleCountMonitorsMapBySiteId = {};
  peelOffMonitorsMapBySiteId = {};

  notificationGroups: NotificationGroup[] = [];
  users = [];
  adminUsers = [];
  memberUsers = [];
  occupancyMonitorLicense: Organisation[] = [];
  siteCameras: { [_: string]: CameraStatus[] } = {};
  offlineAlertCameraPositions: AlertCameraPositionMapping[] = [];
  summaryAlertCameraPositions: AlertCameraPositionMapping[] = [];

  hasOccupancyLicence: boolean = false;
  dataExplorerLicence: boolean = false;
  peopleCountLicence: Organisation[] = [];

  organisation: Organisation = null;

  isSupport: boolean;
  isAdmin: boolean;
  seatsAvailable: number;

  useNewHomePageLayout = true;
  showTrialNewHomePageAlert = true;
  showLegacyHomepageAlert = true;
  isLoading$ = new BehaviorSubject<boolean>(false);
  isLoadingMetrics$ = new BehaviorSubject<boolean>(false);
  nowDate;
  yesterdayDate;
  lastWeekDate;
  private ngUnsubscribe = new Subject();

  constructor(
    public accountService: AccountService,
    private siteService: SiteService,
    private sitesService: SitesService,
    private cameraService: CameraService,
    private camerasService: CamerasService,
    private notificationGroupService: NotificationGroupService,
    private alertOccupancyMonitorMappingService: AlertOccupancyMonitorMappingService,
    private occupancyMonitorService: OccupancyMonitorService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peelOffMonitorService: PeelOffMonitorService,
    private occupancyMonitorCameraPositionService: OccupancyMonitorCameraPositionService,
    private peopleCountMonitorCameraPositionService: PeopleCountMonitorCameraPositionService,
    private peelOffMonitorCameraPositionService: PeelOffMonitorCameraPositionService,
    private alertService: AlertService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    private userService: UserService,
    private matDialog: MatDialog,
    private router: Router,
    private reportService: ReportService,
  ) {
    this.nowDate = formatDate(new Date(), 'HH:mm dd/MM/yy', 'en-us');
    this.yesterdayDate = formatDate(new Date().setDate(new Date().getDate() - 1), 'dd/MM/yy', 'en-us');
    this.lastWeekDate =
      formatDate(new Date().setDate(new Date().getDate() - 7), 'dd/MM/yy', 'en-us') +
      ' - ' +
      formatDate(new Date().setDate(new Date().getDate() - 1), 'dd/MM/yy', 'en-us');
  }

  ngOnInit(): void {
    this.isLoading$.next(true);

    this.isSupport = this.accountService.isSupport;
    this.isAdmin = this.accountService.isAdmin;
    this.dataExplorerLicence = this.accountService.dataExplorerLicence;
    this.organisations = this.accountService.organisations;
    this.organisation = this.accountService.organisation;

    if (this.organisation && this.organisation['maxUserSeats']) {
      this.seatsAvailable = this.organisation['maxUserSeats'];
    }

    if (this.isSupport) {
      this.useNewHomePageLayout = false;
      this.peopleCountLicence = this.organisations.filter((o) => o.licence.includes('people_count'));
      this.occupancyMonitorLicense = this.organisations.filter((o) => o.licence.includes('occupancy_monitor'));
      this.getOldHomeData();
    } else {
      this.getAllData();
    }
  }

  getOldHomeData(): void {
    this.isLoading$.next(true);

    this.sites = [];

    const requests: Observable<any>[] = [
      this.userService.listUsers(),
      this.siteService.listSites(undefined, undefined, 'active'),
      this.cameraService.listCameras(true, undefined, undefined, ['running', 'paused']),
      this.occupancyMonitorService.listOccupancyMonitors(undefined, undefined, undefined, 'active'),
      this.peopleCountMonitorService.listPeopleCountMonitors(undefined, undefined, undefined, 'active'),
      this.peelOffMonitorService.listPeelOffMonitors(undefined, undefined, undefined, 'active'),
      this.alertService.listAlerts('occupancy_alert', undefined, undefined, undefined, undefined, undefined, 'active'),

      this.alertService.listAlerts('offline_alert', undefined, undefined, undefined, undefined, undefined, 'active'),

      this.alertService.listAlerts(
        'camera_health_summary_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.notificationGroupService.listNotificationGroups(),
    ];

    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.hasOccupancyLicence = true;
          this.isLoading$.next(false);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([
          users,
          sites,
          cameras,
          occupancyMonitors,
          peopleCountMonitors,
          peelOffMonitors,
          occupancyAlerts,
          offlineAlerts,
          summaryAlerts,
          notificationGroups,
        ]) => {
          this.issueCameras = [];

          this.users = users;
          this.cameras = cameras.map((c) => this.camerasService.getCameraStatus(c));

          this.offlineAlerts = offlineAlerts;

          this.summaryAlerts = summaryAlerts;

          this.sites = sites
            .map((s) => this.sitesService.getSiteStatus(s, this.siteCameras[s.id]))
            .sort((s1, s2) => {
              if (s1.status === s2.status) {
                return compare(s1, s2, { keyToSort: 'name', direction: 'ascending' });
              } else {
                return (
                  Object.values(SiteStatus.StatusEnum).indexOf(s2.status) -
                  Object.values(SiteStatus.StatusEnum).indexOf(s1.status)
                );
              }
            });
          this.sites.forEach((site: Site) => {
            this.sitesMap[site.id] = site;
          });

          this.occupancyMonitors = occupancyMonitors.filter((monitor) => this.sitesMap[monitor.siteId]);
          this.peopleCountMonitors = peopleCountMonitors.filter((monitor) => this.sitesMap[monitor.siteId]);
          this.peelOffMonitors = peelOffMonitors.filter((monitor) => this.sitesMap[monitor.siteId]);

          this.occupancyAlerts = occupancyAlerts;
          this.notificationGroups = notificationGroups.filter((n: NotificationGroup) => n.state !== 'deleted');

          this.adminUsers = this.users.filter((u) => u.roles.includes('admin'));
          this.memberUsers = this.users.filter((u) => u.roles.includes('member'));
        },
        error: () => {
          this.router.navigate(['internal-error']);
        },
      });
  }

  getAllData(): void {
    this.gotAllData = true;
    this.isLoading$.next(false);

    this.sites = [];

    const requests: Observable<any>[] = [
      this.userService.listUsers(),
      this.siteService.listSites(undefined, undefined, 'active'),
      this.cameraService.listCameras(false),
      this.cameraService.listCameras(true, undefined, undefined, ['running', 'paused']),
      this.occupancyMonitorService.listOccupancyMonitors(undefined, undefined, undefined, 'active'),
      this.peopleCountMonitorService.listPeopleCountMonitors(undefined, undefined, undefined, 'active'),
      this.peelOffMonitorService.listPeelOffMonitors(undefined, undefined, undefined, 'active'),
      this.alertService.listAlerts('occupancy_alert', undefined, undefined, undefined, undefined, undefined, 'active'),
      this.alertOccupancyMonitorMappingService.listAlertOccupancyMonitorMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.occupancyMonitorCameraPositionService.listOccupancyMonitorCameraPositions(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.peopleCountMonitorCameraPositionService.listPeopleCountMonitorCameraPositions(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.peelOffMonitorCameraPositionService.listPeelOffMonitorCameraPositions(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.alertService.listAlerts('offline_alert', undefined, undefined, undefined, undefined, undefined, 'active'),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'offline_alert',
      ),
      this.alertService.listAlerts(
        'camera_health_summary_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'camera_health_summary_alert',
      ),
      this.notificationGroupService.listNotificationGroups(),
    ];

    forkJoin(requests)
      .pipe(
        finalize(() => {
          if (this.organisation?.licence.includes('occupancy_monitor') || this.isSupport) {
            this.hasOccupancyLicence = true;
          }
          this.isLoading$.next(false);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([
          users,
          sites,
          allCameras,
          cameras,
          occupancyMonitors,
          peopleCountMonitors,
          peelOffMonitors,
          occupancyAlerts,
          alertOccupancyMonitorMappings,
          occupancyMonitorCameraPositions,
          peopleCountMonitorCameraPositions,
          peelOffMonitorCameraPositions,
          offlineAlerts,
          offlineAlertCameraPositions,
          summaryAlerts,
          summaryAlertCameraPositions,
          notificationGroups,
        ]) => {
          this.issueCameras = [];
          this.issueCamerasLastWeek = [];
          this.incompletCameras = [];
          this.sitesMapCameraIssues = {};
          this.sitesMapCameraIssuesLastWeek = {};
          this.users = users;
          this.allCameras = allCameras;
          this.cameras = cameras.map((c) => this.camerasService.getCameraStatus(c));
          this.offlineAlertCameraPositions = offlineAlertCameraPositions ?? [];
          this.summaryAlertCameraPositions = summaryAlertCameraPositions ?? [];
          this.camerasWithNoOfflineAlerts = cameras.filter((c) => c.state === 'running');
          this.camerasWithNoSummaryAlert = cameras.filter((c) => c.state === 'running');
          const offlineAlertMapOfflineAlertCameraPositions: { [_: string]: boolean } = {};
          const summaryAlertMapSummaryAlertCameraPositions: { [_: string]: boolean } = {};
          this.offlineAlertCameraPositions.forEach((mapping) => {
            offlineAlertMapOfflineAlertCameraPositions[mapping.alertId] = true;
            this.camerasWithNoOfflineAlerts = this.camerasWithNoOfflineAlerts.filter(
              (c) => c.cameraPositionId !== mapping.cameraPositionId,
            );
          });
          this.summaryAlertCameraPositions.forEach((mapping) => {
            summaryAlertMapSummaryAlertCameraPositions[mapping.alertId] = true;
            this.camerasWithNoSummaryAlert = this.camerasWithNoSummaryAlert.filter(
              (c) => c.cameraPositionId !== mapping.cameraPositionId,
            );
          });
          this.offlineAlerts = offlineAlerts ?? [];
          this.offlineAlerts.forEach((alert) => {
            this.offlineAlertsMap[alert.id] = alert;
            if (!alert.siteId) {
              this.cameras.forEach((c) => {
                if (c.organisationId === alert.organisationId) {
                  this.addAlertToCamerasMap(this.offlineAlertsMapByCameraPositionId, c.cameraPositionId, alert);
                }
              });
              this.camerasWithNoOfflineAlerts = this.camerasWithNoOfflineAlerts.filter(
                (c) => c.organisationId !== alert.organisationId,
              );
            } else if (!offlineAlertMapOfflineAlertCameraPositions[alert.id]) {
              this.cameras.forEach((c) => {
                if (c.siteId === alert.siteId) {
                  this.addAlertToCamerasMap(this.offlineAlertsMapByCameraPositionId, c.cameraPositionId, alert);
                }
              });
              this.camerasWithNoOfflineAlerts = this.camerasWithNoOfflineAlerts.filter(
                (c) => c.siteId !== alert.siteId,
              );
            }
          });
          this.summaryAlerts = summaryAlerts ?? [];
          this.summaryAlerts.forEach((alert) => {
            this.summaryAlertsMap[alert.id] = alert;
            if (!alert.siteId) {
              this.cameras.forEach((c) => {
                if (c.organisationId === alert.organisationId) {
                  this.addAlertToCamerasMap(this.summaryAlertsMapByCameraPositionId, c.cameraPositionId, alert);
                }
              });
              this.camerasWithNoSummaryAlert = this.camerasWithNoSummaryAlert.filter(
                (c) => c.organisationId !== alert.organisationId,
              );
            } else if (!summaryAlertMapSummaryAlertCameraPositions[alert.id]) {
              this.cameras.forEach((c) => {
                if (c.siteId === alert.siteId) {
                  this.addAlertToCamerasMap(this.summaryAlertsMapByCameraPositionId, c.cameraPositionId, alert);
                }
              });
              this.camerasWithNoSummaryAlert = this.camerasWithNoSummaryAlert.filter((c) => c.siteId !== alert.siteId);
            }
          });
          this.offlineAlertCameraPositions.forEach((mapping) => {
            this.addAlertToCamerasMap(
              this.offlineAlertsMapByCameraPositionId,
              mapping.cameraPositionId,
              this.offlineAlertsMap[mapping.alertId],
            );
          });
          this.summaryAlertCameraPositions.forEach((mapping) => {
            this.addAlertToCamerasMap(
              this.summaryAlertsMapByCameraPositionId,
              mapping.cameraPositionId,
              this.summaryAlertsMap[mapping.alertId],
            );
          });
          this.cameras.forEach((camera: CameraStatus) => {
            if (this.camerasService.hasIssue(camera)) {
              this.issueCameras.push(camera);
              this.sitesMapCameraIssues[camera.siteId]
                ? this.sitesMapCameraIssues[camera.siteId].push(camera)
                : (this.sitesMapCameraIssues[camera.siteId] = [camera]);
            }
            if (
              this.camerasWithNoOfflineAlerts.find((c) => c.cameraPositionId === camera.cameraPositionId) &&
              this.camerasWithNoSummaryAlert.find((c) => c.cameraPositionId === camera.cameraPositionId)
            ) {
              this.incompletCameras.push(camera);
            }
          });
          sites.forEach((site: Site) => {
            this.siteCameras[site.id] = this.cameras.filter((camera) => camera.siteId === site.id);
          });
          this.sites = sites
            .map((s) => this.sitesService.getSiteStatus(s, this.siteCameras[s.id]))
            .sort((s1, s2) => {
              if (s1.status === s2.status) {
                return compare(s1, s2, { keyToSort: 'name', direction: 'ascending' });
              } else {
                return (
                  Object.values(SiteStatus.StatusEnum).indexOf(s2.status) -
                  Object.values(SiteStatus.StatusEnum).indexOf(s1.status)
                );
              }
            });
          this.sites.forEach((site: Site) => {
            this.sitesMap[site.id] = site;
            this.occupancyMonitorsMapBySiteId[site.id] = [];
            this.peopleCountMonitorsMapBySiteId[site.id] = [];
            this.peelOffMonitorsMapBySiteId[site.id] = [];
          });
          this.occupancyMonitors = (occupancyMonitors ?? []).filter((monitor) => this.sitesMap[monitor.siteId]);
          this.peopleCountMonitors = (peopleCountMonitors ?? []).filter((monitor) => this.sitesMap[monitor.siteId]);
          this.peelOffMonitors = (peelOffMonitors ?? []).filter((monitor) => this.sitesMap[monitor.siteId]);
          this.showQuickstarWizard =
            this.occupancyMonitors.length === 0 &&
            this.peopleCountMonitors.length === 0 &&
            this.peelOffMonitors.length === 0;
          this.occupancyMonitors.forEach((occupancyMonitor) => {
            this.occupancyMonitorsMap[occupancyMonitor.id] = occupancyMonitor;
            this.occupancyMonitorsMapBySiteId[occupancyMonitor.siteId].push(occupancyMonitor);
          });
          occupancyMonitorCameraPositions?.forEach((o) => {
            if (this.occupancyMonitorsMap[o.occupancyMonitorId]) {
              this.occupancyMonitorsMapByCameraPositionId[o.cameraPositionId]
                ? this.occupancyMonitorsMapByCameraPositionId[o.cameraPositionId].push(
                    this.occupancyMonitorsMap[o.occupancyMonitorId],
                  )
                : (this.occupancyMonitorsMapByCameraPositionId[o.cameraPositionId] = [
                    this.occupancyMonitorsMap[o.occupancyMonitorId],
                  ]);
            }
          });
          this.occupancyMonitorCameraPositionsIssues = new Set(
            this.issueCameras.flatMap((c) =>
              this.occupancyMonitorsMapByCameraPositionId[c.cameraPositionId]
                ? this.occupancyMonitorsMapByCameraPositionId[c.cameraPositionId].map((o) => o.id)
                : [],
            ),
          ).size;
          this.peopleCountMonitors.forEach((peopleCountMonitor) => {
            this.peopleCountMonitorsMap[peopleCountMonitor.id] = peopleCountMonitor;
            this.peopleCountMonitorsMapBySiteId[peopleCountMonitor.siteId].push(peopleCountMonitor);
          });
          peopleCountMonitorCameraPositions?.forEach((o) => {
            if (this.peopleCountMonitorsMap[o.peopleCountMonitorId]) {
              this.peopleCountMonitorsMapByCameraPositionId[o.cameraPositionId]
                ? this.peopleCountMonitorsMapByCameraPositionId[o.cameraPositionId].push(
                    this.peopleCountMonitorsMap[o.peopleCountMonitorId],
                  )
                : (this.peopleCountMonitorsMapByCameraPositionId[o.cameraPositionId] = [
                    this.peopleCountMonitorsMap[o.peopleCountMonitorId],
                  ]);
            }
          });
          this.peopleCountMonitorCameraPositionsIssues = new Set(
            this.issueCameras.flatMap((c) =>
              this.peopleCountMonitorsMapByCameraPositionId[c.cameraPositionId]
                ? this.peopleCountMonitorsMapByCameraPositionId[c.cameraPositionId].map((o) => o.id)
                : [],
            ),
          ).size;
          this.peelOffMonitors.forEach((peelOffMonitor) => {
            this.peelOffMonitorsMap[peelOffMonitor.id] = peelOffMonitor;
            this.peelOffMonitorsMapBySiteId[peelOffMonitor.siteId].push(peelOffMonitor);
          });
          peelOffMonitorCameraPositions?.forEach((o) => {
            if (this.peelOffMonitorsMap[o.peelOffMonitorId]) {
              this.peelOffMonitorsMapByCameraPositionId[o.cameraPositionId]
                ? this.peelOffMonitorsMapByCameraPositionId[o.cameraPositionId].push(
                    this.peelOffMonitorsMap[o.peelOffMonitorId],
                  )
                : (this.peelOffMonitorsMapByCameraPositionId[o.cameraPositionId] = [
                    this.peelOffMonitorsMap[o.peelOffMonitorId],
                  ]);
            }
          });
          this.peelOffMonitorCameraPositionsIssues = new Set(
            this.issueCameras.flatMap((c) =>
              this.peelOffMonitorsMapByCameraPositionId[c.cameraPositionId]
                ? this.peelOffMonitorsMapByCameraPositionId[c.cameraPositionId].map((o) => o.id)
                : [],
            ),
          ).size;
          this.noOccupancyAlertMonitors = [...this.occupancyMonitors];
          const occupancyAlertMapAlertOccupancyMonitorMappings: { [_: string]: boolean } = {};
          alertOccupancyMonitorMappings?.forEach((mapping) => {
            occupancyAlertMapAlertOccupancyMonitorMappings[mapping.alertId] = true;
            this.noOccupancyAlertMonitors = this.noOccupancyAlertMonitors.filter(
              (m) => m.id !== mapping.occupancyMonitorId,
            );
          });
          this.occupancyAlerts = occupancyAlerts ?? [];
          this.occupancyAlerts.forEach((alert) => {
            if (!alert.siteId) {
              this.noOccupancyAlertMonitors = this.noOccupancyAlertMonitors.filter(
                (m) => this.sitesMap[m.siteId].organisationId !== alert.organisationId,
              );
            } else if (!occupancyAlertMapAlertOccupancyMonitorMappings[alert.id]) {
              this.noOccupancyAlertMonitors = this.noOccupancyAlertMonitors.filter((m) => m.siteId !== alert.siteId);
            }
          });
          this.notificationGroups = (notificationGroups ?? []).filter((n: NotificationGroup) => n.state !== 'deleted');
          this.adminUsers = this.users.filter((u) => u.roles.includes('admin'));
          this.memberUsers = this.users.filter((u) => u.roles.includes('member'));
          this.getMetricsData();
        },
        error: () => {
          this.router.navigate(['internal-error']);
        },
      });
  }

  showNewHomePageLayout() {
    this.useNewHomePageLayout = true;
    if (!this.gotAllData) {
      this.getAllData();
    }
  }
  addAlertToCamerasMap(map: { [_: string]: Alert[] }, cameraPositionId: string, alert: Alert): void {
    if (map[cameraPositionId]) {
      map[cameraPositionId].push(alert);
    } else {
      map[cameraPositionId] = [alert];
    }
  }

  getMetricsData(): void {
    this.isLoadingMetrics$.next(true);

    const requests: Observable<any>[] = [
      this.reportService.createAggregatedVideoMetricsReport({
        cameraIds: this.cameras.map((c) => c.id).slice(0, 100),
        endDate: moment().format('YYYY-MM-DD'),
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        timeGrain: '24h',
      }),
    ];
    const tzList = [];
    const tzRequests = {};
    this.cameras.forEach((peopleCountMonitor) => {
      const siteTz = this.sitesMap[peopleCountMonitor.siteId].tz;

      if (siteTz in tzRequests) {
        tzRequests[siteTz].push(peopleCountMonitor.id);
      } else {
        tzRequests[siteTz] = [peopleCountMonitor.id];
        tzList.push(siteTz);
      }
    });

    forkJoin(requests)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([aggregatedVideoMetrics, ...PCreports]) => {
        const videoLoss = {};
        aggregatedVideoMetrics.forEach((aggregatedVideoMetric) => {
          if (!this.cameraSevenDaysSummary[aggregatedVideoMetric.cameraId]) {
            this.cameraSevenDaysSummary[aggregatedVideoMetric.cameraId] = {
              lateCount: 0,
              missingCount: 0,
              onTimeCount: 0,
            };
          }

          this.cameraSevenDaysSummary[aggregatedVideoMetric.cameraId].lateCount += aggregatedVideoMetric.lateCount;
          this.cameraSevenDaysSummary[aggregatedVideoMetric.cameraId].missingCount +=
            aggregatedVideoMetric.missingCount;
          this.cameraSevenDaysSummary[aggregatedVideoMetric.cameraId].onTimeCount += aggregatedVideoMetric.onTimeCount;

          if (!videoLoss[aggregatedVideoMetric.time]) {
            videoLoss[aggregatedVideoMetric.time] = 0;
          }
          const cameraVideoLoss = Math.round(
            (aggregatedVideoMetric.missingCount * 100) /
              (aggregatedVideoMetric.lateCount +
                aggregatedVideoMetric.missingCount +
                aggregatedVideoMetric.onTimeCount),
          );
          videoLoss[aggregatedVideoMetric.time] += cameraVideoLoss ? cameraVideoLoss : 0;
        });
        let todayLoss = 0;
        let yesterdayLoss = 0;
        let lastWeekLoss = 0;
        const today = new Date();
        today.setDate(today.getDate() - 1);
        const yesterdayDate = new Date(today);
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);
        Object.keys(videoLoss).forEach((k) => {
          videoLoss[k] = videoLoss[k] / this.cameras.map((c) => c.id).slice(0, 100).length;
          lastWeekLoss += videoLoss[k];
          if (today.toDateString() === new Date(k).toDateString()) {
            todayLoss = videoLoss[k];
          }
          if (yesterdayDate.toDateString() === new Date(k).toDateString()) {
            yesterdayLoss = videoLoss[k];
          }
        });
        lastWeekLoss = lastWeekLoss / 7;
        this.cameraVideoLoss['today'] = Number((100 - todayLoss).toPrecision(4));
        this.cameraVideoLoss['yestarday'] = Number((100 - yesterdayLoss).toPrecision(4));
        this.cameraVideoLoss['lastWeek'] = Number((100 - lastWeekLoss).toPrecision(4));

        Object.keys(this.cameraSevenDaysSummary).forEach((cameraId) => {
          const sevenDaysSummaryTotal =
            this.cameraSevenDaysSummary[cameraId].lateCount +
            this.cameraSevenDaysSummary[cameraId].missingCount +
            this.cameraSevenDaysSummary[cameraId].onTimeCount;
          if (
            sevenDaysSummaryTotal * 0.05 < this.cameraSevenDaysSummary[cameraId].missingCount &&
            !this.issueCameras.find((c) => c.id === cameraId)
          ) {
            const issueCamera = this.cameras.find((c) => c.id === cameraId);
            if (issueCamera) {
              this.issueCamerasLastWeek.push(issueCamera);

              this.sitesMapCameraIssuesLastWeek[issueCamera.siteId]
                ? this.sitesMapCameraIssuesLastWeek[issueCamera.siteId].push(issueCamera)
                : (this.sitesMapCameraIssuesLastWeek[issueCamera.siteId] = [issueCamera]);
            }
          }
          this.cameraSevenDaysSummary[cameraId].lateCount =
            Math.trunc((this.cameraSevenDaysSummary[cameraId].lateCount * 1000) / sevenDaysSummaryTotal) / 10;
          this.cameraSevenDaysSummary[cameraId].missingCount =
            Math.trunc((this.cameraSevenDaysSummary[cameraId].missingCount * 1000) / sevenDaysSummaryTotal) / 10;
          this.cameraSevenDaysSummary[cameraId].onTimeCount =
            Math.trunc((this.cameraSevenDaysSummary[cameraId].onTimeCount * 1000) / sevenDaysSummaryTotal) / 10;
        });

        this.occupancyMonitorCameraPositionsIssuesLastWeek = new Set(
          this.issueCamerasLastWeek.flatMap((c) =>
            this.occupancyMonitorsMapByCameraPositionId[c.cameraPositionId]
              ? this.occupancyMonitorsMapByCameraPositionId[c.cameraPositionId].map((o) => o.id)
              : [],
          ),
        ).size;
        this.peopleCountMonitorCameraPositionsIssuesLastWeek = new Set(
          this.issueCamerasLastWeek.flatMap((c) =>
            this.peopleCountMonitorsMapByCameraPositionId[c.cameraPositionId]
              ? this.peopleCountMonitorsMapByCameraPositionId[c.cameraPositionId].map((o) => o.id)
              : [],
          ),
        ).size;
        this.peelOffMonitorCameraPositionsIssuesLastWeek = new Set(
          this.issueCamerasLastWeek.flatMap((c) =>
            this.peelOffMonitorsMapByCameraPositionId[c.cameraPositionId]
              ? this.peelOffMonitorsMapByCameraPositionId[c.cameraPositionId].map((o) => o.id)
              : [],
          ),
        ).size;
        // ---------------------------------------
        const peopleCount = {};
        PCreports.forEach((report) => {
          report.forEach((row) => {
            peopleCount[new Date(row.observed_time).toDateString()] += row.direction1Count + row.direction2Count;
          });
        });
        this.cameraPeopleCount['lastWeek'] = 0;
        Object.keys(peopleCount).forEach((k) => {
          this.cameraPeopleCount['lastWeek'] += peopleCount[k];
          if (today.toDateString() === new Date(k).toDateString()) {
            this.cameraPeopleCount['today'] = peopleCount[k];
          }
          if (yesterdayDate.toDateString() === new Date(k).toDateString()) {
            this.cameraPeopleCount['yestarday'] = peopleCount[k];
          }
        });
        this.isLoadingMetrics$.next(false);
      });
  }
  filterAlertsAvailableForCamera(alerts: Alert[], camera: CameraStatus): Alert[] {
    return alerts.filter(
      (alert) => (!alert.siteId && alert.organisationId === camera.organisationId) || alert.siteId === camera.siteId,
    );
  }

  getCamerasSites(cameras: CameraStatus[]): number {
    return new Set(cameras.map((c) => c.siteId)).size;
  }

  openAddSiteDialog(): void {
    const dialogRef = this.matDialog.open(AddEditSiteComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.getAllData();
        }
      },
    });
  }

  openNewCameraDialog(camera?: CameraStatus): void {
    const dialogRef = this.matDialog.open(CameraSetupDialogComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        sites: this.sites,
        siteId: this.sites[0]?.id,
        cameras: this.allCameras,
        camera,
        offlineAlerts: this.offlineAlerts,
        summaryAlerts: this.summaryAlerts,
        offlineAlertCameraPositions: this.offlineAlertCameraPositions,
        summaryAlertCameraPositions: this.summaryAlertCameraPositions,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.getAllData();
        }
      },
    });
  }

  openNewPeopleCountMonitorDialog(): void {
    const dialogRef = this.matDialog.open(AddPeopleCountMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        cameras: this.cameras,
        sites: this.sites,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  openNewOccupancyMonitorDialog(): void {
    const dialogRef = this.matDialog.open(AddOccupancyMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        cameras: this.cameras.filter((c) => c.serialNumber && GlobalMethods.isOverheadCamera(c.serialNumber)),
        sites: this.sites,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  openNewPeelOffMonitorDialog(): void {
    const dialogRef = this.matDialog.open(AddEditPeelOffMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        cameras: this.cameras,
        sites: this.sites,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  formatLicenses(licenses: string[]): string {
    return GlobalMethods.formatLicenses(licenses);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
