import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-copy-link',
  templateUrl: './copy-link.component.html',
  styleUrls: ['./copy-link.component.scss'],
})
export class CopyLinkComponent {
  @ViewChild('tooltip', { static: false }) tooltip: MatTooltip;
  @Input() fullRoute: string;
  @Input() partRoute: string;
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() tooltipMessage: string;
  routerLink: string = '';

  constructor(
    private clipboard: Clipboard,
    private router: Router,
    private ref: ChangeDetectorRef,
  ) {}

  copyLink(): void {
    if (!this.fullRoute && !this.partRoute) {
      throw 'Link input are required';
    }
    this.ref.detectChanges();
    this.tooltip.show();
    const oldTooltipMessage = this.tooltipMessage;
    this.tooltipMessage = 'Link copied';
    this.routerLink = `${new URL(window.location.href).origin}${
      this.fullRoute ? this.fullRoute : this.router.url + this.partRoute
    }`;
    this.clipboard.copy(this.routerLink);

    setTimeout(() => {
      this.tooltipMessage = oldTooltipMessage;
      this.ref.detectChanges();
    }, 1000);
  }
}
