import { ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';
import { ReportsService } from 'src/app/services/reports.service';
import { OccupancyMonitor, ReportService, Site } from 'src/app/api';
import { BehaviorSubject, finalize, forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';
import { CookieService } from 'ngx-cookie-service';
import { TimePickDialogComponent } from 'src/app/views/reporting/components/time-pick-dialog/time-pick-dialog.component';
import { ComparisonDialogComponent } from 'src/app/views/reporting/components/comparison-dialog/comparison-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as ApexCharts from 'apexcharts';
import { GlobalMethods } from 'src/app/global-methods';

type TOccupancyComparisionOptions = 'time-above' | 'time-below' | 'not-applicable';
type TimeGrainEnum = '1m' | '5m' | '15m' | '30m';
@Component({
  selector: 'app-capacity-management-dialog',
  templateUrl: './capacity-management-dialog.component.html',
  styleUrls: ['./capacity-management-dialog.component.scss'],
})
export class CapacityManagementDialogComponent implements OnInit, OnDestroy {
  selectedMonitorId: string;
  occupancyMonitors: OccupancyMonitor[] = [];
  filteredMonitorOptions: OccupancyMonitor[] = [];
  occupancyMonitorsMap: { [_: string]: OccupancyMonitor } = {};
  sitesMap: { [_: string]: Site } = {};

  calendarMaxDay = moment().subtract(1, 'day').toDate();
  selectedDate = this.calendarMaxDay;

  monitorAvgOccupancy: number;

  totalPeopleIn: number;
  avgPeopleIn: number;

  isLoading$ = new BehaviorSubject<boolean>(false);

  chartLegend: { title: string; dotColor: string }[] = [
    {
      title: 'Occupancy (max)',
      dotColor: '#00BAD1',
    },
    {
      title: 'Ins',
      dotColor: '#A52CB9',
    },
    {
      title: 'Outs',
      dotColor: '#475BBC',
    },
  ];

  occupancyComparisonPercentage: number = 60;

  timeAbove;
  timeBelow;
  occupancyComparisionOptions: { [key: string]: TOccupancyComparisionOptions } = {
    timeAbove: 'time-above',
    timeBelow: 'time-below',
    notApplicable: 'not-applicable',
  };
  occupancyComparisionOption: TOccupancyComparisionOptions = this.occupancyComparisionOptions.timeAbove;

  startTime = '9:00 AM';
  endTime = '5:00 PM';

  rollingOptions: TimeGrainEnum[] = ['1m', '5m', '15m', '30m'];
  rollingOptionsMap = {
    '1m': 1,
    '5m': 5,
    '15m': 15,
    '30m': 30,
  };
  selectedRollingOption: TimeGrainEnum = '30m';

  allDayAverageIns;
  chartAverageIns = [];

  chartData;

  viewHeight: number;
  private chartArea: ElementRef;
  @ViewChild('chartArea') set content(content: ElementRef) {
    if (content) {
      this.chartArea = content;
      this.viewHeight = this.chartArea.nativeElement.offsetHeight;
      this.setChartData();
    }
  }

  avgViewHeight: number;
  private avgChart: ElementRef;
  @ViewChild('avgChart') set avgContent(content: ElementRef) {
    if (content) {
      this.avgChart = content;
      this.avgViewHeight = this.avgChart.nativeElement.offsetHeight * 0.75;
      this.setAvgChartData();
    }
  }

  private ngUnsubscribe = new Subject();
  constructor(
    private matDialog: MatDialog,
    private ref: ChangeDetectorRef,
    private cookieService: CookieService,
    private reportService: ReportService,
    private notifyService: NotifyService,
    private route: ActivatedRoute,
    private router: Router,
    public reportsService: ReportsService,
  ) {
    this.occupancyMonitors = this.reportsService.occupancyMonitors;
    this.occupancyMonitorsMap = this.reportsService.occupancyMonitorsMap;
    this.sitesMap = this.reportsService.sitesMap;
  }

  ngOnInit(): void {
    this.filteredMonitorOptions = this.occupancyMonitors;
    if (this.cookieService.get('_occupancy_reports_time_interval')) {
      ({ start: this.startTime, end: this.endTime } = JSON.parse(
        this.cookieService.get('_occupancy_reports_time_interval'),
      ));
    }
    if (this.cookieService.get('_occupancy_reports_comparison')) {
      this.occupancyComparisonPercentage = Number(this.cookieService.get('_occupancy_reports_comparison'));
    }
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        this.chartData = { '1m': undefined, '5m': undefined, '15m': undefined, '30m': undefined };
        this.selectedMonitorId = params?.id;
        this.updateMonitorData();
      },
    });
  }

  setChartData() {
    if (this.chartArea) {
      this.removeAllChildNodes(this.chartArea.nativeElement);
      this.drawChart();
      this.ref.detectChanges();
    }
  }

  setAvgChartData() {
    if (this.avgChart) {
      this.removeAllChildNodes(this.avgChart.nativeElement);
      this.drawAvgChart();
      this.ref.detectChanges();
    }
  }

  private drawChart(): void {
    const chartOptions = this.getChartOptions();
    const chart = new ApexCharts(this.chartArea.nativeElement, chartOptions);
    chart.render();
  }

  private drawAvgChart(): void {
    const { avgChartOptions, occupancy, occupancyPercentage } = this.getAvgChartOptions();
    const avgChart = new ApexCharts(this.avgChart.nativeElement, avgChartOptions);
    avgChart.render().then(() => {
      const chartElement = document.querySelector('#avgChart .apexcharts-text.apexcharts-datalabel-value');
      const chartGroup = chartElement?.parentElement;
      if (chartElement) {
        chartElement.innerHTML = `<tspan x="42%" dy="0" font-weight="bold" >${occupancyPercentage}%</tspan><tspan  dy="1.2em" x="42%">${occupancy}</tspan>`;
        const textElement = chartElement.querySelector('tspan') as SVGTSpanElement;
        if (textElement) {
          const textBBox = textElement.getBBox();
          const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
          line.setAttribute('x1', `${textBBox.x + textBBox.width * 0.25}`);
          line.setAttribute('x2', `${textBBox.x + textBBox.width * 0.75}`);
          line.setAttribute('y1', `${textBBox.y + textBBox.height + 2}`);
          line.setAttribute('y2', `${textBBox.y + textBBox.height + 2}`);
          line.setAttribute('stroke', 'var(--border-color)');
          line.setAttribute('stroke-width', '1');
          chartGroup.appendChild(line);
        }
      }
    });
  }

  private getChartOptions(): ApexCharts.ApexOptions {
    const referenceLine = { xaxis: [] };
    this.allDayAverageIns.forEach((dayOccupancyAvg) => {
      const time = moment(dayOccupancyAvg['time']);
      referenceLine['xaxis'].push(this.getTopAxisAnnotations(time.format('hA'), dayOccupancyAvg['occupancyAvg']));
    });
    const [startTimeIndex, endTimeIndex, numberOfHours] = this.getDatesRange(this.startTime, this.endTime);
    this.totalPeopleIn = this.chartData[this.selectedRollingOption].ins
      .slice(startTimeIndex, endTimeIndex)
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
    this.avgPeopleIn = Math.trunc(this.totalPeopleIn / numberOfHours);

    var maxYValue = Math.max(
      ...this.chartData[this.selectedRollingOption].occupancy.slice(startTimeIndex, endTimeIndex),
      ...this.chartData[this.selectedRollingOption].outs.slice(startTimeIndex, endTimeIndex),
      ...this.chartData[this.selectedRollingOption].ins.slice(startTimeIndex, endTimeIndex),
    );

    if (this.occupancyMonitorsMap[this.selectedMonitorId].capacity) {
      this.occupancyComparisionOption = this.occupancyComparisionOptions.timeAbove;
      this.timeAbove = 0;
      this.timeBelow = 0;
      const occupancyComparation = Math.trunc(
        (this.occupancyMonitorsMap[this.selectedMonitorId].capacity * this.occupancyComparisonPercentage) / 100,
      );
      this.chartData[this.selectedRollingOption].occupancy.slice(startTimeIndex, endTimeIndex).forEach((occupancy) => {
        if (occupancy > occupancyComparation) {
          this.timeAbove += this.rollingOptionsMap[this.selectedRollingOption];
        } else {
          this.timeBelow += this.rollingOptionsMap[this.selectedRollingOption];
        }
      });
      referenceLine['yaxis'] = [
        {
          y: occupancyComparation,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: '#00E396',
            },
            text: 'Capacity',
          },
        },
      ];
      maxYValue = Math.max(maxYValue, occupancyComparation);
    }

    return {
      series: [
        {
          name: 'Occupancy (max)',
          color: 'var(--color-turquoise)',
          data: this.chartData[this.selectedRollingOption].occupancy.slice(startTimeIndex, endTimeIndex),
        },
        {
          name: 'Ins',
          color: 'var(--color-orchid)',
          data: this.chartData[this.selectedRollingOption].ins.slice(startTimeIndex, endTimeIndex),
        },
        {
          name: 'Outs',
          color: 'var(--color-violet)',
          data: this.chartData[this.selectedRollingOption].outs.slice(startTimeIndex, endTimeIndex),
        },
      ],
      chart: {
        toolbar: { show: false },
        height: this.viewHeight,
        type: 'line',
        zoom: { enabled: false },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: this.chartData[this.selectedRollingOption].time.slice(startTimeIndex, endTimeIndex),
        labels: {
          rotate: 0,
          formatter: function (value) {
            const time = moment.parseZone(value);
            if (time.minute() === 0) {
              return time.format('hA');
            }
            return '';
          },
        },
      },
      yaxis: {
        min: 0,
        max: Math.trunc(maxYValue * 1.1),
      },
      annotations: referenceLine,
      legend: { show: false },
      stroke: { width: 2, curve: 'monotoneCubic' },
    };
  }

  private getAvgChartOptions(): any {
    const occupancy = this.monitorAvgOccupancy;
    const occupancyPercentage = this.occupancyMonitorsMap[this.selectedMonitorId].capacity
      ? Math.trunc((occupancy / this.occupancyMonitorsMap[this.selectedMonitorId].capacity) * 100)
      : 0;
    const series = [occupancyPercentage];

    const colors =
      occupancyPercentage < 75
        ? ['var(--color-secondary)']
        : occupancyPercentage < 90
          ? ['var(--color-orange)']
          : ['var(--color-red)'];

    return {
      avgChartOptions: {
        series,
        chart: {
          type: 'radialBar',
          offsetY: -10,
          height: this.avgViewHeight,
          width: this.avgViewHeight,
        },
        colors,
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '12px',
                color: '#4d5050',
                offsetY: 70,
              },
              value: {
                fontSize: '24px',
                color: '#373d3f',
                offsetY: -10,
              },
            },
          },
        },
        stroke: {
          width: -20,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        labels: ['Average occupancy'],
      },
      occupancy,
      occupancyPercentage,
    };
  }

  private getTopAxisAnnotations(time: string, occupancyAvg: number) {
    return {
      x: time,
      borderColor: 'var(--color-grey--medium)',
      label: {
        style: {
          color: '#fff',
          background: 'var(--color-orchid)',
          padding: {
            left: 5,
            right: 50,
            top: 10,
            bottom: 10,
          },
          stroke: 'unset',
        },
        text: occupancyAvg,
        orientation: 'horizontal',
        offsetY: -10,
      },
    };
  }

  private removeAllChildNodes(element: HTMLElement) {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  dayFormatting(val: string | number) {
    const date = moment(val).toDate().toLocaleString('en-US', { hour: 'numeric', hour12: true });
    return date;
  }

  openTimePickDialog(): void {
    const dialogRef = this.matDialog.open(TimePickDialogComponent, {
      maxWidth: '390px',
      data: {
        start: this.startTime,
        end: this.endTime,
        dialogTitle: 'Target Hours',
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          this.startTime = result.start;
          this.endTime = result.end;
          this.ref.detectChanges();
          if (result.saveAsDefault) {
            this.cookieService.set('_occupancy_reports_time_interval', JSON.stringify(result));
          }
          this.setChartData();
        }
      },
    });
  }

  getDatesRange(start: string, end: string) {
    const startTimeInMinutes = GlobalMethods.timeStringToMinutes(start);
    let endTimeInMinutes;
    if (end === '12:00 AM') {
      endTimeInMinutes = 1440;
    } else {
      endTimeInMinutes = GlobalMethods.timeStringToMinutes(end);
    }
    return [
      startTimeInMinutes / this.rollingOptionsMap[this.selectedRollingOption],
      endTimeInMinutes / this.rollingOptionsMap[this.selectedRollingOption],
      (endTimeInMinutes - startTimeInMinutes) / 60,
    ];
  }

  openComparisonLevelDialog(): void {
    this.matDialog
      .open(ComparisonDialogComponent, {
        maxWidth: '450px',
        data: { occupancyComparisonPercentage: this.occupancyComparisonPercentage },
      })
      .componentInstance.comparisonLevelEmitter.subscribe((occupancyComparisonPercentage) => {
        this.occupancyComparisonPercentage = occupancyComparisonPercentage.comparisonLevel;
        if (occupancyComparisonPercentage.saveAsDefault) {
          this.cookieService.set(
            '_occupancy_reports_comparison',
            occupancyComparisonPercentage.comparisonLevel.toString(),
          );
        }
        this.setChartData();
      });
  }

  initializeMonitorData() {
    this.monitorAvgOccupancy = 0;
    this.chartArea = undefined;
    this.avgChart = undefined;
    this.occupancyComparisionOption = this.occupancyComparisionOptions.notApplicable;
  }

  updateMonitorData(update = true): void {
    this.initializeMonitorData();
    this.isLoading$.next(true);

    const requests: Observable<any>[] = [];
    if (update || !this.chartData[this.selectedRollingOption]) {
      var roundedEndDate;

      roundedEndDate = new Date(this.selectedDate);
      roundedEndDate.setDate(roundedEndDate.getDate() + 1);

      requests.push(
        this.reportService.createOccupancyReport({
          occupancyMonitorIds: [this.selectedMonitorId],
          startDate: moment(this.selectedDate).format('YYYY-MM-DD'),
          endDate: moment(roundedEndDate).format('YYYY-MM-DD'),
          metrics: ['in_count_sum', 'occupancy_avg', 'occupancy_max', 'out_count_sum'],
          timeGrain: this.selectedRollingOption,
        }),
      );
      requests.push(
        this.reportService.createOccupancyReport({
          occupancyMonitorIds: [this.selectedMonitorId],
          startDate: moment(this.selectedDate).format('YYYY-MM-DD'),
          endDate: moment(roundedEndDate).format('YYYY-MM-DD'),
          metrics: ['occupancy_avg'],
          timeGrain: '1h',
        }),
      );
    }
    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([report, allDayAverageIns]) => {
          var hasNoData = true;
          this.chartData[this.selectedRollingOption] = { occupancy: [], ins: [], outs: [], time: [] };
          report.forEach((row) => {
            this.chartData[this.selectedRollingOption].occupancy.push(row['occupancyMax'] ? row['occupancyMax'] : 0);
            this.chartData[this.selectedRollingOption].ins.push(row['inCountSum'] ? row['inCountSum'] : 0);
            this.chartData[this.selectedRollingOption].outs.push(row['outCountSum'] ? row['outCountSum'] : 0);
            this.chartData[this.selectedRollingOption].time.push(row['time'] ? row['time'] : 0);
            if (
              row['time'] != undefined &&
              row['outCountSum'] != undefined &&
              row['inCountSum'] != undefined &&
              row['occupancyMax'] != undefined
            ) {
              hasNoData = false;
            }
          });
          if (hasNoData) {
            this.chartData[this.selectedRollingOption] = undefined;
          }
          if (allDayAverageIns) {
            this.allDayAverageIns = [];
            this.monitorAvgOccupancy = 0;
            allDayAverageIns.forEach((metric) => {
              if (metric.occupancyAvg !== undefined) {
                this.monitorAvgOccupancy += metric.occupancyAvg;
                this.allDayAverageIns.push({ occupancyAvg: metric.occupancyAvg, time: metric.time });
              } else {
                this.allDayAverageIns.push({ occupancyAvg: 0, time: metric.time });
              }
            });
            this.monitorAvgOccupancy = this.monitorAvgOccupancy
              ? Math.floor(Number(this.monitorAvgOccupancy / allDayAverageIns.length))
              : 0;
          }
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  filterBySiteOrMonitorName(ss: string): void {
    const mustContainString = ss.toLowerCase();

    this.filteredMonitorOptions = this.occupancyMonitors.filter(
      (occupancyMonitor: OccupancyMonitor) =>
        occupancyMonitor.name.toLowerCase().includes(mustContainString) ||
        this.sitesMap[occupancyMonitor.siteId].name.toLowerCase().includes(mustContainString),
    );
  }

  close(): void {
    history.back();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }

  changeMonitor() {
    this.router.navigate([`/reporting/capacity-management/${this.selectedMonitorId}`], { relativeTo: this.route });
  }
}
