import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject, takeUntil, finalize, Observable, switchMap, BehaviorSubject } from 'rxjs';
import {
  Alert,
  AlertCameraPositionMapping,
  AlertCameraPositionMappingService,
  AlertNotificationGroupMapping,
  AlertNotificationGroupMappingService,
  AlertService,
  Camera,
  NotificationGroup,
  Site,
} from 'src/app/api';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { AddEditCameraSummaryComponent } from 'src/app/components/notifications/add-edit-camera-summary/add-edit-camera-summary.component';
import { GlobalMethods } from 'src/app/global-methods';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-camera-summaries',
  templateUrl: './camera-summaries.component.html',
  styleUrls: ['./camera-summaries.component.scss'],
})
export class CameraSummariesComponent implements OnInit, OnDestroy {
  sites: Site[] = [];
  sitesMap: { [_: string]: Site } = {};
  cameras: Camera[] = [];
  camerasWithNoSummaryAlert: Camera[] = [];
  camerasMap: { [_: string]: Camera } = {};
  summaryCameras: { [_: string]: Camera[] } = {};
  notificationGroupsMap: { [_: string]: NotificationGroup } = {};
  sitesCameras: { [_: string]: Camera[] } = {};
  summaryAlerts: Alert[] = [];
  summaryAlertCameraPositionsMap: {
    [_: string]: AlertCameraPositionMapping[];
  } = {};
  summaryAlertNotificationGroupsMap: {
    [_: string]: AlertNotificationGroupMapping[];
  } = {};
  isLoading$ = new BehaviorSubject<boolean>(false);

  summariesPanel: { [_: string]: boolean } = {};

  viewSummaryId = '';

  private ngUnsubscribe = new Subject();
  constructor(
    private route: ActivatedRoute,
    private notifyService: NotifyService,
    private matDialog: MatDialog,
    private alertService: AlertService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    private alertNotificationGroupMappingService: AlertNotificationGroupMappingService,
    private notificationsService: NotificationsService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private elementRef: ElementRef,
  ) {
    this.cameras = notificationsService.cameras;
    this.camerasMap = notificationsService.camerasMap;
    this.sites = notificationsService.sites;
    this.sitesMap = notificationsService.sitesMap;
    this.notificationGroupsMap = notificationsService.notificationGroupsMap;

    this.sites.forEach((site: Site) => {
      this.sitesCameras[site.id] = [];
    });
    this.cameras.forEach((camera: Camera) => {
      this.sitesCameras[camera.siteId].push(camera);
    });
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.style.height = '100%';
    this.viewSummaryId = this.route.snapshot.queryParams['summaryId'];
    this.getData();
  }

  getData(): void {
    this.isLoading$.next(true);

    forkJoin([
      this.alertService.listAlerts(
        'camera_health_summary_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'camera_health_summary_alert',
      ),
      this.alertNotificationGroupMappingService.listAlertNotificationGroupMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        undefined,
        'camera_health_summary_alert',
      ),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoading$.next(false);
          if (this.viewSummaryId) {
            this.summariesPanel[this.viewSummaryId] = true;
            this.ref.detectChanges();
          }
        }),
      )
      .subscribe({
        next: ([summaryAlerts, summaryAlertCameraPositions, summaryAlertNotificationGroups]) => {
          this.summaryAlerts = summaryAlerts;
          this.summaryAlerts.forEach((summaryAlert: Alert) => {
            this.summaryCameras[summaryAlert.id] = [];
            this.summaryAlertCameraPositionsMap[summaryAlert.id] = [];
            this.summaryAlertNotificationGroupsMap[summaryAlert.id] = [];
          });

          this.camerasWithNoSummaryAlert = this.cameras.filter((c) => c.state === 'running');

          const summaryAlertMapSummaryAlertCameraPositions: { [_: string]: boolean } = {};

          summaryAlertCameraPositions.forEach((mapping) => {
            this.summaryAlertCameraPositionsMap[mapping.alertId]?.push(mapping);
            summaryAlertMapSummaryAlertCameraPositions[mapping.alertId] = true;
            this.summaryCameras[mapping.alertId]?.push(this.camerasMap[mapping.cameraPositionId]);
            this.camerasWithNoSummaryAlert = this.camerasWithNoSummaryAlert.filter(
              (c) => c.cameraPositionId !== mapping.cameraPositionId,
            );
          });

          summaryAlerts.forEach((alert) => {
            if (!alert.siteId) {
              this.cameras.forEach((c) => {
                if (c.organisationId === alert.organisationId) {
                  this.summaryCameras[alert.id].push(c);
                }
              });
              this.camerasWithNoSummaryAlert = this.camerasWithNoSummaryAlert.filter(
                (c) => c.organisationId !== alert.organisationId,
              );
            } else if (!summaryAlertMapSummaryAlertCameraPositions[alert.id]) {
              this.cameras.forEach((c) => {
                if (c.siteId === alert.siteId) {
                  this.summaryCameras[alert.id].push(c);
                }
              });
              this.camerasWithNoSummaryAlert = this.camerasWithNoSummaryAlert.filter((c) => c.siteId !== alert.siteId);
            }
          });

          summaryAlertNotificationGroups.forEach((summaryAlertNotificationGroup) => {
            this.summaryAlertNotificationGroupsMap[summaryAlertNotificationGroup.alertId].push(
              summaryAlertNotificationGroup,
            );
          });
        },
        error: (_) => {
          this.router.navigate(['internal-error']);
        },
      });
  }

  deleteSummaryAlert(summaryAlert: Alert) {
    const message = `Are you sure you want to delete the summary "${summaryAlert.name}"?`;
    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'delete', isDelete: true },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          const requests: Observable<any>[] = [];
          this.summaryAlertCameraPositionsMap[summaryAlert.id].forEach(
            (alertCameraPositionMapping: AlertCameraPositionMapping) => {
              requests.push(
                this.alertCameraPositionMappingService.deleteAlertCameraPositionMapping(alertCameraPositionMapping.id),
              );
            },
          );

          this.summaryAlertNotificationGroupsMap[summaryAlert.id].forEach(
            (alertNotificationGroupMapping: AlertNotificationGroupMapping) => {
              requests.push(
                this.alertNotificationGroupMappingService.deleteAlertNotificationGroupMapping(
                  alertNotificationGroupMapping.id,
                ),
              );
            },
          );

          if (requests.length) {
            forkJoin(requests)
              .pipe(
                switchMap((_) => this.alertService.deleteAlert(summaryAlert.id)),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe({
                next: (_) => {
                  this.getData();
                },
                error: (error) => {
                  this.notifyService.error(error);
                },
              });
          } else {
            this.alertService
              .deleteAlert(summaryAlert.id)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (_) => {
                  this.getData();
                },
                error: (error) => {
                  this.notifyService.error(error);
                },
              });
          }
        }
      },
    });
  }

  getCameraSummariesSitesIds(summaryId: string): string[] {
    const siteIds = new Set<string>();
    this.summaryCameras[summaryId].forEach((camera: Camera) => {
      siteIds.add(camera.siteId);
    });
    return [...siteIds];
  }

  getCamerasForSite(summaryId: string, siteId: string): Camera[] {
    return this.summaryCameras[summaryId].filter((camera: Camera) => camera.siteId === siteId);
  }

  addEditSummaryAlert(summaryAlert?: Alert): void {
    const data = {};
    data['sites'] = this.sites;
    data['sitesMap'] = this.sitesMap;
    data['cameras'] = this.cameras;
    data['camerasMap'] = this.camerasMap;

    if (summaryAlert) {
      data['summaryAlert'] = summaryAlert;
      data['summaryAlertCameraPositions'] = this.summaryAlertCameraPositionsMap[summaryAlert.id];
      data['notificationGroups'] = this.notificationsService.notificationGroups.filter(
        (notificationGroup) => notificationGroup.organisationId === summaryAlert.organisationId,
      );
      data['users'] = this.notificationsService.users.filter(
        (user) => user.organisationId === summaryAlert.organisationId,
      );
      data['summaryAlertNotificationGroupsMapping'] = this.summaryAlertNotificationGroupsMap[summaryAlert.id];
    } else {
      data['notificationGroups'] = this.notificationsService.notificationGroups;
      data['users'] = this.notificationsService.users;
    }

    const dialogRef = this.matDialog.open(AddEditCameraSummaryComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: data,
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.getData();
        }
      },
    });
  }

  getDurationFromSchedule(schedule: string): string {
    let dow = schedule.split(' ').slice(-1)[0];
    return dow === '*' ? 'Daily' : 'Weekly';
  }

  getDayFromSchedule(schedule: string): string {
    let dow = schedule.split(' ').slice(-1)[0];
    return GlobalMethods.getFullWeekdayName(dow);
  }

  getTimeOfDayFromSchedule(schedule: string): string {
    let [minute, hour, dow] = schedule.split(' ');
    let hours = parseInt(hour, 10);
    let suffix = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${hours}:${minute.padStart(2, '0')} ${suffix}`;
  }

  scroll(id) {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
