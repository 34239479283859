<div class="o-router-full-page-wrapper" *ngIf="(isLoading$ | async) === false">
  <router-outlet></router-outlet>
</div>

<div class="o-view-2 c-container">
  <app-navigation></app-navigation>

  <div fxFill fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.5625rem" class="c-content">
    <div class="c-header">
      <div class="c-header__first-row">
        <div class="c-header__description">
          <app-filter
            (search)="filterMonitors($event)"
            class="o-search__input is-search"
            placeholder="Search monitors"
          ></app-filter>
          <!--          <div class="is-date brevier u-flush&#45;&#45;bottom">-->
          <!--            {{ reportsService.realTimeLastUpdate | date: 'EEEE d MMMM, HH:mm' }}-->
          <!--          </div>-->
          <!--          <div class="long-primer kirk u-flush&#45;&#45;bottom">Real-time capacity management</div>-->
        </div>
        <div class="c-header__controls">
          <mat-form-field>
            <mat-select [(value)]="selectedSite" (selectionChange)="filterMonitors()" placeholder="All sites">
              <mat-option *ngFor="let site of availableSites" [value]="site.id">
                {{ site.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select [(value)]="selectedSortOption" (selectionChange)="sortFilteredMonitors()" placeholder="Sort by">
              <mat-option *ngFor="let sortOption of sortOptions | keyvalue" [value]="sortOption.value">
                Sort by {{ sortOption.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="c-header__second-row">
        <div
          *ngIf="reportsService.hasRealTimeDataUpdateAvailable"
          class="c-refresh-button"
          (click)="updateRealTimeData()"
        >
          <mat-icon class="is-icon" [class.rotate]="updating"> refresh </mat-icon>
          <span> Updates available </span>
        </div>

        <div class="is-title">Monitor</div>
        <div class="is-live-occupancy-title is-title">Live Occupancy</div>
        <div class="is-title">Today's occupancy</div>
        <div class="is-refresh-toggle">
          <div class="c-auto-refresh">
            <div>Auto refresh</div>
            <span
              (click)="toggleAutoRefresh()"
              class="material-icons is-icon"
              [class.c-icon-toggle--active]="reportsService.autoUpdateRealTimeData"
            >
              {{ reportsService.autoUpdateRealTimeData ? 'toggle_on' : 'toggle_off' }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isLoading$ | async; else notLoading">
      <div class="dot-flashing"></div>
    </div>

    <ng-template #notLoading>
      <div #realTimeDataList class="c-capacity-management-charts" (scroll)="onRealTimeDataScroll()">
        <app-real-time-data-card
          *ngFor="let occupancyMonitor of filteredOccupancyMonitors"
          [site]="sitesMap[occupancyMonitor.siteId]"
          [occupancyMonitor]="occupancyMonitor"
        ></app-real-time-data-card>
      </div>
    </ng-template>
  </div>
</div>
