import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import {
  Alert,
  AlertCameraPositionMapping,
  AlertCameraPositionMappingService,
  AlertNotificationGroupMapping,
  AlertNotificationGroupMappingService,
  AlertService,
  Camera,
  NotificationGroup,
  Site,
} from 'src/app/api';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { AddEditCameraAlertsOfflineComponent } from 'src/app/components/notifications/add-edit-camera-alerts-offline/add-edit-camera-alerts-offline.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-camera-alerts-offline',
  templateUrl: './camera-alerts-offline.component.html',
  styleUrls: ['./camera-alerts-offline.component.scss'],
})
export class CameraAlertsOfflineComponent implements OnInit, OnDestroy {
  sites: Site[] = [];
  sitesMap: { [_: string]: Site } = {};
  cameras: Camera[] = [];
  camerasWithNoOfflineAlerts: Camera[] = [];
  camerasMap: { [_: string]: Camera } = {};
  offlineAlerts: Alert[] = [];
  alertCameras: { [alertId: string]: Camera[] } = {};
  notificationGroupsMap: { [_: string]: NotificationGroup } = {};
  sitesCameras: { [_: string]: Camera[] } = {};
  offlineAlertCameraPositionsMap: { [_: string]: AlertCameraPositionMapping[] } = {};
  isLoading$ = new BehaviorSubject<boolean>(false);
  offlineAlertNotificationGroupsMap: {
    [_: string]: AlertNotificationGroupMapping[];
  } = {};
  alertsPanel: { [_: string]: boolean } = {};

  viewAlertId = '';

  private ngUnsubscribe = new Subject();
  constructor(
    private route: ActivatedRoute,
    private notifyService: NotifyService,
    private matDialog: MatDialog,
    private alertService: AlertService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    private alertNotificationGroupMappingService: AlertNotificationGroupMappingService,
    private notificationsService: NotificationsService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private elementRef: ElementRef,
  ) {
    this.cameras = notificationsService.cameras;
    this.camerasMap = notificationsService.camerasMap;
    this.sites = notificationsService.sites;
    this.sitesMap = notificationsService.sitesMap;
    this.notificationGroupsMap = notificationsService.notificationGroupsMap;

    this.sites.forEach((site: Site) => {
      this.sitesCameras[site.id] = [];
    });
    this.cameras.forEach((camera: Camera) => {
      this.sitesCameras[camera.siteId].push(camera);
    });
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.style.height = '100%';
    this.viewAlertId = this.route.snapshot.queryParams['alertId'];
    this.getData();
  }

  getData(): void {
    this.isLoading$.next(true);

    forkJoin([
      this.alertService.listAlerts('offline_alert', undefined, undefined, undefined, undefined, undefined, 'active'),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'offline_alert',
      ),
      this.alertNotificationGroupMappingService.listAlertNotificationGroupMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        undefined,
        'offline_alert',
      ),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoading$.next(false);
          if (this.viewAlertId) {
            this.alertsPanel[this.viewAlertId] = true;
            this.ref.detectChanges();
          }
        }),
      )
      .subscribe({
        next: ([offlineAlerts, offlineAlertCameraPositions, offlineAlertNotificationGroups]) => {
          this.offlineAlerts = offlineAlerts;
          this.offlineAlerts.forEach((summaryAlert: Alert) => {
            this.alertCameras[summaryAlert.id] = [];
            this.offlineAlertCameraPositionsMap[summaryAlert.id] = [];
            this.offlineAlertNotificationGroupsMap[summaryAlert.id] = [];
          });

          this.camerasWithNoOfflineAlerts = this.cameras.filter((c) => c.state === 'running');

          const summaryAlertMapSummaryAlertCameraPositions: { [_: string]: boolean } = {};

          offlineAlertCameraPositions.forEach((mapping) => {
            if (this.offlineAlertCameraPositionsMap[mapping.alertId]) {
              this.offlineAlertCameraPositionsMap[mapping.alertId].push(mapping);
              summaryAlertMapSummaryAlertCameraPositions[mapping.alertId] = true;
              this.alertCameras[mapping.alertId].push(this.camerasMap[mapping.cameraPositionId]);
              this.camerasWithNoOfflineAlerts = this.camerasWithNoOfflineAlerts.filter(
                (c) => c.cameraPositionId !== mapping.cameraPositionId,
              );
            }
          });

          offlineAlerts.forEach((alert) => {
            if (!alert.siteId) {
              this.cameras.forEach((c) => {
                if (c.organisationId === alert.organisationId) {
                  this.alertCameras[alert.id].push(c);
                }
              });
              this.camerasWithNoOfflineAlerts = this.camerasWithNoOfflineAlerts.filter(
                (c) => c.organisationId !== alert.organisationId,
              );
            } else if (!summaryAlertMapSummaryAlertCameraPositions[alert.id]) {
              this.cameras.forEach((c) => {
                if (c.siteId === alert.siteId) {
                  this.alertCameras[alert.id].push(c);
                }
              });
              this.camerasWithNoOfflineAlerts = this.camerasWithNoOfflineAlerts.filter(
                (c) => c.siteId !== alert.siteId,
              );
            }
          });

          offlineAlertNotificationGroups.forEach((summaryAlertNotificationGroup) => {
            this.offlineAlertNotificationGroupsMap[summaryAlertNotificationGroup.alertId].push(
              summaryAlertNotificationGroup,
            );
          });
        },
        error: (_) => {
          this.router.navigate(['internal-error']);
        },
      });
  }

  deleteOfflineAlert(offlineAlert: Alert) {
    const message = `Are you sure you want to delete the offline alert "${offlineAlert.name}"?`;
    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'delete', isDelete: true },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          const requests: Observable<any>[] = [];
          this.offlineAlertCameraPositionsMap[offlineAlert.id].forEach((offlineAlertCameraPosition) => {
            requests.push(
              this.alertCameraPositionMappingService.deleteAlertCameraPositionMapping(offlineAlertCameraPosition.id),
            );
          });
          this.offlineAlertNotificationGroupsMap[offlineAlert.id].forEach((offlineAlertNotificationGroupLink) => {
            requests.push(
              this.alertNotificationGroupMappingService.deleteAlertNotificationGroupMapping(
                offlineAlertNotificationGroupLink.id,
              ),
            );
          });
          if (requests.length) {
            forkJoin(requests)
              .pipe(
                switchMap((_) => this.alertService.deleteAlert(offlineAlert.id)),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe({
                next: (_) => {
                  this.getData();
                },
                error: (error) => {
                  this.notifyService.error(error);
                },
              });
          } else {
            this.alertService
              .deleteAlert(offlineAlert.id)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (_) => {
                  this.getData();
                },
                error: (error) => {
                  this.notifyService.error(error);
                },
              });
          }
        }
      },
    });
  }

  getCameraAlertsSitesIds(alertId: string): string[] {
    const siteIds = new Set<string>();
    this.alertCameras[alertId].forEach((camera: Camera) => {
      siteIds.add(camera.siteId);
    });
    return [...siteIds];
  }

  getCamerasForSite(alertId: string, siteId: string): Camera[] {
    return this.alertCameras[alertId].filter((camera: Camera) => camera.siteId === siteId);
  }

  addEditCameraAlertsOffline(offlineAlert?: Alert): void {
    const data = {};
    data['sites'] = this.sites;
    data['sitesMap'] = this.sitesMap;
    data['cameras'] = this.cameras;
    data['camerasMap'] = this.camerasMap;

    if (offlineAlert) {
      data['offlineAlert'] = offlineAlert;
      data['offlineAlertCameraPositions'] = this.offlineAlertCameraPositionsMap[offlineAlert.id];
      data['notificationGroups'] = this.notificationsService.notificationGroups.filter(
        (notificationGroup) => notificationGroup.organisationId === offlineAlert.organisationId,
      );
      data['users'] = this.notificationsService.users.filter(
        (user) => user.organisationId === offlineAlert.organisationId,
      );
      data['offlineAlertNotificationGroupLinks'] = this.offlineAlertNotificationGroupsMap[offlineAlert.id];
    } else {
      data['notificationGroups'] = this.notificationsService.notificationGroups;
      data['users'] = this.notificationsService.users;
    }

    const dialogRef = this.matDialog.open(AddEditCameraAlertsOfflineComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: data,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.getData();
        }
      },
    });
  }

  getNoCamerasAlertsSetText(): string {
    const oneCamera = `${this.camerasWithNoOfflineAlerts.length} camera does not have alerts set - `;
    const moreCameras = `${this.camerasWithNoOfflineAlerts.length} cameras don't have alerts set - `;
    return this.camerasWithNoOfflineAlerts.length === 1 ? oneCamera : moreCameras;
  }

  goToNoCamerasAlertsSet(): void {
    this.router.navigate(['alerts/no-camera-alerts']);
  }

  scroll(id) {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
