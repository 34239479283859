import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { finalize, takeUntil } from 'rxjs/operators';
import { Alert, AlertCameraPositionMappingService, AlertService, Camera, Site } from 'src/app/api';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotifyService } from 'src/app/services/notify.service';
import { AddCameraToResourceDialogComponent } from 'src/app/views/notifications/components/add-camera-to-resource-dialog/add-camera-to-resource-dialog.component';

@Component({
  selector: 'app-no-camera-alerts',
  templateUrl: './no-camera-alerts.component.html',
  styleUrls: ['./no-camera-alerts.component.scss'],
})
export class NoCameraAlertsComponent implements OnInit, OnDestroy {
  isLoading$ = new BehaviorSubject<boolean>(false);

  cameras: Camera[] = [];
  filteredCameras: Camera[] = [];
  sites: Site[] = [];
  sitesMap = {};
  selectedSites: Site[] = [];
  selection = new SelectionModel<string>(true, []);
  offlineAlerts: Alert[] = [];

  private ngUnsubscribe = new Subject();
  constructor(
    private notificationsService: NotificationsService,
    private alertService: AlertService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    private matDialog: MatDialog,
    private router: Router,
    private notifyService: NotifyService,
  ) {
    this.sites = this.notificationsService.sites;
    this.sitesMap = this.notificationsService.sitesMap;
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.isLoading$.next(true);

    forkJoin([
      this.alertService.listAlerts('offline_alert', undefined, undefined, undefined, undefined, undefined, 'active'),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'offline_alert',
      ),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoading$.next(false);
          this.applyFilter();
        }),
      )
      .subscribe({
        next: ([offlineAlerts, offlineAlertCameraPositions]) => {
          this.offlineAlerts = offlineAlerts;

          var camerasWithNoOfflineAlerts = this.notificationsService.cameras.filter((c) => c.state === 'running');

          const offlineAlertMapOfflineAlertCameraPositions: { [_: string]: boolean } = {};

          offlineAlertCameraPositions.forEach((mapping) => {
            offlineAlertMapOfflineAlertCameraPositions[mapping.alertId] = true;
            camerasWithNoOfflineAlerts = camerasWithNoOfflineAlerts.filter(
              (c) => c.cameraPositionId !== mapping.cameraPositionId,
            );
          });

          offlineAlerts.forEach((alert) => {
            if (!alert.siteId) {
              camerasWithNoOfflineAlerts = camerasWithNoOfflineAlerts.filter(
                (c) => c.organisationId !== alert.organisationId,
              );
            } else if (!offlineAlertMapOfflineAlertCameraPositions[alert.id]) {
              camerasWithNoOfflineAlerts = camerasWithNoOfflineAlerts.filter((c) => c.siteId !== alert.siteId);
            }
          });
          this.cameras = camerasWithNoOfflineAlerts;
          if (this.cameras.length === 0) {
            this.router.navigate(['alerts/camera-alerts-offline']);
          }
        },
        error: (_) => {
          this.router.navigate(['internal-error']);
        },
      });
  }

  applyFilter(sites?: Site[]): void {
    this.selectedSites = sites && sites.length !== 0 ? sites : this.sites;
    this.filter();
  }

  filter() {
    let selectedSiteIds = this.selectedSites.map((site) => site.id);
    this.filteredCameras = this.cameras.filter((camera) => selectedSiteIds.includes(camera.siteId));
  }

  addToAlert(): void {
    const cameraIds = this.selection.selected;
    const dialogRef = this.matDialog.open(AddCameraToResourceDialogComponent, {
      width: '330px',
      data: {
        resourceType: 'alert',
        resources: this.offlineAlerts,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result: string[]) => {
        if (result) {
          const requests: Observable<any>[] = [];

          result.forEach((offlineAlertRuleId: string) => {
            cameraIds.forEach((cameraId: string) => {
              requests.push(
                this.alertCameraPositionMappingService.putAlertCameraPositionMapping({
                  cameraPositionId: cameraId,
                  alertId: offlineAlertRuleId,
                }),
              );
            });
          });

          forkJoin(requests).subscribe({
            next: (_) => {
              this.notifyService.success('Alerts succesfully added');
              this.getData();
            },
            error: (error) => {
              this.notifyService.error(error);
            },
          });
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
