import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  Alert,
  AlertCameraPositionMappingService,
  AlertOccupancyMonitorMapping,
  AlertOccupancyMonitorMappingService,
  AlertService,
  Camera,
  CameraService,
  OccupancyMonitorCameraPosition,
  OccupancyMonitorCameraPositionService,
  OccupancyMonitorService,
  PeelOffMonitorCameraPosition,
  PeelOffMonitorCameraPositionService,
  PeelOffMonitorService,
  PeopleCountMonitorCameraPosition,
  PeopleCountMonitorCameraPositionService,
  PeopleCountMonitorService,
  Site,
  SiteService,
} from 'src/app/api';
import { NotifyService } from 'src/app/services/notify.service';
import { AddEditSiteComponent } from 'src/app/components/sites/add-edit-site/add-edit-site.component';
import { SiteDetailsService } from 'src/app/services/site-details.service';
import { CamerasService } from 'src/app/services/cameras.service';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss'],
})
export class SiteDetailsComponent implements OnInit, OnDestroy {
  siteId = '';

  isLoading$ = new BehaviorSubject<boolean>(true);

  private ngUnsubscribe = new Subject();

  constructor(
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
    private cameraService: CameraService,
    private camerasService: CamerasService,
    private alertService: AlertService,
    private alertOccupancyMonitorMappingService: AlertOccupancyMonitorMappingService,
    private occupancyMonitorService: OccupancyMonitorService,
    private occupancyMonitorCameraPositionService: OccupancyMonitorCameraPositionService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peelOffMonitorService: PeelOffMonitorService,
    private peopleCountMonitorCameraPositionService: PeopleCountMonitorCameraPositionService,
    private peelOffMonitorCameraPositionService: PeelOffMonitorCameraPositionService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
    private notifyService: NotifyService,
    public siteDetailsService: SiteDetailsService,
    public accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        this.siteId = params?.id;
        this.getData();
      },
    });
  }

  getData(): void {
    this.isLoading$.next(true);
    forkJoin([
      this.siteService.listSites(undefined, undefined, 'active'),
      this.cameraService.listCameras(false),
      this.cameraService.listCameras(true, undefined, undefined, ['running', 'paused']),
      this.occupancyMonitorService.listOccupancyMonitors(undefined, this.siteId, undefined, 'active'),
      this.alertService.listAlerts(
        'occupancy_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        this.siteId,
        'active',
      ),
      this.alertOccupancyMonitorMappingService.listAlertOccupancyMonitorMappings(
        undefined,
        undefined,
        this.siteId,
        undefined,
        'active',
      ),
      this.peopleCountMonitorService.listPeopleCountMonitors(undefined, this.siteId, undefined, 'active'),
      this.peelOffMonitorService.listPeelOffMonitors(undefined, this.siteId, undefined, 'active'),
      this.alertService.listAlerts('offline_alert', undefined, undefined, undefined, undefined, undefined, 'active'),
      this.alertService.listAlerts(
        'camera_health_summary_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'offline_alert',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'camera_health_summary_alert',
      ),
    ])
      .pipe(
        finalize(() => {
          // updated in getMonitorsCameras()
          // this.isLoading = false;
          // this.ref.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([
          sites,
          allCameras,
          cameras,
          occupancyMonitors,
          occupancyAlerts,
          alertOccupancyMonitorMappings,
          peopleCountMonitors,
          peelOffMonitors,
          offlineAlerts,
          summaryAlerts,
          offlineAlertCameraPositions,
          summaryAlertCameraPositions,
        ]) => {
          this.siteDetailsService.allCameras = allCameras;
          this.siteDetailsService.offlineAlerts = offlineAlerts;
          this.siteDetailsService.summaryAlerts = summaryAlerts;
          this.siteDetailsService.offlineAlertCameraPositions = offlineAlertCameraPositions;
          this.siteDetailsService.summaryAlertCameraPositions = summaryAlertCameraPositions;

          this.siteDetailsService.site = sites.find((site) => site.id === this.siteId);
          if (!this.siteDetailsService.site) {
            this.router.navigate(['not-found']);
          }

          sites = sites
            .filter((site) => site.id !== this.siteId)
            .sort((s1: Site, s2: Site) => {
              if (s1.name.toLowerCase() > s2.name.toLowerCase()) {
                return 1;
              } else {
                return -1;
              }
            });

          let monitorOccupancyAlertRules = {};

          occupancyMonitors?.forEach((monitor) => {
            monitorOccupancyAlertRules[monitor.id] = [];
          });

          let occupancyAlertRulesMap: { [_: string]: Alert } = {};
          const uniqueOccupancyAlertRules = new Set<string>();
          occupancyAlerts
            ?.filter((a) => {
              if (uniqueOccupancyAlertRules.has(a.id)) {
                return false;
              } else {
                uniqueOccupancyAlertRules.add(a.id);
                return a.state !== 'deleted';
              }
            })
            .forEach((occupancyAlertRule) => {
              occupancyAlertRulesMap[occupancyAlertRule.id] = occupancyAlertRule;
            });

          alertOccupancyMonitorMappings?.forEach((alertOccupancyMonitorMapping: AlertOccupancyMonitorMapping) => {
            monitorOccupancyAlertRules[alertOccupancyMonitorMapping.occupancyMonitorId]?.push(
              occupancyAlertRulesMap[alertOccupancyMonitorMapping.alertId],
            );
          });

          this.siteDetailsService.camerasMapByPositionId = {};
          this.siteDetailsService.cameras = cameras
            .filter((camera) => camera.siteId === this.siteId)
            .map((c) => this.camerasService.getCameraStatus(c, this.siteDetailsService.site));
          this.siteDetailsService.cameras.forEach((camera: Camera) => {
            this.siteDetailsService.camerasMapByPositionId[camera.cameraPositionId] = camera;
          });
          this.siteDetailsService.sites = sites;
          this.siteDetailsService.occupancyMonitors = occupancyMonitors ?? [];
          this.siteDetailsService.monitorOccupancyAlerts = monitorOccupancyAlertRules ?? [];
          this.siteDetailsService.peopleCountMonitors = peopleCountMonitors;
          this.siteDetailsService.peelOffMonitors = peelOffMonitors;

          this.getMonitorsCameras();
        },
        error: (error) => {
          this.notifyService.error(error);
          this.router.navigate(['internal-error']);
        },
      });
  }

  getMonitorsCameras(): void {
    this.isLoading$.next(true);

    const inUseCameraIds = new Set();
    const pendingCameraIds = new Set(Object.keys(this.siteDetailsService.camerasMapByPositionId));
    const inUseCameras = [];
    const pendingCameras = [];
    const occupancyMonitorCamerasPositions = {};
    const peopleCountMonitorCamerasPositions = {};
    const peelOffMonitorCamerasPositions = {};
    const occupancyMonitorsCameras = {};
    const peopleCountMonitorsCameras = {};
    const peelOffMonitorsCameras = {};

    this.siteDetailsService.occupancyMonitors.forEach((monitor) => {
      occupancyMonitorCamerasPositions[monitor.id] = {};
      occupancyMonitorsCameras[monitor.id] = [];
    });

    this.siteDetailsService.peopleCountMonitors.forEach((monitor) => {
      peopleCountMonitorCamerasPositions[monitor.id] = {};
      peopleCountMonitorsCameras[monitor.id] = [];
    });

    this.siteDetailsService.peelOffMonitors.forEach((monitor) => {
      peelOffMonitorCamerasPositions[monitor.id] = {};
      peelOffMonitorsCameras[monitor.id] = [];
    });

    forkJoin([
      this.occupancyMonitorCameraPositionService.listOccupancyMonitorCameraPositions(
        undefined,
        this.siteId,
        undefined,
        undefined,
        'active',
      ),
      this.peopleCountMonitorCameraPositionService.listPeopleCountMonitorCameraPositions(
        undefined,
        this.siteId,
        undefined,
        undefined,
        'active',
      ),
      this.peelOffMonitorCameraPositionService.listPeelOffMonitorCameraPositions(
        undefined,
        this.siteId,
        undefined,
        undefined,
        'active',
      ),
    ])
      .pipe(
        finalize(() => {
          inUseCameraIds.forEach((cameraPositionId: string) => {
            if (this.siteDetailsService.camerasMapByPositionId[cameraPositionId]) {
              inUseCameras.push(this.siteDetailsService.camerasMapByPositionId[cameraPositionId]);
            }
          });

          pendingCameraIds.forEach((cameraPositionId: string) => {
            if (this.siteDetailsService.camerasMapByPositionId[cameraPositionId]) {
              pendingCameras.push(this.siteDetailsService.camerasMapByPositionId[cameraPositionId]);
            }
          });

          this.siteDetailsService.inUseCameras = inUseCameras;
          this.siteDetailsService.pendingCameras = pendingCameras;
          this.siteDetailsService.occupancyMonitorsCameras = occupancyMonitorsCameras;
          this.siteDetailsService.peopleCountMonitorsCameras = peopleCountMonitorsCameras;
          this.siteDetailsService.peelOffMonitorsCameras = peelOffMonitorsCameras;
          this.siteDetailsService.occupancyMonitorCamerasPositions = occupancyMonitorCamerasPositions;
          this.siteDetailsService.peopleCountMonitorCamerasPositions = peopleCountMonitorCamerasPositions;
          this.siteDetailsService.peelOffMonitorCamerasPositions = peelOffMonitorCamerasPositions;

          this.isLoading$.next(false);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([occupancyMonitorCameraPositions, peopleCountMonitorCameraPositions, peelOffMonitorCameraPositions]) => {
          occupancyMonitorCameraPositions?.forEach((occupancyMonitorCameraPosition: OccupancyMonitorCameraPosition) => {
            if (
              !occupancyMonitorCamerasPositions[occupancyMonitorCameraPosition.occupancyMonitorId] ||
              !this.siteDetailsService.camerasMapByPositionId[occupancyMonitorCameraPosition.cameraPositionId]
            ) {
              return;
            }

            occupancyMonitorCamerasPositions[occupancyMonitorCameraPosition.occupancyMonitorId][
              occupancyMonitorCameraPosition.cameraPositionId
            ] = occupancyMonitorCameraPosition;
            occupancyMonitorsCameras[occupancyMonitorCameraPosition.occupancyMonitorId].push(
              this.siteDetailsService.camerasMapByPositionId[occupancyMonitorCameraPosition.cameraPositionId],
            );
            inUseCameraIds.add(occupancyMonitorCameraPosition.cameraPositionId);
            pendingCameraIds.delete(occupancyMonitorCameraPosition.cameraPositionId);
          });

          peopleCountMonitorCameraPositions?.forEach(
            (peopleCountMonitorCameraPosition: PeopleCountMonitorCameraPosition) => {
              if (
                !peopleCountMonitorCamerasPositions[peopleCountMonitorCameraPosition.peopleCountMonitorId] ||
                !this.siteDetailsService.camerasMapByPositionId[peopleCountMonitorCameraPosition.cameraPositionId]
              ) {
                return;
              }

              peopleCountMonitorCamerasPositions[peopleCountMonitorCameraPosition.peopleCountMonitorId][
                peopleCountMonitorCameraPosition.cameraPositionId
              ] = peopleCountMonitorCameraPosition;
              peopleCountMonitorsCameras[peopleCountMonitorCameraPosition.peopleCountMonitorId].push(
                this.siteDetailsService.camerasMapByPositionId[peopleCountMonitorCameraPosition.cameraPositionId],
              );
              inUseCameraIds.add(peopleCountMonitorCameraPosition.cameraPositionId);
              pendingCameraIds.delete(peopleCountMonitorCameraPosition.cameraPositionId);
            },
          );

          peelOffMonitorCameraPositions?.forEach((peelOffMonitorCameraPosition: PeelOffMonitorCameraPosition) => {
            if (
              !peelOffMonitorCamerasPositions[peelOffMonitorCameraPosition.peelOffMonitorId] ||
              !this.siteDetailsService.camerasMapByPositionId[peelOffMonitorCameraPosition.cameraPositionId]
            ) {
              return;
            }

            peelOffMonitorCamerasPositions[peelOffMonitorCameraPosition.peelOffMonitorId][
              peelOffMonitorCameraPosition.cameraPositionId
            ] = peelOffMonitorCameraPosition;
            peelOffMonitorsCameras[peelOffMonitorCameraPosition.peelOffMonitorId].push(
              this.siteDetailsService.camerasMapByPositionId[peelOffMonitorCameraPosition.cameraPositionId],
            );
            inUseCameraIds.add(peelOffMonitorCameraPosition.cameraPositionId);
            pendingCameraIds.delete(peelOffMonitorCameraPosition.cameraPositionId);
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  editSite(): void {
    const dialogRef = this.matDialog.open(AddEditSiteComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: { site: this.siteDetailsService.site },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.getData();
        }
      },
    });
  }

  deleteSite(): void {
    const message = `You are about to delete ${this.siteDetailsService.site.name}.

    Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'Delete' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.siteService
            .deleteSite(this.siteDetailsService.site.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (_) => {
                window.location.reload();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  viewCameras(camera?: Camera): void {
    const routerLink = 'cameras' + (camera ? `/${camera.id}` : '');
    this.router.navigate([routerLink], { relativeTo: this.route });
  }

  isSiteDetailsRoute(): boolean {
    return this.router.url.split('/').length === 3;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
