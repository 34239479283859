<div class="o-modal-full-page o-dialog-page">
  <ng-container *ngIf="!reportsError; else noReports">
    <mat-tab-group
      class="c-tab-groups"
      [selectedIndex]="selectedMenuIndex"
      (selectedTabChange)="navigateToTab(explorerTypeValue[$event.index])"
    >
      <ng-container *ngFor="let item of reportsService.dataExplorerMenuItems">
        <mat-tab label="item">
          <ng-template mat-tab-label>
            <mat-icon>{{ reportsService.dataExplorerMenuSpec[item].icon }}</mat-icon>
            {{ reportsService.dataExplorerMenuSpec[item].primary_text }}
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>

    <div class="o-modal-full-page__body c-modal-people-count-downloads-body">
      <div #vizContainer id="vizContainer" class="c-viz-reports"></div>

      <div class="c-viz-actions c-fixed-bar">
        <div class="loading" *ngIf="isLoading$ | async">
          <mat-progress-spinner color="primary" mode="indeterminate" diameter="36"></mat-progress-spinner>
        </div>
        <button class="o-button is-contained is-small" (click)="showDownloadDialog()">
          <mat-icon class="is-icon-left">download</mat-icon>
          <span> Download </span>
        </button>
        <button class="o-button is-contained is-small" (click)="refreshData()">
          <mat-icon class="is-icon-left">refresh</mat-icon>
          <span>Refresh</span>
        </button>
        <button
          class="o-button is-contained is-small mat-button-toggle"
          [matTooltip]="autoRefreshToolTip"
          matTooltipPosition="above"
        >
          <mat-icon class="is-icon-left">autorenew</mat-icon>
          <mat-slide-toggle [checked]="autoRefresh" (toggleChange)="toggleAutoRefresh()"> </mat-slide-toggle>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #noReports>
    <div class="c-reporting-not-enabled">
      <div class="c-reporting-not-enabled__text">
        <div class="is-icon">
          <mat-icon>warning</mat-icon>
        </div>
        <div class="is-text">
          <h2>There was an error while getting your data.</h2>
          <p class="u-flush">
            Please contact <a href="mailto:support@hoxton.ai" target="_blank">customer service team</a> if this problem
            persists.
          </p>
        </div>
      </div>
      <div class="c-reporting-not-enabled__image">
        <img src="/assets/images/reporting-not-enabled.jpeg" class="is-media" alt="" />
      </div>
    </div>
  </ng-template>
</div>
