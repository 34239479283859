<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading organisations...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <nav class="navigation">
    <ul class="o-breadcrumb">
      <li class="o-breadcrumb__item">
        <a routerLink="/infrastructure" class="is-link"> <mat-icon class="is-icon">dashboard</mat-icon></a>
      </li>
      <li class="o-breadcrumb__item">Organisations</li>
    </ul>
  </nav>
  <div class="c-org">
    <div class="c-org__filter" *ngIf="isSupport">
      <app-filter placeholder="Filter organisations" (search)="runSearch($event)" class="o-search__input"></app-filter>
      <div class="is-action o-button has-outline" (click)="openAddEditOrganistaion()">
        <mat-icon class="is-icon-left">add_moderator</mat-icon>
        ADD ORGANISATION
      </div>
    </div>
    <div class="c-org__list">
      <ng-container *ngFor="let organisation of filteredOrganisations">
        <app-organisation-card
          [organisation]="organisation"
          [sites]="organisationsSites[organisation.id]"
          [cameras]="organisationsCameras[organisation.id]"
          [users]="organisationsUsers[organisation.id]"
          (edit)="openAddEditOrganistaion(organisation)"
          [canEdit]="isSupport"
          [adminUsersSeatsAvailable]="organisation.maxAdminSeats"
          [memberUsersSeatsAvailable]="organisation.maxUserSeats"
        ></app-organisation-card>
      </ng-container>
    </div>
  </div>
</ng-template>
