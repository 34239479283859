import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import { UserService, SiteService } from 'src/app/api';
import { SitesOpeningHoursComponent } from 'src/app/components/sites/sites-opening-hours/sites-opening-hours.component';
import { SiteStatus } from 'src/app/model/siteStatus';
import { AccountService } from 'src/app/services/account.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnInit, OnDestroy {
  sitesWithOpeningHours = 0;
  users: User[];
  sites: SiteStatus[] = [];
  adminUsers: User[];
  memberUsers: User[];
  adminUsersSeatsAvailable: number;
  memberUsersSeatsAvailable: number;

  selectedMenuIndex;
  selectedMenuValueMapping = { 0: 'overview', 1: 'users', 2: 'opening_hours', 3: 'oauth_client' };
  selectedMenuIndexMapping = { overview: 0, users: 1, opening_hours: 2, oauth_client: 3 };

  private ngUnsubscribe = new Subject();
  constructor(
    public accountService: AccountService,
    private userService: UserService,
    private notifyService: NotifyService,
    private siteService: SiteService,
    private matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getData();

    this.route.queryParams.subscribe((queryParams) => {
      this.selectedMenuIndex = queryParams.tab ? this.selectedMenuIndexMapping[queryParams.tab] : 0;
    });
  }

  getData(): void {
    const requests: Observable<any>[] = [
      this.userService.listUsers(),
      this.siteService.listSites(undefined, undefined, 'active'),
    ];

    forkJoin(requests)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: ([users, sites]) => {
          this.users = users;
          this.sites = sites;
          this.sites.forEach((site) => {
            if (site.openingHour) {
              this.sitesWithOpeningHours += 1;
            }
          });
          this.adminUsers = this.users.filter((u) => u.roles.includes('admin'));
          this.memberUsers = this.users.filter((u) => u.roles.includes('member'));
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  openOpeningHours(): void {
    this.matDialog.open(SitesOpeningHoursComponent, {
      data: { sites: this.sites },
      width: '100vw',
      maxWidth: '100vw',
      height: '100%',
    });
  }

  navigateToTab(tab): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
