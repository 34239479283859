import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Subject, Subscription, finalize, interval, takeUntil } from 'rxjs';
import { ReportsService } from 'src/app/services/reports.service';
import { environment } from 'src/environments/environment';

declare var tableau: any;

@Component({
  selector: 'app-data-explore-dialog',
  templateUrl: './data-explore-dialog.component.html',
  styleUrls: ['./data-explore-dialog.component.scss'],
})
export class DataExploreDialogComponent implements OnDestroy, OnInit, AfterViewInit {
  isLoading$ = new BehaviorSubject<boolean>(false);
  reportsError = false;

  autoRefresh: boolean;
  autoRefreshToolTip: string;
  selectedMenuIndex;

  explorerType = {};
  explorerTypeValue = {};

  private sheetName: string;
  private device: string;

  private sheetNames = {
    'current-occupancy': 'CurrOcc CR',
    'time-series': 'Time Series CR',
    'time-comparison': 'Time Comparison CR',
    seasonality: 'Seasonality CR',
  };

  viz: any;

  private tick = interval(60000);
  private autoRefresher: Subscription;
  private vizLoadTimeoutID: any;

  private ngUnsubscribe = new Subject();
  constructor(
    public reportsService: ReportsService,
    private http: HttpClient,
    private ref: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    if (this.deviceService.isMobile()) {
      this.device = 'phone';
    } else if (this.deviceService.isTablet()) {
      this.device = 'tablet';
    } else {
      this.device = 'desktop';
    }
  }

  ngOnInit(): void {
    this.reportsService.dataExplorerMenuItems.forEach((menu, index) => {
      this.explorerType[menu] = index;
      this.explorerTypeValue[index] = menu;
    });
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      let tabIndex;
      if (queryParams.tab && this.reportsService.dataExplorerMenuItems.includes(queryParams.tab)) {
        tabIndex = this.explorerType[queryParams.tab];
      } else {
        tabIndex = this.explorerType[this.reportsService.dataExplorerMenuItems[0]];
      }
      this.changeDataExplorerMenu(tabIndex);
    });
  }

  changeDataExplorerMenu(menuItemIndex: string): void {
    this.selectedMenuIndex = menuItemIndex;
    this.sheetName = this.sheetNames[this.explorerTypeValue[this.selectedMenuIndex]];
    this.setVizContent();
    if (this.explorerTypeValue[this.selectedMenuIndex] == 'current-occupancy') {
      this.startAutoRefresh();
    } else {
      this.stopAutoRefresh();
    }
  }

  navigateToTab(tab): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab },
      queryParamsHandling: 'merge',
    });
  }

  setVizContent(): void {
    const element = document.getElementById('vizContainer') as HTMLElement;
    if (element && !element.hasChildNodes()) {
      this.isLoading$.next(true);
      this.http
        .get(`${environment.reportingUrl}?format=json`)
        .pipe(
          finalize(() => {
            this.ref.detectChanges();
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: (resp) => {
            let wb = resp['name'].split('/', 2)[0];
            let ws = this.sheetName.replace(/\s/g, '');
            const vizUrl = `https://tableau.hoxton.ai/trusted/${resp['ticket']}/views/${wb}/${ws}?${resp['filter']}`;
            const options = {
              hideTabs: true,
              width: '100%',
              height: '100%',
              hideToolbar: true,
              device: this.device,
              onFirstInteractive: this.onFirstInteractive.bind(this),
            };
            // clean the timeout on ngOnDestroy()
            this.vizLoadTimeoutID = setTimeout(this.timeoutHandler.bind(this), 10000);
            const element = document.getElementById('vizContainer') as HTMLElement;
            this.viz = new tableau.Viz(element, vizUrl, options);
          },
          error: (_) => {
            this.reportsError = true;
            this.isLoading$.next(false);
          },
        });
    } else if (element) {
      this.viz.getWorkbook().activateSheetAsync(this.sheetName);
    }
  }

  onFirstInteractive(): void {
    this.isLoading$.next(false);
    clearTimeout(this.vizLoadTimeoutID);
    if (this.sheetName === 'current-occupancy') {
      this.startAutoRefresh();
    }
    this.ref.detectChanges();
  }

  timeoutHandler(): void {
    this.reportsError = true;
    this.isLoading$.next(false);
  }

  refreshData(): void {
    if (this.viz) {
      this.viz.refreshDataAsync();
    }
  }

  showDownloadDialog(): void {
    if (this.viz) {
      this.viz.showDownloadDialog();
    }
  }

  startAutoRefresh(): void {
    this.autoRefresh = true;
    this.autoRefreshToolTip = 'Click to turn off auto-refresh';
    this.refreshData();
    this.autoRefresher = this.tick.subscribe({
      next: () => {
        this.refreshData();
      },
    });
    this.ref.detectChanges();
  }

  stopAutoRefresh(): void {
    this.autoRefresh = false;
    this.autoRefreshToolTip = 'Click to turn on auto-refresh';
    if (this.autoRefresher) this.autoRefresher.unsubscribe();
    this.ref.detectChanges();
  }

  toggleAutoRefresh(): void {
    if (this.autoRefresh) {
      this.stopAutoRefresh();
    } else {
      this.startAutoRefresh();
    }
  }

  ngOnDestroy() {
    clearTimeout(this.vizLoadTimeoutID);
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
