<div class="c-people-count-row">
  <div class="c-people-count-row__title" *ngIf="!showChartForMobile">
    <div>
      <div class="minion--caps is-site"><mat-icon>business</mat-icon>{{ site.name }}</div>
      <!-- [routerLink]="['/sites', peopleCountMonitor.siteId, 'people-count-monitor', peopleCountMonitor.id]" -->
      <div class="is-monitor-name primer kirk">
        {{ peopleCountMonitor.name }}
      </div>
    </div>
    <div class="is-icon" (click)="openDialog()"><mat-icon>insert_chart</mat-icon></div>
  </div>
  <div class="c-people-count-row__stats" [ngClass]="{ 'u-island': showChartForMobile }">
    <ng-container *ngIf="(isLoading$ | async) === false">
      <div class="c-people-count-monitor-info">
        <div>
          <div class="c-people-count-monitor-info__direction">
            {{ directionPropToDirectionNameMap[activeDirection] }}
          </div>
          <div class="paragon">{{ activeDirectionDataTotal | number }}</div>
        </div>
        <div
          class="c-people-count-monitor-info__percentage"
          [ngClass]="{
            'is-incerase': activeDirectionDataPercentage > 0,
            'is-decrease': activeDirectionDataPercentage < 0,
          }"
        >
          <mat-icon *ngIf="activeDirectionDataPercentage > 0">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="activeDirectionDataPercentage < 0">arrow_drop_down</mat-icon>
          {{ activeDirectionDataPercentage | abs }}%
          <div class="c-people-count-monitor-info__percentage-tooltip">
            <div class="brevier--bold u-margin--bottom">Week-on-week comparison.</div>
            <div class="minion u-flush--bottom">Selected week: {{ activeDirectionDataTotal | number }} visitors</div>
            <div class="minion u-flush--bottom">
              Previous week: {{ activeDirectionDataTotalLastWeek | number }} visitors
            </div>
            <div class="brevier--bold">
              <span>{{ activeDirectionDataPercentage < 0 ? '-' : '+' }}</span>
              {{ activeDirectionDataPercentage | abs }}%
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="{ 'c-people-count-directions': !showChartForMobile }">
        <div
          *ngFor="let direction of peopleCountsEnum | keyvalue"
          (click)="updateDirection(direction.value)"
          class="c-people-count-directions__item"
          [ngClass]="{ 'is-active': activeDirection === direction.value }"
        >
          <mat-icon class="is-icon">remove_red_eyes</mat-icon>
          <div class="is-direction">{{ directionPropToDirectionNameMap[direction.value] }}</div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="o-scroll-wrap" [ngClass]="{ 'has-left-scroll': showLeftScroll, 'has-right-scroll': showRightScroll }">
    <div class="scroll-left scroll-icon is-small" *ngIf="showLeftScroll" (click)="scrollLeft()">
      <mat-icon>arrow_back</mat-icon>
    </div>
    <div
      class="c-people-count-row__chart"
      [class.c-show-chart-mobile]="showChartForMobile"
      [ngClass]="{
        'is-loading': isLoading$ | async,
        'weekly-data': !reportsService.isPeopleCountDaily,
        'no-values': !activeDirectionDataTotal && !activeDirectionDataTotalLastWeek,
      }"
      #chartArea
      (scroll)="computeScrollIcons()"
      (window:resize)="setViewSize()"
    >
      <div class="dot-flashing" *ngIf="isLoading$ | async; else notLoadingData"></div>
      <ng-template #notLoadingData>
        <ngx-charts-bar-vertical-2d
          class="c-people-count-row-chart"
          [view]="view"
          [animations]="false"
          [scheme]="peopleCountVerticalChartColorScheme"
          [results]="chartResults"
          [xAxis]="true"
          [yAxis]="false"
          [legend]="false"
          [xAxisTickFormatting]="reportsService.isPeopleCountDaily ? formatDate24h : formatDateDayName"
          [dataLabelFormatting]="dataLabelFormatting"
          [showXAxisLabel]="false"
          [showYAxisLabel]="true"
          [tooltipDisabled]="false"
          [groupPadding]="1"
          [barPadding]="0"
          [showDataLabel]="true"
        >
          <ng-template #tooltipTemplate let-model="model">
            <div class="c-chart-tooltip">
              <div class="brevier--bold" [ngClass]="{ 'u-margin--bottom': reportsService.isPeopleCountDaily }">
                Week-on-week comparison:
              </div>
              <div *ngIf="!reportsService.isPeopleCountDaily" class="brevier--bold">
                (at {{ formatDateDayMonthYear(model.series) }})
              </div>
              <div class="minion u-flush--bottom">
                Selected week: {{ getResultsSeries(model.series)[0].value | number }} visitors
              </div>
              <div class="minion u-flush--bottom">
                Previous week: {{ getResultsSeries(model.series)[1].value | number }} visitors
              </div>

              <div class="brevier--bold">
                <span
                  >{{
                    getResultsSeries(model.series)[1].value - getResultsSeries(model.series)[0].value > 0 ? '-' : '+'
                  }}
                </span>
                {{ computeTemplatePercantage(model.series) | abs }}
                %
              </div>
            </div>
          </ng-template>
        </ngx-charts-bar-vertical-2d>
      </ng-template>
    </div>
    <div class="scroll-right scroll-icon is-small" *ngIf="showRightScroll" (click)="scrollRight()">
      <mat-icon>arrow_forward</mat-icon>
    </div>
  </div>
</div>
