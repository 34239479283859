import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService, User } from '@auth0/auth0-angular';
import { BehaviorSubject } from 'rxjs';
import { OAuthClient, OAuthClientLink, Organisation } from 'src/app/api';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  public isLoading$ = new BehaviorSubject<boolean>(true);
  showNotReleased = false;
  hasAppAccess = true;
  isSupport = false;
  isAdmin = false;
  isMember = false;
  role = undefined;
  canImpersonate = false;
  canCreateOAuth = false;
  canReadOAuth = false;
  canUpdateOAuth = false;
  occupancyLicence = false;
  peopleCountLicence = false;
  dataExplorerLicence = false;
  organisation: Organisation = undefined;
  organisations: Organisation[] = [];
  organisationsMap: { [_: string]: Organisation } = {};
  oAuthClientLinks: OAuthClientLink[] = [];
  oAuthClients: OAuthClient[] = [];
  user: User;
  licenseIcon = {
    data_explorer: 'query_stats',
    occupancy_monitor: 'groups',
    people_count: 'transfer_within_a_station',
    real_time: 'pending_actions',
  };
  userPreferances = {
    theme: 'light',
  };

  private managementApiUrl = 'https://auth.hoxton.ai/api/v2/users';

  constructor(
    private http: HttpClient,
    private auth: AuthService,
  ) {}

  updateUserMetadata(metadata: any): Promise<any> {
    this.setUserPreferances(metadata);
    return new Promise((resolve, reject) => {
      this.auth
        .getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://hoxtonanalytics.eu.auth0.com/api/v2/',
            scope: 'update:current_user_metadata',
          },
        })
        .subscribe({
          next: (token: string) => {
            const headers = new HttpHeaders({
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            });
            this.http
              .patch(
                `${this.managementApiUrl}/${this.user.sub}`,
                {
                  user_metadata: { ui: metadata },
                },
                { headers },
              )
              .subscribe({
                next: resolve,
                error: reject,
              });
          },
          error: reject,
        });
    });
  }

  setUserPreferances(userPreferances) {
    this.userPreferances = { ...this.userPreferances, ...userPreferances };
  }
}
