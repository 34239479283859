import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Color, PieChartComponent, ScaleType } from '@swimlane/ngx-charts';
import { MatDialog } from '@angular/material/dialog';
import { RealTimeDataCardDialogComponent } from 'src/app/views/real-time-data/component/real-time-data-card-dialog/real-time-data-card-dialog.component';

import * as moment from 'moment';
import { OccupancyMonitor, Site } from 'src/app/api';
import { ReportsService } from 'src/app/services/reports.service';
import { BehaviorSubject, filter, interval, startWith, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-real-time-data-card',
  templateUrl: './real-time-data-card.component.html',
  styleUrls: ['./real-time-data-card.component.scss'],
})
export class RealTimeDataCardComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Input() site: Site;
  @Input() occupancyMonitor: OccupancyMonitor;

  @Input() disableShowingPopup = false;
  @Input() showChartForMobile = false;

  realTimeData: {
    isLoading$: BehaviorSubject<boolean>;
    data: any[];
    currentOccupancy: number;
    lastUpdate: Date;
  };
  updating = false;

  isLoading$ = new BehaviorSubject<boolean>(true);
  occupancyPercentage;
  colorSchemePieChart: Color;
  colorSchemeLineChart: Color = {
    name: 'SchemeLineChart',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#58c0d5'],
  };

  tabletBreakpoint = '(max-width: 1023px)';
  mobileBreakPoint = '(max-width: 767px)';
  isMobile: boolean;

  pieChartResults;
  lineChartResults = [
    {
      name: 'occupancy',
      series: [],
    },
  ];
  referenceLines = [];

  xAxisTicks = [];
  yScaleMax: number;
  xScaleMin = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf();
  xScaleMax = moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).valueOf();
  lastUpdate: string;
  view: [number, number] = [3840, 120];
  private chartArea: ElementRef;
  @ViewChild('chartArea') set content(content: ElementRef) {
    if (content) {
      this.chartArea = content;
    }
  }
  @ViewChild(PieChartComponent) pieChartView: PieChartComponent;
  readonly breakpoint$ = this.breakpointObserver.observe([this.tabletBreakpoint]);

  private ngUnsubscribe = new Subject();
  constructor(
    private breakpointObserver: BreakpointObserver,
    public reportsService: ReportsService,
    private matDialog: MatDialog,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.breakpoint$.subscribe(() => this.breakpointChanged());

    this.reportsService.getOccupancyMonitorRealTimeData(this.occupancyMonitor).then((occupancyMonitorRealTimeData) => {
      this.realTimeData = occupancyMonitorRealTimeData;
      this.realTimeData.isLoading$
        .pipe(
          filter((isLoading) => !isLoading),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((_) => {
          this.isLoading$.next(true);
          if (this.occupancyMonitor.capacity) {
            this.preparePieChart();
          }
          this.prepareLineChart();
          this.isLoading$.next(false);
          this.setViewSize();

          this.updateRealTimeData();
        });
    });
  }

  updateRealTimeData(): void {
    if (this.updating) {
      return;
    }
    this.updating = true;

    // run the update once every 5 min
    interval(300000)
      .pipe(startWith(0), takeUntil(this.ngUnsubscribe))
      .subscribe((_) => {
        this.reportsService.updateOccupancyMonitorRealTimeData(this.occupancyMonitor);
      });

    interval(60000)
      .pipe(startWith(0), takeUntil(this.ngUnsubscribe))
      .subscribe((_) => {
        this.lastUpdate = moment(this.realTimeData?.lastUpdate).format('h:mm A');
        this.ref.detectChanges();
      });
  }

  dayFormatting(val: string | number) {
    const date = moment(val).toDate().toLocaleString('en-US', { hour: 'numeric', hour12: true });
    return date;
  }

  ngAfterContentChecked(): void {
    if (this.pieChartView) {
      this.pieChartView.innerRadius = 36;
    }
  }

  setViewSize() {
    const chartAreaWidth = this.chartArea.nativeElement.offsetWidth;
    let hourWidth = 80;
    if (chartAreaWidth > 480) {
      hourWidth = 120;
    }
    if (chartAreaWidth > 720) {
      hourWidth = 160;
    }

    this.view = [Math.max(24 * hourWidth, chartAreaWidth), 120];

    this.chartArea.nativeElement.scrollTo({
      left: this.view[0] - hourWidth,
      behavior: 'smooth',
    });
  }

  private prepareLineChart(): void {
    this.yScaleMax = 0;
    this.xAxisTicks = [];
    this.lineChartResults[0].series = [];
    this.referenceLines = [];

    if (this.occupancyMonitor.capacity) {
      this.yScaleMax = this.occupancyMonitor.capacity;
      this.referenceLines.push({ name: this.occupancyMonitor.capacity, value: this.occupancyMonitor.capacity });
    }

    this.realTimeData.data.forEach((dataPoint, index) => {
      if (dataPoint.time.minute() === 0 && dataPoint.time.second() === 0) {
        this.xAxisTicks.push(dataPoint.time.valueOf());
      }
      this.lineChartResults[0].series.push({
        name: dataPoint.time,
        value: dataPoint.occupancy,
      });
      this.yScaleMax = Math.max(this.yScaleMax, dataPoint.occupancy);
    });
  }

  private preparePieChart(): void {
    this.occupancyPercentage = this.realTimeData.currentOccupancy
      ? Number((this.realTimeData.currentOccupancy / this.occupancyMonitor.capacity) * 100)
      : 0;

    this.pieChartResults = [
      { name: 'progress', value: this.occupancyPercentage },
      { name: 'rest', value: 100 - this.occupancyPercentage },
    ];

    const domain =
      this.occupancyPercentage < 75
        ? ['var(--color-secondary)']
        : this.occupancyPercentage < 90
          ? ['var(--color-orange)']
          : ['var(--color-red)'];
    domain.push('#c9ccd2');
    this.colorSchemePieChart = {
      name: 'SchemePieChart',
      selectable: true,
      group: ScaleType.Ordinal,
      domain: domain,
    };
  }

  private breakpointChanged(): void {
    this.breakpointObserver.observe([this.mobileBreakPoint]).subscribe({
      next: (state: BreakpointState) => {
        this.isMobile = state.matches;
      },
    });
  }

  openDialog(): void {
    if (!this.isMobile || this.disableShowingPopup) {
      return;
    }

    this.matDialog.open(RealTimeDataCardDialogComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: { site: this.site, occupancyMonitor: this.occupancyMonitor, realTimeData: this.realTimeData },
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
