<div class="c-monitor-details-card" [class.c-monitor-details-card-selected]="selected">
  <div class="c-monitor-details-card-header">
    <mat-checkbox class="c-checkbox" [checked]="selected" (change)="selected = $event.checked; changeSelected.emit()">
      <div class="c-text-site-name minion--caps">{{ site.name }}</div>
      <div class="c-text-monitor-name">{{ peopleCountMonitor.name }}</div>
    </mat-checkbox>
  </div>
  <div class="c-monitor-details-card-content" [ngClass]="{ 'is-loading': isLoading$ | async }">
    <div class="dot-spin" *ngIf="isLoading$ | async; else notLoading"></div>
    <ng-template #notLoading>
      <div class="c-text-traffic">TRAFFIC</div>
      <div class="c-text-directions">Both directions</div>
      <div class="c-value-wrapper">
        <div class="c-text-value">{{ activeDirectionDataTotal | number }}</div>
        <div
          class="c-people-count-monitor-info__percentage"
          [ngClass]="{
            'is-incerase': activeDirectionDataPercentage > 0,
            'is-decrease': activeDirectionDataPercentage < 0,
          }"
        >
          <mat-icon *ngIf="activeDirectionDataPercentage > 0">arrow_drop_up</mat-icon>
          <mat-icon *ngIf="activeDirectionDataPercentage < 0">arrow_drop_down</mat-icon>
          {{ activeDirectionDataPercentage }}%
        </div>
      </div>
    </ng-template>
  </div>
</div>
