<mat-horizontal-stepper linear class="dialog-stepper" #stepper>
  <mat-step>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="false"
      [processingStep]="isLoading$ | async"
      [disabledNextStep]="accountService.isSupport && !orgFilterControl.value?.id"
      (openNextStep)="goToSecondStep()"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-dwell-time-report-setup-side-panel [activeStep]="0"></app-dwell-time-report-setup-side-panel>
        <div class="o-step-body">
          <div class="o-form">
            <div class="o-form__legend-icon">
              <mat-icon class="is-icon">calendar_month</mat-icon>
              <h2 class="is-legend">Start</h2>
              <h6 class="is-support-text">The date report should start</h6>
              <mat-form-field [formGroup]="range" class="is-input">
                <input
                  matInput
                  [matDatepicker]="pickerFrom"
                  (focus)="pickerFrom.open()"
                  formControlName="from"
                  readonly
                />
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="o-form__legend-icon">
              <mat-icon class="is-icon">calendar_month</mat-icon>
              <h2 class="is-legend">End</h2>
              <h6 class="is-support-text">The date report should end</h6>
              <mat-form-field [formGroup]="range" class="is-input">
                <input
                  matInput
                  [matDatepicker]="pickerTo"
                  [min]="range.value.from"
                  (focus)="pickerTo.open()"
                  formControlName="to"
                  readonly
                />
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="o-form__legend-icon" *ngIf="accountService.isSupport">
              <mat-icon class="is-icon">shield</mat-icon>
              <h2 class="is-legend">Organisation</h2>
              <h6 class="is-support-text">Select an organisation to filter monitors</h6>
              <mat-form-field class="is-input">
                <input
                  type="text"
                  placeholder="Search organisation"
                  matInput
                  [formControl]="orgFilterControl"
                  [matAutocomplete]="auto"
                />
                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="getNameFn">
                  @for (organisation of filteredOrganisations | async; track organisation) {
                    <mat-option [value]="organisation">{{ organisation.name }}</mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </app-step>
  </mat-step>

  <mat-step>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="true"
      [processingStep]="isLoading$ | async"
      [disabledNextStep]="pickedOccupancyMonitors.length === 0 && pickedPeopleCountMonitors.length === 0"
      (openNextStep)="goToReviewStep()"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-dwell-time-report-setup-side-panel [activeStep]="1"></app-dwell-time-report-setup-side-panel>
        <div class="o-step-body c-step-body">
          <div class="o-form c-monitor-selection">
            <div class="o-form__legend-icon o-flex">
              <mat-icon class="is-icon">people</mat-icon>
              <h2 class="is-legend">Occupancy Monitors</h2>
            </div>
            <div class="o-occupancy-monitors-container">
              <div class="o-monitor-list">
                <div class="o-monitor-list__title">
                  <h3 class="primer kirk">Available</h3>

                  <mat-form-field *ngIf="hasOccupancySearch" class="search-box">
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput type="text" placeholder="Search monitor" [formControl]="occupancySearchControl" />
                  </mat-form-field>
                </div>

                <mat-selection-list #availableOccupancyList>
                  <mat-list-option *ngFor="let monitor of filteredAvailableOccupancyMonitors | async" [value]="monitor">
                    {{ monitor.name }}
                  </mat-list-option>
                </mat-selection-list>
              </div>
              <div class="o-monitor-actions">
                <button
                  mat-icon-button
                  (click)="
                    moveAllOccupancyMonitorsToPicked();
                    occupancySearchControl.setValue(this.occupancySearchControl.value, { emitEvent: true })
                  "
                  [disabled]="availableOccupancyMonitors.length === 0"
                >
                  <mat-icon>{{ sideBySide ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_down' }}</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="
                    moveSelectedOccupancyMonitorsToPicked(availableOccupancyList.selectedOptions.selected);
                    occupancySearchControl.setValue(this.occupancySearchControl.value, { emitEvent: true })
                  "
                  [disabled]="
                    availableOccupancyMonitors.length === 0 ||
                    availableOccupancyList.selectedOptions.selected.length === 0
                  "
                >
                  <mat-icon>{{ sideBySide ? 'keyboard_arrow_right' : 'keyboard_arrow_down' }}</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="
                    moveSelectedOccupancyMonitorsToAvailable(pickedOccupancyList.selectedOptions.selected);
                    occupancySearchControl.setValue(this.occupancySearchControl.value, { emitEvent: true })
                  "
                  [disabled]="
                    pickedOccupancyMonitors.length === 0 || pickedOccupancyList.selectedOptions.selected.length === 0
                  "
                >
                  <mat-icon>{{ sideBySide ? 'keyboard_arrow_left' : 'keyboard_arrow_up' }}</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="
                    moveAllOccupancyMonitorsToAvailable();
                    occupancySearchControl.setValue(this.occupancySearchControl.value, { emitEvent: true })
                  "
                  [disabled]="pickedOccupancyMonitors.length === 0"
                >
                  <mat-icon>{{ sideBySide ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_up' }}</mat-icon>
                </button>
              </div>
              <div class="o-monitor-list">
                <h3 class="primer kirk">Selected</h3>
                <mat-selection-list #pickedOccupancyList>
                  <mat-list-option *ngFor="let monitor of pickedOccupancyMonitors" [value]="monitor">
                    {{ monitor.name }}
                  </mat-list-option>
                </mat-selection-list>
              </div>
            </div>
          </div>
          <div class="o-form c-monitor-selection" *ngIf="accountService.peopleCountLicence && accountService.isSupport">
            <div class="o-form__legend-icon o-flex">
              <mat-icon class="is-icon">person</mat-icon>
              <h2 class="is-legend">People count Monitors</h2>
            </div>
            <div class="o-occupancy-monitors-container">
              <div class="o-monitor-list">
                <div class="o-monitor-list__title">
                  <h3 class="primer kirk">Available</h3>

                  <mat-form-field *ngIf="hasPeopleCountSearch" class="search-box">
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput type="text" placeholder="Search monitor" [formControl]="peopleCountSearchControl" />
                  </mat-form-field>
                </div>

                <mat-selection-list #availablePeopleCountList>
                  <mat-list-option
                    *ngFor="let monitor of filteredAvailablePeopleCountMonitors | async"
                    [value]="monitor"
                  >
                    {{ monitor.name }}
                  </mat-list-option>
                </mat-selection-list>
              </div>
              <div class="o-monitor-actions">
                <button
                  mat-icon-button
                  (click)="
                    moveAllPeopleCountMonitorsToPicked();
                    peopleCountSearchControl.setValue(this.peopleCountSearchControl.value, { emitEvent: true })
                  "
                  [disabled]="availablePeopleCountMonitors.length === 0"
                >
                  <mat-icon>{{ sideBySide ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_down' }}</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="
                    moveSelectedPeopleCountMonitorsToPicked(availablePeopleCountList.selectedOptions.selected);
                    peopleCountSearchControl.setValue(this.peopleCountSearchControl.value, { emitEvent: true })
                  "
                  [disabled]="
                    availablePeopleCountMonitors.length === 0 ||
                    availablePeopleCountList.selectedOptions.selected.length === 0
                  "
                >
                  <mat-icon>{{ sideBySide ? 'keyboard_arrow_right' : 'keyboard_arrow_down' }}</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="
                    moveSelectedPeopleCountMonitorsToAvailable(pickedPeopleCountList.selectedOptions.selected);
                    peopleCountSearchControl.setValue(this.peopleCountSearchControl.value, { emitEvent: true })
                  "
                  [disabled]="
                    pickedPeopleCountMonitors.length === 0 ||
                    pickedPeopleCountList.selectedOptions.selected.length === 0
                  "
                >
                  <mat-icon>{{ sideBySide ? 'keyboard_arrow_left' : 'keyboard_arrow_up' }}</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="
                    moveAllPeopleCountMonitorsToAvailable();
                    peopleCountSearchControl.setValue(this.peopleCountSearchControl.value, { emitEvent: true })
                  "
                  [disabled]="pickedPeopleCountMonitors.length === 0"
                >
                  <mat-icon>{{ sideBySide ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_up' }}</mat-icon>
                </button>
              </div>
              <div class="o-monitor-list">
                <h3 class="primer kirk">Selected</h3>
                <mat-selection-list #pickedPeopleCountList>
                  <mat-list-option *ngFor="let monitor of pickedPeopleCountMonitors" [value]="monitor">
                    {{ monitor.name }}
                  </mat-list-option>
                </mat-selection-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-step>
  </mat-step>
  <mat-step>
    <app-step
      [stepTitle]="stepsTitle"
      [isBackable]="true"
      [nextStepText]="'FINISH'"
      [processingStep]="isLoading$ | async"
      (openNextStep)="createReport()"
      (closeStep)="close()"
    >
      <div class="c-modal-content">
        <app-dwell-time-report-setup-side-panel [activeStep]="2"></app-dwell-time-report-setup-side-panel>
        <div class="o-step-body" *ngIf="reviewForm">
          <div class="o-form c-review">
            <div class="o-form__legend-icon">
              <mat-icon class="is-icon">calendar_month</mat-icon>
              <h2 class="is-legend">Start</h2>
              <mat-form-field [formGroup]="reviewForm" class="is-input">
                <input matInput [matDatepicker]="pickerFromReview" formControlName="from" readonly />
                <mat-datepicker #pickerFromReview></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="o-form__legend-icon">
              <mat-icon class="is-icon">calendar_month</mat-icon>
              <h2 class="is-legend">End</h2>
              <mat-form-field [formGroup]="reviewForm" class="is-input">
                <input matInput [matDatepicker]="pickerToReview" formControlName="to" readonly />
                <mat-datepicker #pickerToReview></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="o-form__legend-icon" *ngIf="accountService.isSupport">
              <mat-icon class="is-icon">shield</mat-icon>
              <h2 class="is-legend">Organisation</h2>
              <mat-form-field [formGroup]="reviewForm" class="is-input">
                <input matInput formControlName="org" />
              </mat-form-field>
            </div>
            <div class="c-review__monitors">
              <div>
                <div class="o-form__legend-icon o-flex">
                  <mat-icon class="is-icon">people</mat-icon>
                  <h2 class="is-legend">Occupancy Monitors</h2>
                </div>
                <div class="o-occupancy-monitos">
                  <mat-checkbox
                    *ngFor="let monitor of occupancyMonitors"
                    (click)="$event.stopPropagation()"
                    [checked]="selectedOccupancyMonitors.isSelected(monitor.id)"
                    class="c-checkbox-camera-alert"
                    [disabled]="true"
                  >
                    {{ monitor.name }}
                  </mat-checkbox>
                </div>
              </div>
              <div *ngIf="accountService.peopleCountLicence || accountService.isSupport">
                <div class="o-form__legend-icon o-flex">
                  <mat-icon class="is-icon">transfer_within_a_station</mat-icon>
                  <h2 class="is-legend">People count Monitors</h2>
                </div>
                <div class="o-occupancy-monitos">
                  <mat-checkbox
                    *ngFor="let monitor of peopleCountMonitors"
                    (click)="$event.stopPropagation()"
                    [checked]="selectedPeopleCountMonitors.isSelected(monitor.id)"
                    class="c-checkbox-camera-alert"
                    [disabled]="true"
                  >
                    {{ monitor.name }}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-step>
  </mat-step>
</mat-horizontal-stepper>
