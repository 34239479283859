<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <div class="o-router-full-page-wrapper">
    <router-outlet></router-outlet>
  </div>
  <div class="o-view-2" [ngClass]="{ 'c-page-container': useNewHomePageLayout }">
    <ng-container *ngIf="useNewHomePageLayout; else oldHomeLayout">
      <div *ngIf="isSupport && showLegacyHomepageAlert" class="alert-use-legacy-homepage">
        <div class="alert-use-legacy-homepage__button" (click)="useNewHomePageLayout = false">
          Use legacy home page design.
          <mat-icon class="c-icon-arrow-forward-new-control-room"> arrow_forward </mat-icon>
        </div>
        <mat-icon class="is-close" (click)="showLegacyHomepageAlert = false">close</mat-icon>
      </div>

      <app-navigation></app-navigation>
      <app-quickstart
        [addedSite]="sites.length !== 0"
        [addedUser]="users.length !== 0"
        [addedCamera]="cameras.length !== 0"
        *ngIf="showQuickstarWizard"
        (closeWizard)="showQuickstarWizard = false"
      ></app-quickstart>
      <div class="c-display-resources" *ngIf="cameras.length !== 0">
        <app-infrastructure-aside
          [sites]="sites"
          [sitesMap]="sitesMap"
          [cameras]="cameras"
          [issueCameras]="issueCameras"
          [incompletCameras]="incompletCameras"
          [summaryAlerts]="summaryAlerts"
          [camerasWithNoSummaryAlert]="camerasWithNoSummaryAlert"
          [offlineAlerts]="offlineAlerts"
          [camerasWithNoOfflineAlerts]="camerasWithNoOfflineAlerts"
          [occupancyMonitors]="occupancyMonitors"
          [peopleCountMonitors]="peopleCountMonitors"
          [peelOffMonitors]="peelOffMonitors"
          [occupancyAlerts]="occupancyAlerts"
          [noOccupancyAlertMonitors]="noOccupancyAlertMonitors"
          (addSite)="openAddSiteDialog()"
          (addCamera)="openNewCameraDialog()"
          (addOccupancyMonitor)="openNewOccupancyMonitorDialog()"
          (addPeopleCountMonitor)="openNewPeopleCountMonitorDialog()"
          (addPeelOffMonitor)="openNewPeelOffMonitorDialog()"
          (continueSetup)="openNewCameraDialog($event)"
        ></app-infrastructure-aside>
        <div class="c-camera-metrics">
          <div class="c-camera-metrics__list u-margin--bottom">
            <app-metrics-card
              [isLoadingMetrics]="isLoadingMetrics$ | async"
              [titel]="'TODAY'"
              [titelDate]="nowDate"
              [videoUploaded]="cameraVideoLoss['today']"
              [peopleCounted]="cameraPeopleCount['today']"
            ></app-metrics-card>
            <app-metrics-card
              [isLoadingMetrics]="isLoadingMetrics$ | async"
              [titel]="'YESTERDAY'"
              [titelDate]="yesterdayDate"
              [videoUploaded]="cameraVideoLoss['yestarday']"
              [peopleCounted]="cameraPeopleCount['yestarday']"
              [backgroundColor]="'var(--color-mikado--light-2)'"
            ></app-metrics-card>
            <app-metrics-card
              [isLoadingMetrics]="isLoadingMetrics$ | async"
              [titel]="'LAST 7 DAYS'"
              [titelDate]="lastWeekDate"
              [videoUploaded]="cameraVideoLoss['lastWeek']"
              [peopleCounted]="cameraPeopleCount['lastWeek']"
              [backgroundColor]="'var(--color-mikado--light-3)'"
            ></app-metrics-card>
          </div>

          <div class="c-camera-issues">
            <ng-container *ngIf="!issueCameras.length; else cameraIssues">
              <div class="c-camera-issues__title">
                <div class="is-primary-text">Current camera issues.</div>
              </div>
              <div class="c-camera-issues__no-issues u-margin--bottom">
                <div class="is-title">
                  <mat-icon class="is-icon">camera_alt</mat-icon>
                  <div class="is-badge brevier--caps u-flush--bottom">No current issues</div>
                </div>
                <div class="is-text">
                  <b
                    >{{ cameras.length }} {{ cameras.length | pluraliseWord: 'camera' }} online and operating
                    correctly.</b
                  >
                </div>
              </div>
            </ng-container>

            <ng-template #cameraIssues>
              <div class="c-camera-issues__title">
                <div class="is-primary-text">
                  {{ issueCameras.length }} {{ issueCameras.length | pluraliseWord: 'camera' }} currently with issues.
                </div>
                <div class="is-secondary-text">
                  <b>
                    {{
                      occupancyMonitorCameraPositionsIssues +
                        peopleCountMonitorCameraPositionsIssues +
                        peelOffMonitorCameraPositionsIssues
                    }}
                    {{
                      occupancyMonitorCameraPositionsIssues +
                        peopleCountMonitorCameraPositionsIssues +
                        peelOffMonitorCameraPositionsIssues | pluraliseWord: 'monitor'
                    }}
                  </b>
                  impacted at
                  <b> {{ getCamerasSites(issueCameras) }} {{ issueCameras.length | pluraliseWord: 'site' }} </b> by
                  these issues.
                </div>
              </div>
              <app-expansion-panel
                *ngFor="let camera of issueCameras"
                [camera]="camera"
                [site]="sitesMap[camera.siteId]"
                [siteCameraIssues]="sitesMapCameraIssues[camera.siteId]"
                [cameraOfflineAlerts]="offlineAlertsMapByCameraPositionId[camera.cameraPositionId]"
                [cameraSummaryAlerts]="summaryAlertsMapByCameraPositionId[camera.cameraPositionId]"
                [offlineAlerts]="filterAlertsAvailableForCamera(offlineAlerts, camera)"
                [summaryAlerts]="filterAlertsAvailableForCamera(summaryAlerts, camera)"
                [occupancyMonitors]="occupancyMonitorsMapByCameraPositionId[camera.cameraPositionId]"
                [peopleCountMonitors]="peopleCountMonitorsMapByCameraPositionId[camera.cameraPositionId]"
                [peelOffMonitors]="peelOffMonitorsMapByCameraPositionId[camera.cameraPositionId]"
              ></app-expansion-panel>
            </ng-template>

            <!-- last 7 days -->

            <ng-container *ngIf="(isLoadingMetrics$ | async) === false">
              <div class="c-camera-issues__title">
                <div class="is-primary-text">
                  {{ issueCamerasLastWeek.length }} {{ issueCamerasLastWeek.length | pluraliseWord: 'camera' }} with
                  issues over last 7 days.
                </div>
                <div class="is-secondary-text">
                  <b>
                    {{
                      occupancyMonitorCameraPositionsIssuesLastWeek + peopleCountMonitorCameraPositionsIssuesLastWeek
                    }}
                    {{
                      occupancyMonitorCameraPositionsIssuesLastWeek + peopleCountMonitorCameraPositionsIssuesLastWeek
                        | pluraliseWord: 'monitor'
                    }}
                  </b>
                  impacted at
                  <b>
                    {{ getCamerasSites(issueCamerasLastWeek) }}
                    {{ issueCamerasLastWeek.length | pluraliseWord: 'site' }}
                  </b>
                  by these issues.
                </div>
              </div>
              <app-expansion-panel
                *ngFor="let camera of issueCamerasLastWeek"
                [camera]="camera"
                [site]="sitesMap[camera.siteId]"
                [siteCameraIssues]="sitesMapCameraIssuesLastWeek[camera.siteId]"
                [cameraOfflineAlerts]="offlineAlertsMapByCameraPositionId[camera.cameraPositionId]"
                [cameraSummaryAlerts]="summaryAlertsMapByCameraPositionId[camera.cameraPositionId]"
                [offlineAlerts]="filterAlertsAvailableForCamera(offlineAlerts, camera)"
                [summaryAlerts]="filterAlertsAvailableForCamera(summaryAlerts, camera)"
                [occupancyMonitors]="occupancyMonitorsMapByCameraPositionId[camera.cameraPositionId]"
                [peopleCountMonitors]="peopleCountMonitorsMapByCameraPositionId[camera.cameraPositionId]"
                [sevenDaysSummary]="cameraSevenDaysSummary[camera.cameraPositionId]"
              ></app-expansion-panel>
            </ng-container>
          </div>
        </div>
      </div>
      <app-add-first-site
        *ngIf="sites.length === 0"
        (addSite)="openAddSiteDialog()"
        id="add-first-site"
      ></app-add-first-site>
      <app-no-site-permission *ngIf="!accountService.isSupport && !accountService.isAdmin"></app-no-site-permission>

      <app-add-first-camera
        *ngIf="cameras.length === 0"
        id="add-first-camera"
        [canAddCamera]="sites.length !== 0"
        (addCamera)="openNewCameraDialog()"
      ></app-add-first-camera>
      <app-add-first-monitor
        id="add-first-monitor"
        *ngIf="
          peopleCountMonitors.length === 0 &&
          peelOffMonitors.length === 0 &&
          (!accountService.occupancyLicence || occupancyMonitors.length === 0)
        "
        [canAddMonitor]="cameras.length !== 0"
        (addPeopleCountMonitor)="openNewPeopleCountMonitorDialog()"
        (addPeelOffMonitor)="openNewPeelOffMonitorDialog()"
        (addOccupancyMonitor)="openNewOccupancyMonitorDialog()"
      ></app-add-first-monitor>
    </ng-container>

    <!-- old home -->
    <ng-template #oldHomeLayout>
      <div *ngIf="isSupport && showTrialNewHomePageAlert" class="c-info-new-control-room">
        <div class="c-info-new-control-room__button" (click)="showNewHomePageLayout()">
          <mat-icon>fiber_new</mat-icon>
          <div class="is-label">Trial the new control room home page.</div>
          <mat-icon> arrow_forward </mat-icon>
        </div>
        <div class="c-info-new-control-room__text">
          <mat-icon class="is-close" (click)="showTrialNewHomePageAlert = false">close</mat-icon>
        </div>
      </div>

      <div class="o-card-grid c-home-card-grid">
        <ng-container *ngIf="isSupport">
          <div class="o-category-card">
            <div class="o-category-card__head">
              <mat-icon>shield</mat-icon>
              <h3 class="is-text">{{ organisations.length }} organisations</h3>
            </div>
            <div class="o-category-card__body">
              <ul class="o-list">
                <li *ngIf="occupancyMonitorLicense.length !== 0">
                  <div class="o-list__item--block">
                    <div class="o-list__item-icon">
                      <mat-icon>reduce_capacity</mat-icon>
                    </div>
                    <div class="o-list__item-text is-primer-bold">
                      {{ occupancyMonitorLicense.length }} occupancy licences
                    </div>
                  </div>
                </li>

                <li *ngIf="peopleCountLicence.length !== 0">
                  <div class="o-list__item--block">
                    <div class="o-list__item-icon">
                      <mat-icon>people</mat-icon>
                    </div>
                    <div class="o-list__item-text is-primer-bold">
                      {{ peopleCountLicence.length }} people count licences
                    </div>
                  </div>
                </li>
              </ul>
              <div class="is-media">
                <!-- <img src="assets/images/site-museum.png" alt="Site" /> -->
              </div>
            </div>
            <div class="o-category-card__action">
              <a class="o-button" [routerLink]="['/organisations']">
                View organisations
                <mat-icon class="is-icon-right">arrow_forward</mat-icon>
              </a>
            </div>
          </div>
          <div class="o-category-card">
            <div class="o-category-card__head">
              <mat-icon>apartment</mat-icon>
              <h3 class="is-text">{{ sites.length }} {{ sites.length | pluraliseWord: 'site' }}</h3>
            </div>
            <div class="o-category-card__body">
              <div class="is-media">
                <img src="assets/images/site-museum.png" alt="Site" />
              </div>
            </div>
            <div class="o-category-card__action">
              <a [routerLink]="['/sites']" class="o-button">
                View sites
                <mat-icon class="is-icon-right">arrow_forward</mat-icon>
              </a>
            </div>
          </div>
        </ng-container>
        <div class="o-category-card">
          <div class="o-category-card__head">
            <mat-icon>photo_camera</mat-icon>
            <h3 class="is-text">
              <ng-container *ngIf="!isAdmin && sites.length === 0; else hasCamerasTitle">Cameras</ng-container>
              <ng-template #hasCamerasTitle
                >{{ cameras.length }} {{ cameras.length | pluraliseWord: 'camera' }}</ng-template
              >
            </h3>
          </div>
          <div class="o-category-card__body">
            <ul class="o-list is-list">
              <li class="o-list__item--block" *ngIf="!isAdmin && sites.length === 0">
                <div class="o-list__item-icon">
                  <mat-icon>photo_camera</mat-icon>
                </div>
                <div class="o-list__item-text is-primer-bold">n/a</div>
              </li>
            </ul>
            <div class="is-media">
              <img src="assets/images/camera-landing-thumb.png" alt="Cameras" />
            </div>
          </div>
          <div class="o-category-card__action">
            <a
              [routerLink]="!isAdmin && sites.length === 0 ? [] : ['/cameras']"
              class="o-button"
              [ngClass]="{ 'is-disabled': !isAdmin && sites.length === 0 }"
            >
              View all cameras
              <mat-icon class="is-icon-right">arrow_forward</mat-icon>
            </a>
          </div>
        </div>
        <div class="o-category-card" *ngIf="hasOccupancyLicence">
          <div class="o-category-card__head">
            <mat-icon>view_quilt</mat-icon>
            <h3 class="is-text">
              {{ occupancyMonitors.length + peopleCountMonitors.length + peelOffMonitors.length }}
              {{
                occupancyMonitors.length + peopleCountMonitors.length + peelOffMonitors.length
                  | pluraliseWord: 'Monitor'
              }}
            </h3>
          </div>
          <div class="o-category-card__body">
            <ul class="o-list">
              <li>
                <a
                  class="o-list__item--block is-link"
                  [routerLink]="['/monitors']"
                  [queryParams]="{
                    type: 'people_count',
                  }"
                >
                  <div class="o-list__item-icon">
                    <mat-icon>transfer_within_a_station</mat-icon>
                  </div>
                  <div class="o-list__item-text is-primer-bold">{{ peopleCountMonitors.length }} people count</div>
                </a>
              </li>
              <li>
                <a
                  class="o-list__item--block is-link"
                  [routerLink]="['/monitors']"
                  [queryParams]="{
                    type: 'peel_off',
                  }"
                >
                  <div class="o-list__item-icon">
                    <mat-icon>fork_left</mat-icon>
                  </div>
                  <div class="o-list__item-text is-primer-bold is-new-feature">
                    <div class="is-new-feature__label">{{ peelOffMonitors.length }} peel off</div>
                    <div class="is-new-feature__badge">New</div>
                  </div>
                </a>
              </li>
              <li>
                <a
                  class="o-list__item--block is-link"
                  [routerLink]="['/monitors']"
                  [queryParams]="{
                    type: 'occupancy',
                  }"
                >
                  <div class="o-list__item-icon">
                    <mat-icon>groups</mat-icon>
                  </div>
                  <div class="o-list__item-text is-primer-bold">{{ occupancyMonitors.length }} occupancy</div>
                </a>
              </li>
            </ul>
            <div class="is-media">
              <img src="assets/images/monitors.png" alt="Monitors" />
            </div>
          </div>
          <div class="o-category-card__action">
            <a class="o-button" [routerLink]="['/monitors']">
              View monitors
              <mat-icon class="is-icon-right">arrow_forward</mat-icon>
            </a>
          </div>
        </div>
        <div class="o-category-card" *ngIf="isSupport">
          <div class="o-category-card__head">
            <mat-icon>supervised_user_circle</mat-icon>
            <h3 class="is-text">{{ users.length }} {{ users.length | pluraliseWord: 'user' }}</h3>
          </div>
          <div class="o-category-card__body">
            <ul class="o-list is-list">
              <li *ngIf="adminUsers.length !== 0">
                <a class="o-list__item--block is-link" [routerLink]="['/users']">
                  <div class="o-list__item-icon">
                    <mat-icon>admin_panel_settings</mat-icon>
                  </div>
                  <div class="o-list__item-text is-primer-bold">
                    {{ adminUsers.length }} {{ adminUsers.length | pluraliseWord: 'admin' }}
                  </div>
                </a>
              </li>
              <li *ngIf="memberUsers.length !== 0">
                <a class="o-list__item--block is-link" [routerLink]="['/users']">
                  <div class="o-list__item-icon">
                    <mat-icon>person</mat-icon>
                  </div>
                  <div class="o-list__item-text is-primer-bold">
                    {{ memberUsers.length }} {{ memberUsers.length | pluraliseWord: 'member' }}
                  </div>
                </a>
              </li>
              <li *ngIf="seatsAvailable !== undefined">
                <a class="o-list__item--block is-link" [routerLink]="['/users']">
                  <div class="o-list__item-icon">
                    <mat-icon>recent_actors</mat-icon>
                  </div>
                  <div *ngIf="seatsAvailable - users.length > 0" class="o-list__item-text is-primer-bold">
                    {{ seatsAvailable - users.length }}
                    {{ seatsAvailable - users.length | pluraliseWord: 'seat' }} available
                  </div>
                  <div *ngIf="seatsAvailable - users.length <= 0" class="o-list__item-text is-primer-bold">
                    All seats allocated
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="o-category-card__action">
            <a class="o-button" [routerLink]="['/users']">
              Manage users
              <mat-icon class="is-icon-right">arrow_forward</mat-icon>
            </a>
          </div>
        </div>
        <div class="o-category-card">
          <div class="o-category-card__head">
            <mat-icon>notifications</mat-icon>
            <h3 class="is-text">Configured alerts</h3>
          </div>
          <div class="o-category-card__body">
            <ul class="o-list">
              <ng-container *ngIf="!isAdmin && sites.length === 0; else hasAlerts">
                <li class="o-list__item--block">
                  <div class="o-list__item-icon">
                    <mat-icon>no_photography</mat-icon>
                  </div>
                  <div class="o-list__item-text is-primer-bold">n/a</div>
                </li>
              </ng-container>
              <ng-template #hasAlerts>
                <li>
                  <a class="o-list__item--block is-link" [routerLink]="['/alerts/camera-alerts-offline']">
                    <div class="o-list__item-icon">
                      <mat-icon>no_photography</mat-icon>
                    </div>
                    <div class="o-list__item-text is-primer-bold">
                      {{ offlineAlerts.length }} offline camera
                      {{ offlineAlerts.length | pluraliseWord: 'alert' }}
                    </div>
                  </a>
                </li>
                <li>
                  <a class="o-list__item--block is-link" [routerLink]="['/alerts/camera-summaries']">
                    <div class="o-list__item-icon">
                      <mat-icon>emergency</mat-icon>
                    </div>
                    <div class="o-list__item-text is-primer-bold">
                      {{ summaryAlerts.length }} camera health
                      {{ summaryAlerts.length === 1 ? 'summary' : 'summaries' }}
                    </div>
                  </a>
                </li>
                <li>
                  <a class="o-list__item--block is-link" [routerLink]="['/alerts/monitor-alerts']" is-link>
                    <div class="o-list__item-icon">
                      <mat-icon>reduce_capacity</mat-icon>
                    </div>
                    <div class="o-list__item-text is-primer-bold">
                      {{ occupancyAlerts.length }} occupancy
                      {{ occupancyAlerts.length | pluraliseWord: 'alert' }}
                    </div>
                  </a>
                </li>
                <li>
                  <a class="o-list__item--block is-link" [routerLink]="['/alerts/notification-groups']" is-link>
                    <div class="o-list__item-icon">
                      <mat-icon>contacts</mat-icon>
                    </div>
                    <div class="o-list__item-text is-primer-bold">
                      {{ notificationGroups.length }} notification
                      {{ notificationGroups.length | pluraliseWord: 'group' }}
                    </div>
                  </a>
                </li>
              </ng-template>
            </ul>
          </div>
          <div class="o-category-card__action">
            <a
              [routerLink]="!isAdmin && sites.length === 0 ? [] : ['/alerts']"
              class="o-button"
              [ngClass]="{ 'is-disabled': !isAdmin && sites.length === 0 }"
            >
              Manage alerts
              <mat-icon class="is-icon-right">arrow_forward</mat-icon>
            </a>
          </div>
        </div>
        <div class="o-category-card">
          <div class="o-category-card__head">
            <mat-icon>assessment</mat-icon>
            <h3 class="is-text">Reports</h3>
          </div>
          <div class="o-category-card__body">
            <ul class="o-list">
              <li>
                <a class="o-list__item--block is-link" [routerLink]="['/reporting', 'people-count']">
                  <div class="o-list__item-icon">
                    <mat-icon>transfer_within_a_station</mat-icon>
                  </div>
                  <div class="o-list__item-text is-primer-bold">People count reports</div>
                </a>
              </li>
              <li *ngIf="dataExplorerLicence">
                <a class="o-list__item--block is-link" [routerLink]="['/reporting', 'data-explorer']">
                  <div class="o-list__item-icon">
                    <mat-icon>insights</mat-icon>
                  </div>
                  <div class="o-list__item-text is-primer-bold">Data explorer</div>
                </a>
              </li>
              <li>
                <a
                  class="o-list__item--block is-link"
                  [routerLink]="['/reporting', 'report-csv-download']"
                  [queryParams]="{
                    type: 'people_count',
                  }"
                >
                  <div class="o-list__item-icon">
                    <mat-icon>download</mat-icon>
                  </div>
                  <div class="o-list__item-text is-primer-bold">Data downloads</div>
                </a>
              </li>
            </ul>
          </div>
          <div class="o-category-card__action">
            <a
              [routerLink]="!isAdmin && sites.length === 0 ? [] : ['/reporting']"
              class="o-button"
              [ngClass]="{ 'is-disabled': !isAdmin && sites.length === 0 }"
            >
              View reports
              <mat-icon class="is-icon-right">arrow_forward</mat-icon>
            </a>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
