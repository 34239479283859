import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Organisation, Site, SiteService, User, UserService } from 'src/app/api';
import { NotifyService } from 'src/app/services/notify.service';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss'],
})
export class AddEditUserComponent implements OnInit, OnDestroy {
  saving = false;
  deleting = false;

  sites = [];
  userRole: 'member' | 'admin' = 'member';
  userForm: FormGroup;
  organisation: Organisation;
  organisations: Organisation[];
  user: User;

  updateUser = false;
  isLoading$ = new BehaviorSubject<boolean>(false);
  isLoadingSite$ = new BehaviorSubject<boolean>(false);
  selection = new SelectionModel<string>(true, []);

  memberUsersSeatsAvailable: number;
  adminUsersSeatsAvailable: number;
  adminUsers: number;
  memberUsers: number;

  organisationsMap: { [_: string]: Organisation } = {};
  users: User[] = [];

  private ngUnsubscribe = new Subject();
  isSupport = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountService: AccountService,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    private userService: UserService,
    private siteService: SiteService,
    private notifyService: NotifyService,
  ) {
    const organisationId = this.accountService.isSupport ? '' : this.accountService.organisation.id;
    this.userForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      organisationId: [organisationId, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.adminUsers = this.data.adminUsers;
    this.adminUsersSeatsAvailable = this.data.adminUsersSeatsAvailable;
    this.memberUsers = this.data.memberUsers;
    this.memberUsersSeatsAvailable = this.data.memberUsersSeatsAvailable;

    this.users = this.data.users;

    this.isSupport = this.accountService.isSupport;
    this.organisation = this.accountService.organisation;
    this.organisations = this.accountService.organisations;
    this.organisationsMap = this.accountService.organisationsMap;
    this.getData();
  }

  getData(): void {
    if (this.data.hasOwnProperty('user')) {
      this.isLoading$.next(true);
      this.user = this.data.user;
      forkJoin([
        this.userService.getUser(this.user.id),
        this.siteService.listSites(this.user.organisationId, undefined, 'active'),
      ])
        .pipe(
          finalize(() => {
            this.isLoading$.next(false);
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: ([user, sites]) => {
            this.updateUser = true;
            this.organisations = [this.accountService.organisationsMap[user.organisationId]];
            this.sites = sites;
            this.userForm.get('firstName').setValue(user.firstName && user.lastName ? user.firstName : user.name);
            this.userForm.get('lastName').setValue(user.lastName);
            this.userForm.get('email').setValue(user.email);
            this.userForm.get('email').disable();
            this.userForm.get('organisationId').setValue(this.user.organisationId);
            this.userForm.get('organisationId').disable();

            if (user.roles.includes('admin')) {
              this.userRole = 'admin';
            } else {
              this.user.siteIds?.forEach((site) => {
                this.selection.toggle(site);
              });
            }
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
    } else {
      if (!this.isSupport) {
        this.updateOrganisation(this.organisation.id);
        this.userForm.get('organisationId').setValue(this.organisation.id);
        this.userForm.get('organisationId').disable();
      }
    }
  }

  someSelected(): boolean {
    return this.selection.selected.length !== 0 && this.selection.selected.length !== this.sites.length;
  }

  selectAll(completed: boolean) {
    completed ? this.sites.forEach((row) => this.selection.select(row.id)) : this.selection.clear();
  }

  isAllSelected() {
    return this.sites.length !== 0 && this.selection.selected.length === this.sites.length;
  }

  save(): void {
    if (this.userForm.invalid || this.saving) {
      this.userForm.markAllAsTouched();
      return;
    }
    this.saving = true;
    if (!this.updateUser) {
      const createRequest = this.userService
        .putUser({ ...this.userForm.getRawValue(), roles: [this.userRole], siteIds: this.getSelectedSites() })
        .pipe(takeUntil(this.ngUnsubscribe));
      if (this.selection.selected.length === 0 && this.userRole !== 'admin') {
        const message = `You have not given this member access to any sites.

        A member can be given access to sites later through their
        account profile page, or when creating a new site.`;

        const dialogRef = this.matDialog.open(UserConfirmationComponent, {
          data: { message, buttonText: 'confirm' },
        });

        dialogRef.afterClosed().subscribe({
          next: (result) => {
            if (result === 'confirm') {
              createRequest.subscribe({
                next: () => {
                  this.saving = false;
                  this.close(true);
                },
                error: (error) => {
                  this.notifyService.error(error);
                },
              });
            } else {
              this.saving = false;
            }
          },
        });
      } else {
        createRequest.subscribe({
          next: () => {
            this.saving = false;
            this.close(true);
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
      }
    } else {
      this.userService
        .putUser({
          ...this.userForm.getRawValue(),
          roles: [this.userRole],
          id: this.user.id,
          siteIds: this.getSelectedSites(),
        })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (_) => {
            this.saving = false;
            this.close(true);
          },
          error: (error) => {
            this.notifyService.error(error);
          },
        });
    }
  }

  getSelectedSites(): string[] {
    let selectedSites: string[] = [];
    this.sites.forEach((site: Site) => {
      if (this.selection.isSelected(site.id)) {
        selectedSites.push(site.id);
      }
    });
    return selectedSites;
  }

  deleteUser(): void {
    const message = `Are you sure you want to delete user "${this.user.name}"?

    This will remove their access to all sites.`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'delete', isDelete: true },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.deleting = true;
          this.userService
            .deleteUser(this.user.id)
            .pipe(
              finalize(() => {
                this.deleting = false;
              }),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
              next: (_) => {
                this.close(true);
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  updateOrganisation(organisationId: string): void {
    this.adminUsers = this.users.filter((u) => organisationId === u.organisationId && u.roles.includes('admin')).length;
    this.adminUsersSeatsAvailable = this.organisationsMap[organisationId]['maxAdminSeats'];
    this.memberUsers = this.users.filter(
      (u) => organisationId === u.organisationId && u.roles.includes('member'),
    ).length;
    this.memberUsersSeatsAvailable = this.organisationsMap[organisationId]['maxUserSeats'];

    this.isLoadingSite$.next(true);
    this.siteService
      .listSites(organisationId)
      .pipe(
        finalize(() => {
          this.isLoadingSite$.next(false);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (sites) => {
          this.sites = sites;
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
