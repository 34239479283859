<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <div class="c-container">
    <div class="o-card-list">
      <div class="o-card-list__title">
        <div class="o-heading--long-primer u-flush--bottom">
          <h2>No camera summaries</h2>
        </div>
      </div>
      <app-site-filter [sites]="sites" (filtered)="applyFilter($event)"></app-site-filter>
      <div class="o-card-list__lists">
        <div class="o-banner-alert is-error">
          <mat-icon class="is-icon is-error">warning</mat-icon>
          <span class="is-message"> These cameras do not have any summaries configured. </span>
        </div>

        <button
          class="o-button u-margin--bottom is-contained"
          [class.is-muted]="!selection.selected.length || !summaryAlerts.length"
          [disabled]="!selection.selected.length || !summaryAlerts.length"
          (click)="addToSummary()"
        >
          <mat-icon class="is-icon-left">add_to_photos</mat-icon>
          ADD TO SUMMARY
        </button>

        <ul class="o-notification-list has-divider">
          <ng-container *ngFor="let camera of filteredCameras">
            <li class="o-notification-list__item c-notification-list__item">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="selection.toggle(camera.id)"
                [checked]="selection.isSelected(camera.id)"
              >
              </mat-checkbox>

              <mat-icon *ngIf="camera.isOnline" class="is-icon u-primary-color">camera_alt</mat-icon>
              <mat-icon *ngIf="!camera.isOnline" class="is-icon u-red-color">no_photography</mat-icon>

              <div class="is-link">
                <div class="o-notification-list__primary-secondary">
                  <div>{{ camera.name }}</div>
                  <div>{{ sitesMap[camera.siteId].name }}</div>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
