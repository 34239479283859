import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, finalize, Subject, takeUntil } from 'rxjs';
import {
  DwellTimeReportService,
  DwellTimeRequestResponse,
  PatchDwellTimeReportRequest,
  PutDwellTimeReportRequest,
} from 'src/app/api';
import { NotifyService } from 'src/app/services/notify.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-dwell-time-report-review',
  templateUrl: './dwell-time-report-review.component.html',
  styleUrl: './dwell-time-report-review.component.scss',
})
export class DwellTimeReportReviewComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  reportId;
  isLoading$ = new BehaviorSubject<boolean>(false);
  loadingMsg;
  tableDataSource = new MatTableDataSource<any>();
  tableHeaderNames = ['delete', 'day', 'description', 'monitorType', 'dwellTime'];
  reportCompleted: boolean = false;

  constructor(
    public accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef,
    private notifyService: NotifyService,
    private dwellTimeReportService: DwellTimeReportService,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        this.reportId = params?.id;
        this.getData();
      },
    });
  }

  getData() {
    this.loadingMsg = 'Loading report...';
    this.isLoading$.next(true);
    const dataSource: { [_: string]: string }[] = [];

    this.dwellTimeReportService
      .getDwellTimeReport(this.reportId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoading$.next(false);
          this.tableDataSource.data = dataSource;
        }),
      )
      .subscribe({
        next: (report) => {
          this.reportCompleted = report.status === DwellTimeRequestResponse.StatusEnum.Completed;
          if (report.reportData) {
            report.reportData.forEach((row) => {
              dataSource.push({
                day: row.day,
                description: row.description,
                dwellTime: row.dwellTime,
                monitorType: row.monitorType,
              });
            });
          }
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  deleteRow(row: any): void {
    const index = this.tableDataSource.data.indexOf(row);
    if (index >= 0) {
      this.tableDataSource.data.splice(index, 1);
      this.tableDataSource._updateChangeSubscription();
    }
  }

  approve() {
    this.dwellTimeReportService
      .updateDwellTimeReportStatus(this.reportId, {
        status: PutDwellTimeReportRequest.StatusEnum.Approved,
        approvedBy: this.accountService.user.name,
      })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.close();
        }),
      )
      .subscribe({
        error: (err) => this.notifyService.error(err),
      });
  }

  reject() {
    this.dwellTimeReportService
      .updateDwellTimeReportStatus(this.reportId, {
        status: PutDwellTimeReportRequest.StatusEnum.Rejected,
        errorMessage: 'Reject',
        approvedBy: this.accountService.user.name,
      })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.close();
        }),
      )
      .subscribe({
        error: (err) => this.notifyService.error(err),
      });
  }

  changeAndApprove() {
    const dataSource: { [_: string]: string }[] = [];
    this.ref.detectChanges();

    this.dwellTimeReportService
      .patchDwellTimeReport(this.reportId, {
        status: PatchDwellTimeReportRequest.StatusEnum.Pending,
        reportData: this.tableDataSource.data,
      })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.approve();
        }),
      )
      .subscribe({
        error: (err) => this.notifyService.error(err),
      });
  }

  close(): void {
    if (window.history.length > 1) {
      history.back();
    } else {
      this.router.navigate(['/reporting']);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
