<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading details...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <div class="o-modal-full-page">
    <div class="o-modal-full-page__header">
      <button (click)="close()" class="is-close">
        <mat-icon>clear</mat-icon>
      </button>
      <h2 class="is-title" *ngIf="!updateUser">Add new user</h2>
      <h2 class="is-title" *ngIf="updateUser">Edit user</h2>
      <button class="is-action" (click)="save()" [class.spinner]="saving">Save</button>
    </div>

    <div class="o-modal-full-page__body c-layout-view">
      <div class="c-layout-view__col">
        <div class="c-users__block">
          <div class="c-users__title">
            <mat-icon class="is-icon">recent_actors</mat-icon>
            <div>
              <div class="long-primer kirk u-flush--bottom">
                {{ adminUsers + memberUsers ? adminUsers + memberUsers : '' }}
                Current user accounts
              </div>
              <div class="brevier u-flush--bottom" *ngIf="adminUsersSeatsAvailable || memberUsersSeatsAvailable">
                Your account has a {{ memberUsersSeatsAvailable }} user limit.
                <ng-container *ngIf="adminUsersSeatsAvailable"
                  >Including up to {{ adminUsersSeatsAvailable }} admin
                  {{ adminUsersSeatsAvailable | pluraliseWord: 'user' }}.</ng-container
                >
              </div>
            </div>
          </div>
          <div class="c-user-info">
            <mat-icon class="is-icon">manage_accounts</mat-icon>
            <div class="is-title">Admins</div>
            <div class="is-registered brevier type--secondary">Registered: {{ adminUsers }}</div>
            <div class="is-available">
              <ng-container *ngIf="adminUsersSeatsAvailable">
                <div
                  *ngIf="adminUsers < adminUsersSeatsAvailable && adminUsers + memberUsers < memberUsersSeatsAvailable"
                  class="c-user-seats-available-text"
                >
                  <ng-container
                    *ngIf="
                      [adminUsersSeatsAvailable - adminUsers, memberUsersSeatsAvailable - (adminUsers + memberUsers)]
                        | minMax as result
                    "
                  >
                    {{ result[0] }} admin {{ result[0] | pluraliseWord: 'seat' }} available
                  </ng-container>
                </div>
                <div
                  *ngIf="
                    adminUsers >= adminUsersSeatsAvailable || adminUsers + memberUsers >= memberUsersSeatsAvailable
                  "
                  class="c-user-seats-available-text"
                >
                  All admin seats allocated
                </div>
              </ng-container>
            </div>
            <div *ngIf="adminUsers >= adminUsersSeatsAvailable" class="is-banner">
              <div>
                You have reached your limit of {{ adminUsersSeatsAvailable }} admin
                {{ adminUsersSeatsAvailable | pluraliseWord: 'user' }}.
              </div>
              <div class="c-contact-text">Contact Hoxton support to increase your allowance.</div>
              <div>Or delete an existing admin user to stay within your current limit.</div>
            </div>
          </div>
          <div class="c-user-info">
            <mat-icon class="is-icon">person</mat-icon>
            <div class="is-title">Members</div>
            <div class="is-registered brevier type--secondary">Registered: {{ memberUsers }}</div>
            <div class="is-available">
              <ng-container *ngIf="memberUsersSeatsAvailable">
                <div *ngIf="memberUsers + adminUsers < memberUsersSeatsAvailable">
                  {{ memberUsersSeatsAvailable - (adminUsers + memberUsers) }} member seats available
                </div>
                <div *ngIf="memberUsers + adminUsers >= memberUsersSeatsAvailable">All member seats allocated</div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="c-layout-view__col">
        <div class="c-users__block">
          <div
            class="c-member-limit"
            *ngIf="
              (adminUsersSeatsAvailable || memberUsersSeatsAvailable) &&
                memberUsers + adminUsers >= memberUsersSeatsAvailable &&
                !updateUser;
              else canAddUser
            "
          >
            <img class="c-member-placeholder" src="/assets/images/members-placeholder.png" alt="" />
            <div class="long-primer kirk">
              You have reached your limit of {{ memberUsersSeatsAvailable }}
              {{ memberUsersSeatsAvailable | pluraliseWord: 'user' }}.
            </div>
            <div class="primer">
              Please contact Hoxton support to increase your allowance. <br />
              We will contact you to confirm the details of your new user limits.
            </div>
            <a href="mailto:support@hoxton.ai" class="o-button is-primary">
              <mat-icon class="is-icon-left">email</mat-icon>
              EMAIL SUPPORT
            </a>

            <div class="c-custom-horizontal-rule"></div>
            <div class="c-delete-msg">Or delete an existing user to stay within your current allowance.</div>
          </div>
          <ng-template #canAddUser>
            <div>
              <div class="c-users__title long-primer kirk">
                <mat-icon class="c-new-user-icon">person_add</mat-icon>
                Add new user
              </div>
              <form class="c-users__add-form" [formGroup]="userForm">
                <mat-form-field class="user-form-field" appearance="outline">
                  <mat-label>First name</mat-label>
                  <input matInput type="text" placeholder="First Name" autocomplete="off" formControlName="firstName" />
                  <mat-error *ngIf="userForm.controls.firstName.hasError('required')"
                    >First name is <strong>required</strong></mat-error
                  >
                </mat-form-field>
                <mat-form-field class="user-form-field" appearance="outline">
                  <mat-label>Last name</mat-label>
                  <input matInput type="text" placeholder="Last Name" autocomplete="off" formControlName="lastName" />
                  <mat-error *ngIf="userForm.controls.lastName.hasError('required')"
                    >Last name is <strong>required</strong></mat-error
                  >
                </mat-form-field>
                <mat-form-field class="user-form-field is-2-col" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput type="text" placeholder="Email address" autocomplete="off" formControlName="email" />
                  <mat-error *ngIf="userForm.controls.email.hasError('required')"
                    >Email is <strong>required</strong></mat-error
                  >
                </mat-form-field>
                <mat-form-field class="user-form-field is-2-col" appearance="outline" *ngIf="isSupport">
                  <mat-label>Organisation</mat-label>
                  <mat-select
                    ngDefaultControl
                    formControlName="organisationId"
                    (selectionChange)="updateOrganisation($event.value)"
                  >
                    <mat-option *ngFor="let org of organisations" [value]="org.id">
                      {{ org.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="userForm.controls.organisationId.hasError('required')"
                    >Organisation is <strong>required</strong></mat-error
                  >
                </mat-form-field>
              </form>
              <div class="u-margin--bottom">
                <div class="o-heading--primer">
                  <h2>User role</h2>
                  <p>What permissions should this user have</p>
                </div>
                <mat-radio-group class="role-radio-group o-toggle__row" [(ngModel)]="userRole" name="userRole">
                  <mat-radio-button
                    value="admin"
                    class="o-toggle__item"
                    [disabled]="adminUsers >= adminUsersSeatsAvailable"
                    >Admin</mat-radio-button
                  >
                  <mat-radio-button value="member" class="o-toggle__item">Member</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="u-margin--bottom" *ngIf="userRole === 'member'">
                <div class="o-heading--primer">
                  <h2>Sites</h2>
                  <p>What sites can this member access</p>
                </div>
                <div class="auth-overlay" *ngIf="isLoadingSite$ | async; else notLoadingSite">
                  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
                    <p style="font-size: 1rem; font-weight: 500">Loading sites...</p>
                    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
                  </div>
                </div>
                <ng-template #notLoadingSite>
                  <ng-container *ngIf="userForm.get('organisationId').value === ''; else organisationSiteList">
                    <div class="c-list-scroll">
                      <p>Select an organisation for available sites</p>
                    </div>
                  </ng-container>
                  <ng-template #organisationSiteList>
                    <div *ngIf="sites.length === 0; else siteList">
                      <div class="o-banner-message background-grey">
                        <div class="o-banner-message__text">
                          <h3 class="is-title is-primer">No sites have been created yet.</h3>
                          <p class="is-text">You will be able to give this member access when creating a new site.</p>
                          <p class="is-text">Members can be given access to multiple sites.</p>
                        </div>
                        <div class="o-banner-message__media is-small">
                          <img src="/assets/images/site-museum.png" class="is-grayscale" alt="Building" />
                        </div>
                      </div>
                    </div>
                    <ng-template #siteList>
                      <div class="c-list-scroll">
                        <ul class="o-long-checklist">
                          <li class="o-long-checklist__item">
                            <mat-checkbox
                              class="is-checkbox is-select-all"
                              [checked]="isAllSelected()"
                              [indeterminate]="someSelected()"
                              (change)="selectAll($event.checked)"
                            >
                              Select all sites
                            </mat-checkbox>
                          </li>
                          <li *ngFor="let site of sites" class="o-long-checklist__item">
                            <mat-checkbox
                              class="is-checkbox"
                              (change)="selection.toggle(site.id)"
                              [checked]="selection.isSelected(site.id)"
                            >
                              {{ site.name }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                    </ng-template>
                  </ng-template>
                </ng-template>
              </div>
              <div *ngIf="updateUser">
                <button
                  mat-button
                  class="o-button is-contained is-delete c-delete-user"
                  [class.spinner]="deleting"
                  (click)="deleteUser()"
                >
                  <mat-icon class="is-icon-left">delete</mat-icon>
                  Delete user
                </button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>
