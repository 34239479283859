import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { User } from '@auth0/auth0-angular';
import { BehaviorSubject, finalize, forkJoin, Subject, takeUntil } from 'rxjs';
import { Camera, CameraService, Organisation, Site, SiteService, UserService } from 'src/app/api';
import { SiteStatus } from 'src/app/model/siteStatus';
import { AccountService } from 'src/app/services/account.service';
import { CamerasService } from 'src/app/services/cameras.service';
import { NotifyService } from 'src/app/services/notify.service';
import { SitesService } from 'src/app/services/sites.service';
import { GlobalMethods } from 'src/app/global-methods';

const now = new Date();

@Component({
  selector: 'app-customer-engagement',
  templateUrl: './customer-engagement.component.html',
  styleUrls: ['./customer-engagement.component.scss'],
})
export class CustomerEngagementComponent implements OnInit, OnDestroy {
  hideSelectedListItems = false;
  customerEngagements = [];
  filteredCustomerEngagements;
  hiddenListItemsQuantity: number;
  mustContainString = '';
  orderByParam = 'organisation';

  organisationDate = new FormGroup({
    start: new FormControl(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)),
    end: new FormControl(new Date()),
  });

  organisations: Organisation[];
  sites: Site[];
  users: User[];
  cameras: Camera[];
  siteCameras = [];

  private ngUnsubscribe = new Subject();
  isLoading$ = new BehaviorSubject<boolean>(true);

  constructor(
    public accountService: AccountService,
    private siteService: SiteService,
    private ref: ChangeDetectorRef,
    private notifyService: NotifyService,
    private cameraService: CameraService,
    private userService: UserService,
    private camerasService: CamerasService,
    private sitesService: SitesService,
  ) {}

  ngOnInit(): void {
    this.organisations = this.accountService.organisations;
    const cameraState: Camera.StateEnum[] = ['running', 'paused', 'decommissioned'];

    forkJoin([
      this.siteService.listSites(undefined, undefined, 'active'),
      this.cameraService.listCameras(false, undefined, undefined, cameraState),
      this.userService.listUsers(),
    ])
      .pipe(finalize(this.onFinalize), takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: ([sites, cameras, users]) => {
          this.sites = sites;
          this.cameras = cameras;
          this.users = users;

          const organisationIdToSitesQuantity = {},
            organisationIdToUsersQuantity = {},
            organisationToIssuesSites = {};

          this.organisations.forEach((organisation) => {
            organisationIdToSitesQuantity[organisation.id] = 0;
            organisationIdToUsersQuantity[organisation.id] = 0;
            organisationToIssuesSites[organisation.id] = 'Good';
          });

          this.sites.forEach((site) => organisationIdToSitesQuantity[site.organisationId]++);
          this.users.forEach((user) => organisationIdToUsersQuantity[user.organisationId]++);

          cameras = cameras.map((c) => this.camerasService.getCameraStatus(c));
          sites.forEach((site: SiteStatus) => {
            this.siteCameras[site.id] = cameras.filter((c) => c.siteId === site.id);
          });
          this.sites = sites.map((s) => this.sitesService.getSiteStatus(s, this.siteCameras[s.id]));

          this.organisations.forEach((organisation) => {
            let camerasWithIssues = this.sites.filter(
              (site: any) => site.organisationId === organisation.id && site.status === 'issue',
            );

            let sitesOfcamerasWithIssuesLength = GlobalMethods.unique(
              camerasWithIssues.map((camera) => camera.organisationId),
            ).length;

            const poorCameraHealthText =
              '<b>Poor</b>&nbsp;<span>' +
              (sitesOfcamerasWithIssuesLength === 1 ? `(1 site)` : `(${sitesOfcamerasWithIssuesLength} sites)`) +
              '</span>';

            const cameraHealth = sitesOfcamerasWithIssuesLength ? poorCameraHealthText : 'Good';

            const tableElement = {
              hidden: false,
              checked: false,
              data: {
                organisation: organisation.name,
                sites: organisationIdToSitesQuantity[organisation.id],
                type: '-',
                users: organisationIdToUsersQuantity[organisation.id],
                lastLogin: '-',
                totalLogins: '-',
                apiHealth: '-',
                shopify: '-',
                cameraHealth: cameraHealth,
              },
            };

            this.customerEngagements.push(tableElement);
          });

          this.filteredCustomerEngagements = this.customerEngagements;
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  applyFilter(): void {
    this.filteredCustomerEngagements = this.customerEngagements.filter((customerEngagement) =>
      customerEngagement.data.organisation.toLowerCase().includes(this.mustContainString),
    );
  }

  runSearch(ss: string): void {
    this.mustContainString = ss.toLowerCase();
    this.applyFilter();
  }

  onHideSelectedButtonClick(): void {
    const isAnyCustomerEngagementTableItemChecked = Boolean(
      this.customerEngagements.filter((customerEngagement) => customerEngagement.checked).length,
    );

    if (!isAnyCustomerEngagementTableItemChecked) {
      return;
    }

    this.hideSelectedListItems = true;
    this.customerEngagements
      .filter((customerEngagement) => customerEngagement.checked)
      .map((customerEngagement) => (customerEngagement.hidden = true));

    this.hiddenListItemsQuantity = this.customerEngagements.filter(
      (customerEngagement) => customerEngagement.hidden,
    ).length;
  }

  revealHiddenOrganisation(): void {
    this.hideSelectedListItems = false;

    this.customerEngagements.map((customerEngagement) => {
      customerEngagement.hidden = false;
      customerEngagement.checked = false;
    });
  }

  onCustomerEngagementCheckboxClick(customerEngagement): void {
    const aciveCustomerEngagementRes = this.customerEngagements.find(
      (customerEngagementRes) => customerEngagementRes === customerEngagement,
    );

    aciveCustomerEngagementRes.checked = !aciveCustomerEngagementRes.checked;
  }

  onTableHeaderItemClick(param: string): void {
    this.orderByParam = param;
  }

  onFinalize = () => {
    this.isLoading$.next(false);
  };

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
