import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { BehaviorSubject, finalize, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { DwellTimeReportService, Organisation } from 'src/app/api';
import { AddPeopleCountMonitorComponent } from 'src/app/components/monitors/add-people-count-monitor/add-people-count-monitor.component';
import { AccountService } from 'src/app/services/account.service';
import { NotifyService } from 'src/app/services/notify.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-add-dwell-time-report',
  templateUrl: './add-dwell-time-report.component.html',
  styleUrl: './add-dwell-time-report.component.scss',
})
export class AddDwellTimeReportComponent implements OnInit, OnDestroy {
  readonly stepsTitle: string = 'Create dwell time report';

  isLoading$ = new BehaviorSubject<boolean>(false);
  sideBySide = true;
  currentDateTimeMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  range = new FormGroup({
    from: new FormControl(this.currentDateTimeMidnight),
    to: new FormControl(this.currentDateTimeMidnight),
  });

  reviewForm: FormGroup;
  selectedOrganisationId;
  occupancyMonitors;
  peopleCountMonitors;
  availableOccupancyMonitors = [];
  availablePeopleCountMonitors = [];
  pickedOccupancyMonitors = [];
  pickedPeopleCountMonitors = [];
  orgFilterControl = new FormControl();
  occupancySearchControl = new FormControl('');
  peopleCountSearchControl = new FormControl('');
  filteredOrganisations: Observable<Organisation[]>;
  filteredAvailableOccupancyMonitors: Observable<any[]>;
  filteredAvailablePeopleCountMonitors: Observable<any[]>;
  hasOccupancySearch;
  hasPeopleCountSearch;
  selectedOccupancyMonitors = new SelectionModel<string>(true, []);
  selectedPeopleCountMonitors = new SelectionModel<string>(true, []);
  private ngUnsubscribe = new Subject();
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    public dialogRef: MatDialogRef<AddPeopleCountMonitorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpointObserver: BreakpointObserver,
    public reportsService: ReportsService,
    public accountService: AccountService,
    private dwellTimeReportService: DwellTimeReportService,
    private ref: ChangeDetectorRef,
    private notifyService: NotifyService,
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (state: BreakpointState) => {
          this.sideBySide = !state.matches;
          this.ref.detectChanges();
        },
      });

    this.filteredOrganisations = this.orgFilterControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.accountService.organisations;
      }),
    );
  }

  private _filterMonitors(value: string, monitorList: any[]): any[] {
    const filterValue = value.toLowerCase();
    return monitorList.filter((monitor) => monitor.name.toLowerCase().includes(filterValue));
  }

  private _filter(value: string) {
    return this.accountService.organisations.filter((o) => o.name.toLowerCase().includes(value.toLowerCase()));
  }

  getNameFn(org?: Organisation): string {
    return org ? org.name : '';
  }

  getOccupancyMonitors() {
    if (this.accountService.isSupport) {
      this.occupancyMonitors = this.reportsService.occupancyMonitors.filter(
        (om) => om.organisationId === this.selectedOrganisationId,
      );
    } else {
      this.occupancyMonitors = this.reportsService.occupancyMonitors;
    }

    this.availableOccupancyMonitors = [...this.occupancyMonitors];
    this.pickedOccupancyMonitors = [];
  }

  getPeopleCountMonitors() {
    if (this.accountService.isSupport) {
      this.peopleCountMonitors = this.reportsService.peopleCountMonitors.filter(
        (om) => om.organisationId === this.selectedOrganisationId,
      );
    } else {
      this.peopleCountMonitors = this.reportsService.peopleCountMonitors;
    }

    this.availablePeopleCountMonitors = [...this.peopleCountMonitors];
    this.pickedPeopleCountMonitors = [];
  }

  moveAllOccupancyMonitorsToPicked() {
    this.pickedOccupancyMonitors.push(...this.availableOccupancyMonitors);
    this.availableOccupancyMonitors = [];
  }

  moveAllOccupancyMonitorsToAvailable() {
    this.availableOccupancyMonitors.push(...this.pickedOccupancyMonitors);
    this.pickedOccupancyMonitors = [];
  }

  moveSelectedOccupancyMonitorsToPicked(selectedOptions: MatListOption[]) {
    const selectedMonitors = selectedOptions.map((option) => option.value);
    this.pickedOccupancyMonitors.push(...selectedMonitors);
    this.availableOccupancyMonitors = this.availableOccupancyMonitors.filter(
      (monitor) => !selectedMonitors.includes(monitor),
    );
  }

  moveSelectedOccupancyMonitorsToAvailable(selectedOptions: MatListOption[]) {
    const selectedMonitors = selectedOptions.map((option) => option.value);
    this.availableOccupancyMonitors.push(...selectedMonitors);
    this.pickedOccupancyMonitors = this.pickedOccupancyMonitors.filter(
      (monitor) => !selectedMonitors.includes(monitor),
    );
  }

  moveAllPeopleCountMonitorsToPicked() {
    this.pickedPeopleCountMonitors.push(...this.availablePeopleCountMonitors);
    this.availablePeopleCountMonitors = [];
  }

  moveAllPeopleCountMonitorsToAvailable() {
    this.availablePeopleCountMonitors.push(...this.pickedPeopleCountMonitors);
    this.pickedPeopleCountMonitors = [];
  }

  moveSelectedPeopleCountMonitorsToPicked(selectedOptions: MatListOption[]) {
    const selectedMonitors = selectedOptions.map((option) => option.value);
    this.pickedPeopleCountMonitors.push(...selectedMonitors);
    this.availablePeopleCountMonitors = this.availablePeopleCountMonitors.filter(
      (monitor) => !selectedMonitors.includes(monitor),
    );
  }

  moveSelectedPeopleCountMonitorsToAvailable(selectedOptions: MatListOption[]) {
    const selectedMonitors = selectedOptions.map((option) => option.value);
    this.availablePeopleCountMonitors.push(...selectedMonitors);
    this.pickedPeopleCountMonitors = this.pickedPeopleCountMonitors.filter(
      (monitor) => !selectedMonitors.includes(monitor),
    );
  }

  goToSecondStep() {
    if (this.accountService.isSupport) {
      this.selectedOrganisationId = this.orgFilterControl.value.id;
    } else {
      this.selectedOrganisationId = this.accountService.organisation.id;
    }
    this.getOccupancyMonitors();
    this.getPeopleCountMonitors();

    this.hasOccupancySearch = this.availableOccupancyMonitors.length > 6;
    this.hasPeopleCountSearch = this.availablePeopleCountMonitors.length > 6;

    this.filteredAvailableOccupancyMonitors = this.occupancySearchControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this._filterMonitors(value, this.availableOccupancyMonitors);
      }),
    );

    this.filteredAvailablePeopleCountMonitors = this.peopleCountSearchControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterMonitors(value, this.availablePeopleCountMonitors)),
    );

    this.stepper.next();
  }

  goToReviewStep() {
    this.reviewForm = new FormGroup({
      from: new FormControl({ value: this.range.value.from, disabled: true }),
      to: new FormControl({ value: this.range.value.to, disabled: true }),
      org: new FormControl({
        value: this.accountService.organisationsMap[this.selectedOrganisationId].name,
        disabled: true,
      }),
    });

    this.selectedOccupancyMonitors.clear();
    this.selectedPeopleCountMonitors.clear();
    this.pickedOccupancyMonitors.forEach((monitor) => this.selectedOccupancyMonitors.select(monitor.id));
    this.pickedPeopleCountMonitors.forEach((monitor) => this.selectedPeopleCountMonitors.select(monitor.id));
    this.stepper.next();
  }

  createReport() {
    if (this.isLoading$.getValue()) {
      return;
    }

    this.isLoading$.next(true);
    this.dwellTimeReportService
      .createDwellTimeReport({
        startDate: moment(this.range.value.from).format('YYYY-MM-DD'),
        endDate: moment(this.range.value.to).format('YYYY-MM-DD'),
        organisationId: this.selectedOrganisationId,
        occupancyMonitorIds: this.selectedOccupancyMonitors.selected,
        peopleCountMonitorIds: this.selectedPeopleCountMonitors.selected,
      })
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.close(true);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
