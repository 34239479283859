<div class="o-modal-full-page has-no-header o-dialog-page">
  <div class="o-modal-full-page__body c-modal-full-page__body">
    <div class="loading" *ngIf="isLoading$ | async; else tableContent">
      <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
        <p style="font-size: 2rem; font-weight: 500">Loading report...</p>
        <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
      </div>
    </div>
    <ng-template #tableContent>
      <div class="c-action-buttons" *ngIf="!reportCompleted">
        <button class="o-button is-primary is-small" (click)="approve()">
          <mat-icon class="is-icon-left">check_circle</mat-icon>
          Approve
        </button>
        <button class="o-button is-primary is-small" (click)="reject()">
          <mat-icon class="is-icon-left">cancel</mat-icon>
          Reject
        </button>
        <button class="o-button is-primary is-small" (click)="changeAndApprove()">
          <mat-icon class="is-icon-left">published_with_changes</mat-icon>
          Change and Approve
        </button>
      </div>
      <div>
        <table mat-table [dataSource]="tableDataSource" class="c-table">
          <tr mat-header-row *matHeaderRowDef="tableHeaderNames" class="c-table__header"></tr>
          <tr mat-row *matRowDef="let row; columns: tableHeaderNames" class="c-table__row"></tr>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef class="delete-icon-header"></th>
            <td mat-cell *matCellDef="let row" class="delete-icon-cell">
              <button mat-icon-button *ngIf="!reportCompleted" class="delete-button" (click)="deleteRow(row)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="day">
            <th mat-header-cell *matHeaderCellDef class="c-table__title">Day</th>
            <td mat-cell *matCellDef="let row">{{ row.day }}</td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef class="c-table__title">Description</th>
            <td mat-cell *matCellDef="let row">{{ row.description }}</td>
          </ng-container>
          <ng-container matColumnDef="monitorType">
            <th mat-header-cell *matHeaderCellDef class="c-table__title">Type</th>
            <td mat-cell *matCellDef="let row">{{ row.monitorType }}</td>
          </ng-container>
          <ng-container matColumnDef="dwellTime">
            <th mat-header-cell *matHeaderCellDef class="c-table__title">Dwell Time</th>
            <td mat-cell *matCellDef="let row">
              <input
                *ngIf="!reportCompleted"
                matInput
                class="dwell-time-input"
                placeholder="Enter dwell time"
                [(ngModel)]="row.dwellTime"
              />
              <span *ngIf="reportCompleted">{{ row.dwellTime }}</span>
            </td>
          </ng-container>
        </table>
      </div>
    </ng-template>
  </div>
</div>
