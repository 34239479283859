import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  CameraService,
  OccupancyMonitorService,
  Organisation,
  PeelOffMonitorService,
  PeopleCountMonitorService,
  SiteService,
} from 'src/app/api';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { AddEditSiteComponent } from 'src/app/components/sites/add-edit-site/add-edit-site.component';
import { compare } from 'src/app/functions/sort';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { SiteStatus } from 'src/app/model/siteStatus';
import { AccountService } from 'src/app/services/account.service';
import { CamerasService } from 'src/app/services/cameras.service';
import { NotifyService } from 'src/app/services/notify.service';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
})
export class SitesComponent implements OnInit, OnDestroy {
  sites: SiteStatus[] = [];
  filteredSites: SiteStatus[] = [];
  siteCameras: { [_: string]: CameraStatus[] } = {};
  occupancyMonitorsMapBySiteId = {};
  peopleCountMonitorsMapBySiteId = {};
  peelOffMonitorsMapBySiteId = {};
  noSites: boolean = true;
  isLoading$ = new BehaviorSubject<boolean>(false);
  isSupport = false;
  isAdmin = false;
  mustContainString: string = '';

  organisation: Organisation = null;
  filterByOrganisationId: string = null;
  organisations: Organisation[] = [];
  selectedOrganisations: Organisation[] = [];

  private ngUnsubscribe = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private siteService: SiteService,
    private sitesService: SitesService,
    private cameraService: CameraService,
    private camerasService: CamerasService,
    private occupancyMonitorService: OccupancyMonitorService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peelOffMonitorService: PeelOffMonitorService,
    private router: Router,
    private accountService: AccountService,
    private notifyService: NotifyService,
  ) {}

  ngOnInit(): void {
    this.isSupport = this.accountService.isSupport;
    this.isAdmin = this.accountService.isAdmin;
    this.organisations = this.accountService.organisations;
    this.organisation = this.accountService.organisation;

    this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        if (params.orgId) {
          this.filterByOrganisationId = params.orgId;
        }
      },
    });

    this.getData();
  }

  getData(): void {
    this.isLoading$.next(true);
    forkJoin([
      this.siteService.listSites(undefined, undefined, 'active'),
      this.cameraService.listCameras(true, undefined, undefined, ['running', 'paused']),
      this.occupancyMonitorService.listOccupancyMonitors(undefined, undefined, undefined, 'active'),
      this.peopleCountMonitorService.listPeopleCountMonitors(undefined, undefined, undefined, 'active'),
      this.peelOffMonitorService.listPeelOffMonitors(undefined, undefined, undefined, 'active'),
    ])
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
          this.applyFilter(
            this.organisations.filter((o) => this.filterByOrganisationId && o.id === this.filterByOrganisationId),
          );
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([sites, cameras, occupancyMonitors, peopleCountMonitors, peelOffMonitors]) => {
          cameras = cameras.map((c) => this.camerasService.getCameraStatus(c));

          sites.forEach((site: SiteStatus) => {
            this.siteCameras[site.id] = cameras.filter((c) => c.siteId === site.id);
          });

          this.sites = sites
            .map((s) => this.sitesService.getSiteStatus(s, this.siteCameras[s.id]))
            .sort((s1, s2) => {
              if (s1.status === s2.status) {
                return compare(s1, s2, { keyToSort: 'name', direction: 'ascending' });
              } else {
                return (
                  Object.values(SiteStatus.StatusEnum).indexOf(s2.status) -
                  Object.values(SiteStatus.StatusEnum).indexOf(s1.status)
                );
              }
            });

          this.sites.forEach((site) => {
            this.occupancyMonitorsMapBySiteId[site.id] = [];
            this.peopleCountMonitorsMapBySiteId[site.id] = [];
            this.peelOffMonitorsMapBySiteId[site.id] = [];
          });

          occupancyMonitors?.forEach((occupancyMonitor) => {
            if (occupancyMonitor.siteId in this.occupancyMonitorsMapBySiteId) {
              this.occupancyMonitorsMapBySiteId[occupancyMonitor.siteId].push(occupancyMonitor);
            }
          });

          peopleCountMonitors?.forEach((peopleCountMonitor) => {
            if (peopleCountMonitor.siteId in this.peopleCountMonitorsMapBySiteId) {
              this.peopleCountMonitorsMapBySiteId[peopleCountMonitor.siteId].push(peopleCountMonitor);
            }
          });

          peelOffMonitors?.forEach((peelOffMonitor) => {
            if (peelOffMonitor.siteId in this.peopleCountMonitorsMapBySiteId) {
              this.peelOffMonitorsMapBySiteId[peelOffMonitor.siteId].push(peelOffMonitor);
            }
          });

          this.noSites = this.sites.length === 0;
        },
        error: (_) => {
          this.router.navigate(['internal-error']);
        },
      });
  }

  openAddEditSiteDialog(site?: SiteStatus): void {
    var data: any = { orgId: this.filterByOrganisationId };
    if (site) {
      data = { site };
    }
    const dialogRef = this.matDialog.open(AddEditSiteComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          this.getData();
        }
      },
    });
  }

  deleteSite(site: SiteStatus): void {
    const message = `You are about to delete ${site.name}.

    Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'Delete' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.siteService
            .deleteSite(site.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (_) => {
                window.location.reload();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  applyFilter(organisations?: Organisation[]): void {
    this.selectedOrganisations = organisations && organisations.length !== 0 ? organisations : this.organisations;
    this.filterSites();
  }

  filterSites(): void {
    let selectedOrganisationIds = this.selectedOrganisations.map((o) => o.id);
    this.filteredSites =
      selectedOrganisationIds.length === 0
        ? this.sites
        : this.sites.filter((s) => selectedOrganisationIds.includes(s.organisationId));
    if (this.mustContainString) {
      this.filteredSites = this.filteredSites.filter(
        (site) =>
          site.name.toLowerCase().includes(this.mustContainString) ||
          site.id.toLowerCase().includes(this.mustContainString),
      );
    }
  }

  runSearch(ss: string): void {
    this.mustContainString = ss.toLowerCase();
    this.filterSites();
  }

  clearMustContainString(): void {
    this.mustContainString = '';
    this.filterSites();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
