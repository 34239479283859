import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { finalize, forkJoin, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import {
  AlertCameraPositionMapping,
  AlertCameraPositionMappingService,
  AlertService,
  CameraService,
  OccupancyMonitorCameraPosition,
  OccupancyMonitorCameraPositionService,
  OccupancyMonitorService,
  PeelOffMonitorCameraPosition,
  PeelOffMonitorCameraPositionService,
  PeelOffMonitorService,
  PeopleCountMonitorCameraPosition,
  PeopleCountMonitorCameraPositionService,
  PeopleCountMonitorService,
} from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';
import { NotifyService } from 'src/app/services/notify.service';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { DecommissionConfirmationComponent } from 'src/app/components/cameras/decommission-confirmation/decommission-confirmation.component';

@Component({
  selector: 'app-edit-camera',
  templateUrl: './edit-camera.component.html',
  styleUrls: ['./edit-camera.component.scss'],
})
export class EditCameraComponent implements OnInit, OnDestroy {
  cameraForm: FormGroup;
  cameraId = '';
  cameraState = '';
  cameraFrame = '';
  cameraType = '';

  gettingDecommissionInfo = false;
  saving = false;

  // for decommission
  occupancyMonitorCameraPositions: OccupancyMonitorCameraPosition[] = [];
  peopleCountMonitorCameraPositions: PeopleCountMonitorCameraPosition[] = [];
  peelOffMonitorCameraPositions: PeelOffMonitorCameraPosition[] = [];
  alertCameraPositionMappings: AlertCameraPositionMapping[] = [];

  private ngUnsubscribe = new Subject();

  constructor(
    public dialogRef: MatDialogRef<EditCameraComponent>,
    public accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private notifyService: NotifyService,
    private router: Router,
    private matDialog: MatDialog,
    private cameraService: CameraService,
    private alertService: AlertService,
    private occupancyMonitorService: OccupancyMonitorService,
    private occupancyMonitorCameraPositionService: OccupancyMonitorCameraPositionService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peopleCountMonitorCameraPositionService: PeopleCountMonitorCameraPositionService,
    private peelOffMonitorService: PeelOffMonitorService,
    private peelOffMonitorCameraPositionService: PeelOffMonitorCameraPositionService,
    private alertCameraPositionMappingService: AlertCameraPositionMappingService,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.cameraId = this.data.camera.id;
      this.cameraState = this.data.camera.state;
      this.cameraForm = this.formBuilder.group({
        direction1Alias: [this.data.camera.direction1Alias, [Validators.required]],
        direction2Alias: [this.data.camera.direction2Alias, [Validators.required]],
        name: [this.data.camera.name, [Validators.required]],
      });
      this.cameraFrame = this.data.cameraFrame;
      this.cameraType = this.data.camera.type;
    } else {
      this.router.navigate(['internal-error']);
    }
  }

  save(): void {
    this.saving = true;
    this.cameraService
      .patchCamera(this.cameraId, this.cameraForm.value)
      .pipe(
        finalize(() => {
          this.saving = false;
        }),
      )
      .subscribe({
        next: (_) => {
          this.close(true);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  putCameraState(state: 'running' | 'paused', stateName: string): void {
    const message = `You are about to ${stateName} this camera.

    All people counting and occupaccy monitoring will be ${stateName}d.`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: stateName },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.saving = true;
          this.cameraService
            .putCameraState(this.cameraId, state)
            .pipe(
              finalize(() => {
                this.saving = false;
              }),
            )
            .subscribe({
              next: (_) => {
                this.close(true);
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  deleteCamera(): void {
    this.gettingDecommissionInfo = true;

    forkJoin([
      this.occupancyMonitorCameraPositionService.listOccupancyMonitorCameraPositions(
        undefined,
        undefined,
        this.cameraId,
        undefined,
        'active',
      ),
      this.peopleCountMonitorCameraPositionService.listPeopleCountMonitorCameraPositions(
        undefined,
        undefined,
        this.cameraId,
        undefined,
        'active',
      ),
      this.peelOffMonitorCameraPositionService.listPeelOffMonitorCameraPositions(
        undefined,
        undefined,
        this.cameraId,
        undefined,
        'active',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        this.cameraId,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'offline_alert',
      ),
      this.alertCameraPositionMappingService.listAlertCameraPositionMappings(
        this.cameraId,
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'camera_health_summary_alert',
      ),
    ])
      .pipe(
        switchMap(
          ([
            occupancyMonitorCameraPositions,
            peopleCountMonitorCameraPositions,
            peelOffMonitorCameraPositions,
            offlineAlertCameraPositions,
            summaryAlertCameraPositions,
          ]) => {
            this.occupancyMonitorCameraPositions = occupancyMonitorCameraPositions;
            this.peopleCountMonitorCameraPositions = peopleCountMonitorCameraPositions;
            this.peelOffMonitorCameraPositions = peelOffMonitorCameraPositions;
            this.alertCameraPositionMappings = [...offlineAlertCameraPositions, ...summaryAlertCameraPositions];
            const requests: Observable<any>[] = [];

            this.occupancyMonitorCameraPositions.length
              ? requests.push(
                  this.occupancyMonitorService.listOccupancyMonitors(undefined, undefined, this.cameraId, 'active'),
                )
              : requests.push(of([]));
            this.peopleCountMonitorCameraPositions.length
              ? requests.push(
                  this.peopleCountMonitorService.listPeopleCountMonitors(undefined, undefined, this.cameraId, 'active'),
                )
              : requests.push(of([]));
            this.peelOffMonitorCameraPositions.length
              ? requests.push(
                  this.peelOffMonitorService.listPeelOffMonitors(undefined, undefined, this.cameraId, 'active'),
                )
              : requests.push(of([]));

            this.alertCameraPositionMappings.length
              ? requests.push(
                  this.alertService.listAlerts(
                    undefined,
                    this.cameraId,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    'active',
                  ),
                )
              : requests.push(of([]));

            if (!requests.length) {
              return of([]);
            }

            return forkJoin(requests);
          },
        ),
        finalize(() => {
          this.gettingDecommissionInfo = false;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([occupancyMonitors, peopleCountMonitors, peelOffMonitors, alerts]) => {
          const dialogRef = this.matDialog.open(DecommissionConfirmationComponent, {
            height: '100vh',
            width: '100vw',
            maxWidth: '100vw',
            data: {
              cameraName: this.data.camera.name,
              occupancyMonitors: occupancyMonitors.filter((occupancyMonitor) =>
                this.occupancyMonitorCameraPositions.some(
                  (occupancyMonitorCameraPosition) =>
                    occupancyMonitorCameraPosition.occupancyMonitorId === occupancyMonitor.id,
                ),
              ),
              peopleCountMonitors: peopleCountMonitors.filter((peopleCountMonitor) =>
                this.peopleCountMonitorCameraPositions.some(
                  (peopleCountMonitorCameraPosition) =>
                    peopleCountMonitorCameraPosition.peopleCountMonitorId === peopleCountMonitor.id,
                ),
              ),
              peelOffMonitors: peelOffMonitors.filter((peelOffMonitor) =>
                this.peelOffMonitorCameraPositions.some(
                  (peelOffMonitorCameraPosition) => peelOffMonitorCameraPosition.peelOffMonitorId === peelOffMonitor.id,
                ),
              ),
              alerts: alerts.filter((alert) =>
                this.alertCameraPositionMappings.some(
                  (alertCameraPositionMapping) => alertCameraPositionMapping.alertId === alert.id,
                ),
              ),
            },
          });

          dialogRef.afterClosed().subscribe({
            next: (result) => {
              if (!result) {
                return;
              }

              this.saving = true;

              const requests: Observable<any>[] = [];
              if (result['removeLinks']) {
                this.occupancyMonitorCameraPositions.forEach((occupancyMonitorCameraPosition) =>
                  requests.push(
                    this.occupancyMonitorCameraPositionService.deleteOccupancyMonitorCameraPosition(
                      occupancyMonitorCameraPosition.id,
                    ),
                  ),
                );
                this.peopleCountMonitorCameraPositions.forEach((peopleCountMonitorCameraPosition) =>
                  requests.push(
                    this.peopleCountMonitorCameraPositionService.deletePeopleCountMonitorCameraPosition(
                      peopleCountMonitorCameraPosition.id,
                    ),
                  ),
                );
                this.peelOffMonitorCameraPositions.forEach((peelOffMonitorCameraPosition) =>
                  requests.push(
                    this.peelOffMonitorCameraPositionService.deletePeelOffMonitorCameraPosition(
                      peelOffMonitorCameraPosition.id,
                    ),
                  ),
                );
                this.alertCameraPositionMappings.forEach((alertCameraPositionMapping) =>
                  requests.push(
                    this.alertCameraPositionMappingService.deleteAlertCameraPositionMapping(
                      alertCameraPositionMapping.id,
                    ),
                  ),
                );
              } else {
                requests.push(of([]));
              }

              forkJoin(requests)
                .pipe(
                  switchMap((_) => this.cameraService.deleteCamera(this.cameraId)),
                  finalize(() => {
                    this.saving = false;
                  }),
                )
                .subscribe({
                  next: (_) => {
                    window.location.reload();
                  },
                  error: (error) => {
                    this.notifyService.error(error);
                  },
                });
            },
          });
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
