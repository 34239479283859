import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  PeopleCountMonitor,
  PeopleCountMonitorCameraPosition,
  PeopleCountMonitorCameraPositionService,
  PeopleCountMonitorService,
  Site,
} from 'src/app/api';
import { CdkStep } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { ChooseMonitorModeStepComponent } from 'src/app/components/steps-for-stepper/choose-monitor-mode-step/choose-monitor-mode-step.component';
import { SelectCameraDirectionStepComponent } from 'src/app/components/steps-for-stepper/select-camera-direction-step/select-camera-direction-step.component';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { SelectCamerasStepComponent } from 'src/app/components/steps-for-stepper/select-cameras-step/select-cameras-step.component';
import { GiveMonitorNameStepComponent } from 'src/app/components/steps-for-stepper/give-monitor-name-step/give-monitor-name-step.component';
import { SelectMonitorDirectionStepComponent } from 'src/app/components/steps-for-stepper/select-monitor-direction-step/select-monitor-direction-step.component';
import { BehaviorSubject, finalize, forkJoin, Subject, switchMap, takeUntil } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';
import { SelectASiteComponent } from 'src/app/components/steps-for-stepper/select-a-site/select-a-site.component';
import { CameraStatus } from 'src/app/model/cameraStatus';
import * as moment from 'moment';

@Component({
  selector: 'app-add-people-count-monitor',
  templateUrl: './add-people-count-monitor.component.html',
  styleUrls: ['./add-people-count-monitor.component.scss'],
})
export class AddPeopleCountMonitorComponent implements OnDestroy {
  readonly stepsTitle: string = 'Create a people count monitor';
  isLoading$ = new BehaviorSubject<boolean>(false);
  cameras: CameraStatus[];
  filteredCameras: CameraStatus[] = [];
  sites: Site[];
  selectedCameras: CameraStatus[];
  existedMonitors: any[];
  monitorsCameras: { [_: string]: any[] } = {};
  siteId = '';
  showSelectSite = false;
  successMessage = '';

  private ngUnsubscribe = new Subject();

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('giveMonitorNameStep') giveMonitorNameStep: CdkStep;
  @ViewChild('finalStep') finalStep: CdkStep;
  @ViewChild('selectMonitorDirectionStep') selectMonitorDirectionStep: CdkStep;

  @ViewChild(SelectASiteComponent) selectASiteComponent: SelectASiteComponent;
  @ViewChild(ChooseMonitorModeStepComponent) chooseMonitorModeStepComponent: ChooseMonitorModeStepComponent;
  @ViewChild(GiveMonitorNameStepComponent) giveMonitorNameStepComponent: GiveMonitorNameStepComponent;
  @ViewChild(SelectMonitorDirectionStepComponent)
  SelectMonitorDirectionStepComponent: SelectMonitorDirectionStepComponent;
  @ViewChild(SelectCameraDirectionStepComponent) selectCameraDirectionStepComponent: SelectCameraDirectionStepComponent;
  @ViewChild(SelectCamerasStepComponent) selectCamerasStepComponent: SelectCamerasStepComponent;

  constructor(
    public dialogRef: MatDialogRef<AddPeopleCountMonitorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog,
    private notifyService: NotifyService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peopleCountMonitorCameraPositionService: PeopleCountMonitorCameraPositionService,
  ) {
    this.existedMonitors = data.existedMonitors;
    this.monitorsCameras = data.monitorsCameras;
    this.cameras = data.cameras;
    this.sites = data.sites;

    if (this.sites?.length === 1 && !data.siteId) {
      this.siteId = this.sites[0].id;
    } else {
      this.siteId = data.siteId;
    }

    if (!this.siteId) {
      this.showSelectSite = true;
    }
    this.filterCameras();
  }

  filterCameras(): void {
    this.filteredCameras = this.siteId ? this.cameras.filter((c: CameraStatus) => c.siteId === this.siteId) : [];
  }

  selectASite(): void {
    this.siteId = this.selectASiteComponent.choosenSite.id;
    this.filterCameras();
    this.stepper.next();
  }

  goToSecondStep(): void {
    const component = this.chooseMonitorModeStepComponent;
    this.chooseMonitorModeStepComponent.monitorForm.markAsTouched();
    if (component.monitorMode && component.monitorForm.valid) {
      if (component.monitorMode === 'single') {
        var cameraId: string = this.chooseMonitorModeStepComponent.monitorForm.get('cameraId').value;
        var camera: CameraStatus = this.chooseMonitorModeStepComponent.cameras.find((c) => c.id === cameraId);
        this.createPopleCountMonitor(camera.name, camera.direction1Alias, camera.direction2Alias, [
          { cameraPositionId: camera.cameraPositionId, reversed: false },
        ]);
      } else {
        this.stepper.selected = this.giveMonitorNameStep;
      }
    }
  }

  completeMultiCamerasJourney(): void {
    this.selectCameraDirectionStepComponent.monitorForm.markAsTouched();
    if (this.selectCameraDirectionStepComponent.monitorForm.valid) {
      this.createPopleCountMonitor(
        this.giveMonitorNameStepComponent.monitorForm.get('name').value,
        this.SelectMonitorDirectionStepComponent.monitorForm.get('direction1Alias').value,
        this.SelectMonitorDirectionStepComponent.monitorForm.get('direction2Alias').value,
        this.selectCameraDirectionStepComponent.monitorForm.get('camera').value.map((camera) => {
          return { cameraPositionId: camera.cameraPositionId, reversed: camera.reversed };
        }),
        this.giveMonitorNameStepComponent.monitorForm.get('reportingStartDate').value
          ? moment(this.giveMonitorNameStepComponent.monitorForm.get('reportingStartDate').value).format('YYYY-MM-DD')
          : undefined,
        this.giveMonitorNameStepComponent.monitorForm.get('reportingEndDate').value
          ? moment(this.giveMonitorNameStepComponent.monitorForm.get('reportingEndDate').value).format('YYYY-MM-DD')
          : undefined,
      );
    }
  }

  goToSetMonitorDirectionStep(): void {
    this.selectCamerasStepComponent.monitorForm.markAsTouched();
    if (this.selectCamerasStepComponent.monitorForm.invalid) {
      return;
    }
    const monitorNamesWithSameConfigurations = this.checkIfCamerasSelectionAlreadyExist();

    if (monitorNamesWithSameConfigurations.length) {
      this.showMonitorConfirmationdialog(monitorNamesWithSameConfigurations);
    } else {
      this.stepper.linear = false;
      this.stepper.selected = this.selectMonitorDirectionStep;
      this.stepper.linear = true;
    }
  }

  createPopleCountMonitor(
    name: string,
    direction1Alias: string,
    direction2Alias: string,
    cameras: { cameraPositionId: string; reversed: boolean }[],
    reportingStartDate?,
    reportingEndDate?,
  ): void {
    this.isLoading$.next(true);
    this.peopleCountMonitorService
      .putPeopleCountMonitor({
        name,
        direction1Alias,
        direction2Alias,
        siteId: this.siteId,
        reportingStartDate,
        reportingEndDate,
      })
      .pipe(
        switchMap((monitor: PeopleCountMonitor) =>
          forkJoin(
            cameras.map((camera) =>
              this.peopleCountMonitorCameraPositionService.putPeopleCountMonitorCameraPosition({
                peopleCountMonitorId: monitor.id,
                cameraPositionId: camera.cameraPositionId,
                direction1: camera.reversed
                  ? PeopleCountMonitorCameraPosition.Direction1Enum._2
                  : PeopleCountMonitorCameraPosition.Direction1Enum._1, //chane prop name
              }),
            ),
          ),
        ),
        finalize(() => {
          this.isLoading$.next(false);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (_) => {
          this.successMessage = `Your new people count monitor: ${this.giveMonitorNameStepComponent.monitorForm.value['name']} - is created.`;
          this.close(true);
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }

  private showMonitorConfirmationdialog(monitorNames: string[]): void {
    const message = `
      A monitor with this selection of cameras already exists (${monitorNames.join(', ')}). 

      Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'CONTINUE' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.stepper.selected = this.selectMonitorDirectionStep;
        }
      },
    });
  }

  private checkIfCamerasSelectionAlreadyExist() {
    const selectedCamerasIds = this.selectCamerasStepComponent.monitorForm
      .get('cameras')
      .value.map((camera: CameraStatus) => camera.id);
    const sameMonitorConfigurations: string[] = [];

    for (let key in this.monitorsCameras) {
      const camerasId = this.monitorsCameras[key].map((camera) => camera.id);
      if (this.arePrimitiveArraysEqual(camerasId, selectedCamerasIds)) {
        const monitor = this.existedMonitors.find((monitor) => monitor.id === key);
        sameMonitorConfigurations.push(monitor.name);
      }
    }
    return sameMonitorConfigurations;
  }

  private arePrimitiveArraysEqual(array1: string[] | number[], array2: string[] | number[]) {
    return array1.sort().join(',') === array2.sort().join(',');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
