<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading monitor details...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <div>
    <div class="c-grid-section">
      <div class="o-feature-card">
        <div class="o-feature-card__head">
          <span class="material-icons">fork_left</span>
          <div class="is-title is-reverse">
            <h2>Peel off monitor</h2>
            <h2>{{ monitor.name }}</h2>
          </div>
        </div>
        <div class="o-feature-card__head c-no-border">
          <span class="material-icons">pin_drop</span>
          <div class="is-title is-reverse">
            <h2>
              <b>Passing count | </b>{{ passTraffCameras.length }}
              {{ passTraffCameras.length | pluraliseWord: 'camera' }}
            </h2>
          </div>
        </div>
        <div class="o-feature-card__head" [ngClass]="{ 'c-no-border': !accountService.isSupport }">
          <span class="material-icons">pin_drop</span>
          <div class="is-title is-reverse">
            <h2>
              <b>Entrance count | </b>{{ entranceCameras.length }}
              {{ entranceCameras.length | pluraliseWord: 'camera' }}
            </h2>
          </div>
        </div>
        <ng-container *ngIf="accountService.isSupport">
          <div class="o-feature-card__head c-no-border" *ngIf="monitor.reportingStartDate">
            <span class="material-icons">event</span>
            <div class="is-title is-reverse">
              <h2><b>Reporting start date </b>{{ monitor.reportingStartDate | date: 'dd/MM/yyyy' }}</h2>
            </div>
          </div>
          <div class="o-feature-card__head c-no-border" *ngIf="monitor.reportingEndDate">
            <span class="material-icons">event</span>
            <div class="is-title is-reverse">
              <h2><b>Reporting end date </b>{{ monitor.reportingEndDate | date: 'dd/MM/yyyy' }}</h2>
            </div>
          </div>
        </ng-container>

        <div class="o-feature-card__action">
          <button class="o-button is-ghost" (click)="openEditPeelOffMonitorDialog()">
            Edit monitor
            <span class="material-icons is-icon-right">arrow_forward</span>
          </button>
        </div>
      </div>

      <div class="c-monitor-summary">
        <div class="c-monitor-summary__counts">
          <h3 class="long-primer kirk">People count last week</h3>
          <div class="c-week-count">
            <mat-icon class="is-icon is-passing-count">pin_drop</mat-icon>
            <div class="is-text">
              <div class="is-primary-text">Passing:</div>
              <div class="is-secondary-text">
                {{ (isLoadingChart$ | async) ? '-' : (passingCountWeekTotal[String(this.useOpeningHours)] | number) }}
              </div>
            </div>
          </div>
          <div class="c-week-count has-border">
            <mat-icon class="is-icon is-entrance-count">pin_drop</mat-icon>
            <div class="is-text">
              <div class="is-primary-text">Entrance:</div>
              <div class="is-secondary-text">
                {{ (isLoadingChart$ | async) ? '-' : (entranceCountWeekTotal[String(this.useOpeningHours)] | number) }}
              </div>
            </div>
          </div>
          <h3 class="primer kirk">Peel off rate</h3>

          <div class="c-week-conversion">
            <div class="is-conversion">
              Week average:
              <b>
                {{
                  (isLoadingChart$ | async)
                    ? '-'
                    : (weekConversion[String(this.useOpeningHours)] | number: '1.1-2') + '%'
                }}</b
              >
            </div>
          </div>

          <div class="c-today-conversion">
            <div class="is-conversion">
              Today:
              <b>
                {{
                  (isLoadingChart$ | async)
                    ? '-'
                    : (todayConversion[String(this.useOpeningHours)] | number: '1.1-2') + '%'
                }}</b
              >
            </div>
            <div
              class="is-change"
              #tooltip="matTooltip"
              [matTooltip]="
                (todayConversionChange[String(this.useOpeningHours)] < 0 ? 'Decrease' : 'Increase') +
                ' compared to last week.'
              "
              [matTooltipDisabled]="isLoadingChart$ | async"
              *ngIf="(isLoadingChart$ | async) === false"
            >
              <mat-icon *ngIf="(isLoadingChart$ | async) === false">{{
                todayConversionChange[String(this.useOpeningHours)] !== 0
                  ? todayConversionChange[String(this.useOpeningHours)] > 0
                    ? 'arrow_drop_up'
                    : 'arrow_drop_down'
                  : 'arrow_right'
              }}</mat-icon>
              <b>{{ (todayConversionChange[String(this.useOpeningHours)] | number: '1.1-2') + '%' }}</b>
            </div>
          </div>
          <div class="dot-flashing" *ngIf="isLoadingChart$ | async"></div>
        </div>
        <div class="c-monitor-summary__chart">
          <div class="is-header">
            <div class="o-heading--primer">
              <h3></h3>
              <div class="is-legend">
                <span class="is-passing-count">Passing</span>
                <span class="is-entrance-count">Entrance</span>
                <span class="is-rate">Rate</span>
              </div>
            </div>

            <span
              [matTooltip]="'There are no opening hours set for this site.'"
              [matTooltipDisabled]="!!site.openingHour"
            >
              <mat-checkbox [(ngModel)]="useOpeningHours" (change)="setChartData()" [disabled]="!site.openingHour"
                >Filter by opening hours</mat-checkbox
              >
            </span>
          </div>

          <div class="is-chart" #chart></div>
        </div>
      </div>
    </div>

    <div class="c-monitor-cameras">
      <div class="c-cameras-info">
        <div class="c-cameras-info__list">
          <div class="c-cameras-list-title is-passing-count">
            <mat-icon class="is-icon">pin_drop</mat-icon>
            <div class="is-text">Passing count</div>
          </div>
          <div class="c-camera-link-info">
            <div class="c-camera-link-info__camera" *ngFor="let camera of passTraffCameras">
              <mat-icon class="is-icon">photo_camera</mat-icon>
              <div class="is-text">
                <div class="is-primary-text">
                  {{ camera.name }}
                </div>
                <div class="is-secondary-text">
                  TRACKED:<span class="is-direction-text">
                    {{
                      camerasPositionsMap[camera.cameraPositionId].countDirection === 'direction_both'
                        ? 'Both directions'
                        : camerasPositionsMap[camera.cameraPositionId].countDirection === 'direction_1'
                          ? camera.direction1Alias
                          : camera.direction2Alias
                    }}
                  </span>
                </div>

                <a class="is-action o-button is-small is-action" [routerLink]="['/cameras/details/' + camera.id]">
                  View camera
                  <span class="material-icons is-icon-right"> arrow_forward </span>
                </a>
              </div>
            </div>
          </div>
          <div class="c-count-total is-passing-count">
            <div>
              <span>Today:</span>
              <span class="is-total">{{
                (isLoadingChart$ | async) ? '-' : (passingCountTodayTotal[String(this.useOpeningHours)] | number)
              }}</span>
            </div>
            <div>
              <span class="is-week-avg-label">Last week avg:</span>
              <span class="is-week-avg">{{
                (isLoadingChart$ | async)
                  ? '-'
                  : (passingCountWeekTotal[String(this.useOpeningHours)] / 7 | number: '1.0-0')
              }}</span>
            </div>
          </div>
        </div>
        <div class="c-cameras-info__frame">
          <div class="c-frame-title">
            {{ passTraffCameras.length }} {{ passTraffCameras.length | pluraliseWord: 'camera' }}
          </div>
          <div class="c-frame-images">
            <ng-container *ngFor="let camera of passTraffCameras">
              <ng-container *ngIf="camera.state === 'running'; else noImage">
                <ng-container *ngIf="isLoadingCameraFrame.get(camera.id) | async; else notLoadingImg">
                  <div fxFill fxLayoutAlign="center center" fxLayout="column">
                    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
                  </div>
                </ng-container>
              </ng-container>

              <ng-template #notLoadingImg>
                <img [src]="cameraFrame[camera.id]" alt="" />
              </ng-template>

              <ng-template #noImage>
                <img src="/assets/images/no-frame.png" alt="No image" />
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="c-cameras-info">
        <div class="c-cameras-info__list">
          <div class="c-cameras-list-title is-entrance-count">
            <mat-icon class="is-icon">pin_drop</mat-icon>
            <div class="is-text">Entrance count</div>
          </div>
          <div class="c-camera-link-info">
            <div class="c-camera-link-info__camera" *ngFor="let camera of entranceCameras">
              <mat-icon class="is-icon">photo_camera</mat-icon>
              <div class="is-text">
                <div class="is-primary-text">
                  {{ camera.name }}
                </div>
                <div class="is-secondary-text">
                  TRACKED:<span class="is-direction-text">
                    {{
                      camerasPositionsMap[camera.cameraPositionId].countDirection === 'direction_both'
                        ? 'Both directions'
                        : camerasPositionsMap[camera.cameraPositionId].countDirection === 'direction_1'
                          ? camera.direction1Alias
                          : camera.direction2Alias
                    }}
                  </span>
                </div>

                <a class="is-action o-button is-small is-action" [routerLink]="['/cameras/details/' + camera.id]">
                  View camera
                  <span class="material-icons is-icon-right"> arrow_forward </span>
                </a>
              </div>
            </div>
          </div>
          <div class="c-count-total is-entrance-count">
            <div>
              <span>TODAY:</span>
              <span class="is-total">{{
                (isLoadingChart$ | async) ? '-' : (entranceCountTodayTotal[String(this.useOpeningHours)] | number)
              }}</span>
            </div>
            <div>
              <span class="is-week-avg-label">Last week avg:</span>
              <span class="is-week-avg">{{
                (isLoadingChart$ | async)
                  ? '-'
                  : (entranceCountWeekTotal[String(this.useOpeningHours)] / 7 | number: '1.0-0')
              }}</span>
            </div>
          </div>
        </div>
        <div class="c-cameras-info__frame">
          <div class="c-frame-title">
            {{ entranceCameras.length }} {{ entranceCameras.length | pluraliseWord: 'camera' }}
          </div>
          <div class="c-frame-images">
            <ng-container *ngFor="let camera of entranceCameras">
              <ng-container *ngIf="camera.state === 'running'; else noImage">
                <ng-container *ngIf="isLoadingCameraFrame.get(camera.id) | async; else notLoadingImg">
                  <div fxFill fxLayoutAlign="center center" fxLayout="column">
                    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
                  </div>
                </ng-container>
              </ng-container>

              <ng-template #notLoadingImg>
                <img [src]="cameraFrame[camera.id]" alt="" />
              </ng-template>

              <ng-template #noImage>
                <img src="/assets/images/no-frame.png" alt="No image" />
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
